import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { CreditPoolTypes } from '../../../../../../../shared/constants/Organisation';
import LabelWithHelp from '../../../../../../components/LabelWithHelp/LabelWithHelp';
import FeatureBox from '../../../../../../widgets/featureBox';

export const CreditInputRowRevised = ({
  plan,
  accordionStates,
  updateAccordionState,
  addAccordionState,
}: any) => {
  const type = CreditPoolTypes.find((item) => item.type === plan.type)?.label;
  const index = accordionStates.findIndex((cur: any) => cur.type === plan.type);
  return (
    <>
      <Grid item xs={6}>
        <FeatureBox sx={{ marginBottom: 0, maxHeight: '250px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} width={12}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant="h6">{type}</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={accordionStates[index]?.included || false}
                      onChange={(event, checked) => {
                        addAccordionState(plan.type);
                      }}
                    />
                  }
                  label={''}
                />
              </Box>
            </Grid>
            {accordionStates[index]?.included ? (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            ) : null}

            {accordionStates[index]?.included && accordionStates[index].type !== '10' && (
              <>
                <Grid
                  item
                  xs={12}
                  width={12}
                  display={'flex'}
                  justifyContent={'space-between'}
                  height={'3.5rem'}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accordionStates[index]?.credits !== null}
                        onChange={(event, checked) =>
                          updateAccordionState(index, {
                            ...accordionStates[index],
                            credits: checked ? 0 : null,
                          })
                        }
                      />
                    }
                    labelPlacement="start"
                    label={
                      <LabelWithHelp
                        label="Client Credits"
                        helpText="Add limit on total credits?"
                        maxWidth="150px"
                      />
                    }
                  />
                  {accordionStates[index]?.credits !== null && (
                    <TextField
                      type="number"
                      onChange={(event) =>
                        updateAccordionState(index, {
                          ...accordionStates[index],
                          credits: event.target.value,
                        })
                      }
                      value={accordionStates[index]?.credits}
                      size="small"
                    />
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  width={12}
                  display={'flex'}
                  justifyContent={'space-between'}
                  height={'3.5rem'}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={accordionStates[index]?.creditPerUser !== null}
                        onChange={(event, checked) =>
                          updateAccordionState(index, {
                            ...accordionStates[index],
                            creditPerUser: checked ? 0 : null,
                          })
                        }
                      />
                    }
                    labelPlacement="start"
                    label={
                      <LabelWithHelp
                        label="User Credits"
                        helpText="Add limit on per user credits?"
                        maxWidth="150px"
                      />
                    }
                  />
                  {accordionStates[index]?.creditPerUser !== null && (
                    <TextField
                      type="number"
                      onChange={(event) =>
                        updateAccordionState(index, {
                          ...accordionStates[index],
                          creditPerUser: event.target.value,
                        })
                      }
                      value={accordionStates[index]?.creditPerUser}
                      sx={{
                        marginBottom: 2,
                      }}
                      size="small"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  width={12}
                  marginLeft={'16px'}
                  gap={'8px'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  height={'3.5rem'}
                >
                  <InputLabel>
                    <LabelWithHelp
                      label="Notes"
                      helpText="Notes like: top-up, extensions, refund..."
                      maxWidth="150px"
                    />
                  </InputLabel>
                  <TextField
                    size="small"
                    onChange={(event) =>
                      updateAccordionState(index, {
                        ...accordionStates[index],
                        notes: event.target.value,
                      })
                    }
                    inputProps={{ maxLength: 50 }}
                    value={accordionStates[index]?.notes}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </FeatureBox>
      </Grid>
    </>
  );
};
