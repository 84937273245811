import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationDetailItem } from '../../../../../../shared/types/organisation/OrganisationDetailItem';

export const useToggleMfaEnabled = () =>
  useMutation({
    mutationFn: ({ orgId, mfaStatus }: { orgId: number; mfaStatus: boolean }) =>
      OrganisationServiceV2.setMfaEnabled(orgId, mfaStatus),
    onError() {
      toast.error(
        'An error occurred when attempting to toggle MFA enabled status.',
      );
    },
    onSuccess(_data, { mfaStatus }) {
      toast.success(`MFA ${mfaStatus ? 'enabled' : 'disabled'} successfully.`);
    },
  });
