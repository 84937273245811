import { Box } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';

import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationDetailItem } from '../../../../../../shared/types/organisation/OrganisationDetailItem';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import AddTeamDialog from './AddTeamDialog';
import TeamList from './TeamList';

const TeamView = ({
  teams,
  refreshTeams,
  orgData,
  refreshOrg,
}: {
  teams: OrganisationTeamItem[];
  refreshTeams: () => void;
  orgData: OrganisationDetailItem;
  refreshOrg: () => void;
}) => {
  const [currentlyExpandedTeams, setCurrentlyExpandedTeams] = useState<{
    [teamId: number]: boolean;
  }>({});
  const [showAddTeamDialog, setShowAddTeamDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const handleAddTeam = async (name: string) => {
    let response = null;
    try {
      setLoading(true);
      response = await OrganisationServiceV2.addTeam(orgData.id, {
        teamName: name,
      });
    } catch (e) {
      toast.error('An error occurred while attempting to add the new team.');
    } finally {
      setLoading(false);
    }
    if (response && response.data.success) {
      toast.success('Team added successfully!');
      setShowAddTeamDialog(false);
      refreshTeams();
    }
  };

  const setTeamAsDefault = async (id: number) => {
    let response = null;
    try {
      response = await OrganisationServiceV2.update(orgData.id, {
        name: orgData.name,
        defaultTeamId: id,
      });
    } catch (e) {
      toast.error(
        'An error occurred while attempting to set the team as default.',
      );
    }
    if (response && response.data.success) {
      toast.success('Team set as default.');
      refreshOrg();
    }
  };
  const disableTeamAsDefault = async (id: null) => {
    let response = null;
    try {
      response = await OrganisationServiceV2.update(orgData.id, {
        name: orgData.name,
        defaultTeamId: null,
      });
    } catch (e) {
      toast.error(
        'An error occurred while attempting to remove the team as default.',
      );
    }
    if (response && response.data.success) {
      toast.success('Team removed as default');
      refreshOrg();
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <StyledButton
          size="small"
          variant="ghost"
          color="gray"
          startIcon={MaterialIcons.Add}
          onClick={() => setShowAddTeamDialog(true)}
          sx={{ alignSelf: 'flex-end' }}
          margin={0}
        >
          Add team
        </StyledButton>
        <TeamList
          teams={teams}
          currentlyExpandedTeams={currentlyExpandedTeams}
          setCurrentlyExpandedTeams={setCurrentlyExpandedTeams}
          defaultTeamId={orgData.defaultTeamId}
          refreshTeams={refreshTeams}
          setTeamAsDefault={setTeamAsDefault}
          orgId={orgData.id}
          disableTeamAsDefault={disableTeamAsDefault}
        />
      </Box>
      <AddTeamDialog
        open={showAddTeamDialog}
        handleClose={() => setShowAddTeamDialog(false)}
        handleSubmit={handleAddTeam}
        loading={loading}
      />
    </>
  );
};

export default TeamView;
