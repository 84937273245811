import { IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from 'react';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';
import { IAssociatedRole, IUser } from '../types/user-management.interface';

export const UserGridColumns: (
  setOpenUserDialog: Dispatch<
    SetStateAction<{
      open: boolean;
      edit: boolean;
      user: IUser | null;
    }>
  >,
) => GridColDef[] = (setOpenUserDialog) => [
  {
    flex: 1,
    minWidth: 120,
    field: 'id',
    headerName: 'ID',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.id} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'name',
    headerName: 'Name',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.name ?? '-'} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    sortable: false,
    field: 'email',
    headerName: 'Email',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.email ?? '-'} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'associatedRoles',
    sortable: false,
    headerName: 'Roles',
    renderCell: (params: GridRenderCellParams) => {
      const roles: IAssociatedRole[] = params?.row?.associatedRoles ?? [];
      return (
        <TooltipTypography
          title={roles
            ?.reduce((acc: string[], item) => {
              if (item?.name) acc.push(item?.name);

              return acc;
            }, [])
            .join(', ')}
        />
      );
    },
  },
  {
    flex: 0.5,
    minWidth: 40,
    field: 'actions',
    sortable: false,
    headerName: 'Actions',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <IconButton
          onClick={() =>
            setOpenUserDialog({ open: true, edit: true, user: params?.row })
          }
        >
          {MaterialIcons.Edit}
        </IconButton>
      );
    },
  },
];
