import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import {
  CreditPlanRequestPayload,
  OrganisationDetailItem,
  Subscription,
} from '../../../../../../shared/types/organisation/OrganisationDetailItem';

export const useCreateOrganisation = (onOrgCreateSuccess: () => void) =>
  useMutation({
    mutationFn: ({
      orgData,
      creditRequestPayload,
    }: {
      orgData: Partial<OrganisationDetailItem>;
      creditRequestPayload: Partial<CreditPlanRequestPayload[]>;
    }) => {
      return OrganisationServiceV2.addOrganisation(orgData)
        .then((response) => {
          return response;
        })
        .then((data) => {
          return OrganisationServiceV2.addCredits(
            creditRequestPayload,
            data.id,
          );
        });
    },
    onSuccess() {
      toast.success('Organisation added successfully!');
      onOrgCreateSuccess();
    },
    onError: (err: any) => {
      if (err.response?.status === 409) {
        toast.error('Found an Organisation with the same Name');
      } else {
        toast.error(
          'An error occurred while attempting to add the organisation.',
        );
      }
    },
  });
