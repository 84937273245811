import { Grid, InputLabel, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

import { useContext } from 'react';
import { OrganisationoDetailContext } from './organisationDetail/OrganisationDetailView';
import OrganisationDetailSectionHeader from './organisationDetail/OrganisationDetailSectionHeader';

const OrganisationContractDetails = () => {
  const { isEditing, updatedData, setUpdatedData } = useContext(
    OrganisationoDetailContext,
  );

  return (
    <>
      <OrganisationDetailSectionHeader
        title="Contract Details"
        editingModule={'ORG_CONTRACT_DETAILS'}
      />
      <Grid container spacing={2} padding={1}>
        <Grid item xs={12} width={12}></Grid>
        <Grid item xs={6} width={6}>
          <InputLabel id="addContractValue">Contract start date</InputLabel>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              disabled={!isEditing.ORG_CONTRACT_DETAILS}
              views={['year', 'month', 'day']}
              inputFormat="yyyy-MM-DD"
              mask=""
              value={updatedData?.contractStartDate ?? ''}
              onChange={(value: Moment | null) =>
                setUpdatedData((prev) => ({
                  ...prev,
                  contractStartDate:
                    value?.toISOString() ?? moment().toISOString(),
                }))
              }
              renderInput={(params: any) => (
                <TextField fullWidth {...params} size="small" />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} width={6}>
          <InputLabel id="totalHeadCount">Total Head Count</InputLabel>
          <TextField
            InputProps={{
              inputProps: {
                inputMode: 'numeric',
                pattern: '[0-9]*',
                min: 0,
              },
            }}
            onChange={(event) => {
              const { value } = event.target;
              setUpdatedData((prev) => ({
                ...prev,
                totalHeadCount: isNaN(Number(value)) ? 0 : Number(value),
              }));
            }}
            autoFocus
            fullWidth
            disabled={!isEditing.ORG_CONTRACT_DETAILS}
            id="totalHeadCount"
            value={updatedData?.totalHeadCount}
            placeholder="Add total head count"
            size="small"
          />
        </Grid>
      </Grid>
    </>
  );
};
export default OrganisationContractDetails;
