import { useQuery } from '@tanstack/react-query';
import { OrganisationServiceV2 } from '../../shared/service/services_v2';
import { sortBy } from 'lodash';

export const useGetOrganisations = () =>
  useQuery({
    queryKey: ['orgs'],
    queryFn: () =>
      OrganisationServiceV2.getListV2({
        noLimit: true,
        select: 'id,name',
      }),
    staleTime: 10 * 60 * 1000, // Data is fresh for 10 minutes
    cacheTime: 15 * 60 * 1000, // Data is kept in cache for 15 minutes
    select: (data) => {
      return data?.items ? sortBy(data?.items, (o) => o.name) : [];
    },
    meta: {
      errorMessage: 'An error occurred while attempting to get organisations.',
    },
  });
