import { styled } from '@mui/system';

const FeatureBox = styled('div')({
  padding: '1rem 1.25rem',
  borderRadius: '0.5rem',
  margin: '1rem 0',
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.09)',

  '& .MuiTypography-h5:first-of-type': {
    fontSize: '1.35rem',
    fontWeight: 'bold',
    marginBottom: '2rem',
    color: 'secondary.main',
  },
});

export default FeatureBox;
