import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import { OrganisationoDetailContext } from './OrganisationDetailView';
import StyledButton from '../../../../widgets/styledButton/StyledButton';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';
import { useGetProviderListNoPagination } from '../../../provider/hooks/UseGetProviderList';
import AddOrgProviderDialog from './AddOrganisationProviderDialog';
import SectionHeader from '../../../../components/SectionHeader/SectionHeader';
import TableViewComponent from '../../../../components/TableViewComponent/TableViewComponent';
import { OrgMappedProviderColumns } from '../../utils/grid-columns/OrganisationProviderGridColumns';
import { OrganisationServiceV2 } from '../../../../../shared/service/services_v2';
import toast from 'react-hot-toast';

const OrganisationMappedProviders = (orgId: any) => {
  const { orgProvidersList, getOrgMappedProviders } = useContext(
    OrganisationoDetailContext,
  );

  const [pageSize, setPageSize] = useState<number>(7);
  const [page, setPage] = useState<number>(0);
  const orgProvidersToShow = useMemo(() => {
    return (
      orgProvidersList?.map((obj) => ({
        ...obj,
        services: obj.providerServices.join(', '),
      })) ?? []
    );
  }, [orgProvidersList]);

  const [openProviderOrgDialog, setProviderOrgDialog] = useState<{
    open: boolean;
    edit: boolean;
    data?: any;
  }>({ open: false, edit: false });

  const handleClose = () => {
    setProviderOrgDialog({ open: false, edit: false, data: undefined });
  };
  const handleSuccess = () => {
    getOrgMappedProviders();
  };

  const handleDeleteProvider = useCallback(
    async (providerId: any) => {
      if (!providerId) return;
      let response = null;
      try {
        response = await OrganisationServiceV2.deleteOrgMappedProviders(
          orgId.orgId,
          providerId,
        );
        if (response && response.data.success) {
          getOrgMappedProviders();
          toast.success('Provider has been removed');
        }
      } catch (error) {
        toast.error(
          'An error occurred while attempting to delete the organisation mapped providers',
        );
      }
    },
    [orgId],
  );

  return (
    <>
      <SectionHeader
        title={
          <Typography
            color="textPrimary"
            variant="h6"
            fontWeight="bold"
            margin={0}
          >
            Organisation Mapped Providers
          </Typography>
        }
        rightElement={
          <>
            <StyledButton
              variant="ghost"
              startIcon={MaterialIcons.Add}
              disabled={!orgId}
              onClick={() =>
                setProviderOrgDialog({
                  open: true,
                  edit: false,
                  data: undefined,
                })
              }
            >
              Add Provider
            </StyledButton>
          </>
        }
      />
      <TableViewComponent
        rows={orgProvidersToShow ?? []}
        pageSizeCustom={pageSize}
        currentPage={page}
        getRowId={(row: any) => row?.providerId ?? row?.providerName}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        onPageChange={(page) => {
          setPage(page);
        }}
        columns={OrgMappedProviderColumns({
          handleDeleteProvider,
        })}
      />
      <AddOrgProviderDialog
        open={openProviderOrgDialog?.open}
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        orgId={orgId.orgId}
      />
    </>
  );
};

export default OrganisationMappedProviders;
