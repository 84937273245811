import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import toast from 'react-hot-toast';
import { DomainUsersItem } from '../../../../../../shared/types/organisation/DomainUsers';
import TableViewComponent from '../../../../../components/TableViewComponent/TableViewComponent';
import { DomainUsersGridColumns } from '../../../utils/grid-columns/DomainUsersGridColumns';
import { ItemContains } from '../../../../../../utilities/Common';
import useDebounce from '../../../../../../utilities/hooks/useDebounce';
import { ClearOutlined } from '@mui/icons-material';
import { NumberFieldStyles } from '../../../../../../shared/styles/Common';

const DomainUsersView = ({ orgId }: { orgId: number }) => {
  const [loading, setLoading] = useState(false);
  const [domainUsersDetails, setDomainUsersDetails] = useState<
    DomainUsersItem[]
  >([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [allDomainUsers, setAllDomainUsers] = useState<DomainUsersItem[]>([]);

  const [filterInputs, setFilterInputs] = useState<{
    id: string;
    email: string;
  }>({
    id: '',
    email: '',
  });
  const debouncedFilter = useDebounce(filterInputs, 500);

  const getDomainUsers = async () => {
    let response = null;
    try {
      setLoading(true);
      response = await OrganisationServiceV2.getDomainUsers(orgId);
      if (response && response.data.success) {
        setAllDomainUsers(response.data.data[0]);
        setTotal(response.data.data[1]);
      }
    } catch (error) {
      toast.error('An error occurred while attempting to get domain users');
    } finally {
      setLoading(false);
    }
  };

  const filterData = useMemo(() => {
    return allDomainUsers.filter((item) => {
      let isMatch = true;

      if (
        debouncedFilter.id !== '' &&
        !item.id.toString().includes(debouncedFilter.id)
      ) {
        isMatch = false;
      }

      if (
        debouncedFilter.email !== '' &&
        !item.email.includes(debouncedFilter.email)
      ) {
        isMatch = false;
      }

      return isMatch;
    });
  }, [debouncedFilter, allDomainUsers]);

  // Calculate the current page's data
  const currentData = useMemo(() => {
    const start = currentPage * pageSize;
    const end = start + pageSize;
    return filterData.slice(start, end);
  }, [filterData, currentPage, pageSize]);

  // useEffect(() => {
  //   getDomainUsers();
  // }, [orgId]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setCurrentPage(0);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <InputLabel>Id</InputLabel>
          <TextField
            type="number"
            size="small"
            placeholder=""
            value={filterInputs.id}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.id ? 'visible' : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({ ...prev, id: '' }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({ ...prev, id: e.target.value }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Email</InputLabel>
          <TextField
            type="string"
            size="small"
            value={filterInputs.email}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.email ? 'visible' : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({ ...prev, email: '' }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({ ...prev, email: e.target.value }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <TableViewComponent
          rows={currentData ?? []}
          columns={DomainUsersGridColumns}
          total={filterData.length}
          pageSizeCustom={pageSize}
          currentPage={currentPage}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          paginationMode="server"
        />
      </Box>
    </>
  );
};

export default DomainUsersView;
