import { Box, Divider } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Config from '../../../config';
import { BillingServiceV2 } from '../../../shared/service/services_v2';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import TableViewComponent from '../../components/TableViewComponent/TableViewComponent';
import { LoadingView } from '../../components/loadingView';
import { RootViewContext } from '../RootView';
import { useGetCreditRequests } from './hooks/useGetCreditRequests';
import { CreditRequestsGridColumns } from './utils/grid-columns/CreditRequestsGridColumns';

const CreditRequestViewRevised = () => {
  const { setAppBreadCrumbs } = useContext(RootViewContext);
  const { data, isLoading, refetch } = useGetCreditRequests();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);

  /**
   * Handler for Updating the request based on type
   *
   * 1 -> Approve
   * 2 -> Reject
   * @param requestId
   * @param type
   * @returns
   */
  const handleUpdateCrediRequest = async (requestId: number, type: string) => {
    try {
      if (!requestId || !type) return;

      await BillingServiceV2.updateCreditRequest(requestId, type);
      refetch();
    } catch (err) {
      toast.error('Error occurred while attempting to update credit request');
    }
  };

  useEffect(() => {
    setAppBreadCrumbs([
      {
        label: 'Commercial',
        path: Config.paths.marketing.home,
        type: 'link',
      },
      {
        label: 'Credit Requests',
        type: 'text',
      },
    ]);
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Box>
      <SectionHeader title={'Credit Requests'} />

      <Divider sx={{ my: 5 }} />

      <TableViewComponent
        rows={data ?? []}
        columns={CreditRequestsGridColumns({ handleUpdateCrediRequest })}
        pageSizeCustom={pageSize}
        currentPage={Math.ceil(currentPage / pageSize)}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        onPageChange={(page) => {
          const skipTemp = page * pageSize;
          setCurrentPage(skipTemp);
        }}
        total={data?.length}
      />
    </Box>
  );
};

export default CreditRequestViewRevised;
