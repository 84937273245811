import { Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { CheckIcon, XIcon } from '@primer/octicons-react';
import StyledButton from '../../../../widgets/styledButton/StyledButton';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';

const dateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour12: true,
  hour: '2-digit',
  minute: '2-digit',
} as const;

export const CreditRequestsGridColumns = ({
  handleUpdateCrediRequest,
}: {
  handleUpdateCrediRequest: (requestId: number, type: string) => Promise<void>;
}): GridColDef[] => [
  {
    flex: 0.5,
    minWidth: 60,
    field: 'id',
    headerName: 'RefId',
    renderCell: (params: GridRenderCellParams) => {
      return <Typography>{params.row.id}</Typography>;
    },
  },
  {
    flex: 1,
    minWidth: 60,
    field: 'createdAt',
    headerName: 'Created On',
    renderCell: (params: GridRenderCellParams) => {
      const dateText = params.row?.createdAt
        ? new Date(params.row?.createdAt).toLocaleDateString(
            'en-IN',
            dateOptions,
          )
        : 'N/A';

      return <TooltipTypography title={dateText} />;
    },
  },
  {
    flex: 0.5,
    minWidth: 60,
    field: 'userName',
    valueGetter: (params) => params.row?.user?.name,
    headerName: 'User Name',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.user?.name ?? 'N/A'} />;
    },
  },
  {
    flex: 0.5,
    minWidth: 60,
    field: 'email',
    valueGetter: (params) => params.row?.user?.email,
    headerName: 'User Email',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.user?.email ?? 'N/A'} />;
    },
  },
  {
    flex: 0.5,
    minWidth: 60,
    field: 'type',
    valueGetter: (params) => {
      params.row?.type === '0' ? 'Coaching' : 'Clinical';
    },
    headerName: 'Request Type',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography>
          {params.row?.type === '0' ? 'Coaching' : 'Clinical'}
        </Typography>
      );
    },
  },
  {
    flex: 2,
    minWidth: 60,
    field: 'action',
    headerName: '',
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return params?.row?.status === '0' ? (
        <>
          <Box display={'flex'} gap={1}>
            <StyledButton
              size="small"
              margin={0}
              sx={{
                backgroundColor: 'var(--chip-green-bg-color)',
                color: 'var(--chip-green-color)',
                borderRadius: 'var(--default-border-radius)',
                '&:hover': {
                  backgroundColor: 'var(--chip-green-bg-color)',
                },
              }}
              onClick={async () =>
                handleUpdateCrediRequest(params?.row?.id, '1')
              }
              endIcon={<CheckIcon size={18} />}
            >
              Approve
            </StyledButton>
            <StyledButton
              size="small"
              color="red"
              margin={0}
              variant="ghost"
              sx={{
                backgroundColor: 'var(--chip-red-bg-color)',
                color: 'var(--chip-red-color)',
                borderRadius: 'var(--default-border-radius)',
                '&:hover': {
                  backgroundColor: 'var(--chip-red-bg-color)',
                },
              }}
              onClick={async () =>
                handleUpdateCrediRequest(params?.row?.id, '2')
              }
              endIcon={<XIcon size={18} />}
            >
              Reject
            </StyledButton>
          </Box>
        </>
      ) : (
        <>
          {params.row?.status === '1' ? (
            <Typography display="inline" color="success.main" fontWeight="bold">
              Approved
            </Typography>
          ) : (
            <Typography display="inline" color="error.main" fontWeight="bold">
              Rejected
            </Typography>
          )}
          &nbsp;on&nbsp;
          {new Date(params.row?.updatedAt).toLocaleDateString(
            'en-IN',
            dateOptions,
          )}
        </>
      );
    },
  },
];
