import { ApiConfigV2 } from '../../apiConfigV2';
import { NetworkInstanceV2 } from './NetworkInstance';

class AuditLog {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getAuditLogs = ({
    url = ApiConfigV2.api.auditlog.getAuditLogs,
    refId,
    queryParams = {},
  }: {
    refId: string;
    url?: string;
    queryParams?: Record<string, any>;
  }) => {
    return this.client.get(url?.replace('{{refId}}', refId), {
      params: queryParams,
    });
  };
}
export { AuditLog };
