import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import {
  ChiefProviderServiceV2,
  ProviderServiceV2,
} from '../../../../shared/service/services_v2';

export const useRescheduleMeeting = (onSuccess: () => void) =>
  useMutation({
    mutationKey: ['rescheduleMeeting'],
    mutationFn: ({
      id,
      body,
    }: {
      id: string;
      body: Record<
        'userId' | 'scheduledStartTime' | 'duration' | 'role',
        string
      >;
    }) => ChiefProviderServiceV2.rescheduleMeeting(id, body),
    onSuccess() {
      toast.success('Session rescheduled successfully!');
      onSuccess();
    },
    onError() {
      toast.error(
        'An error occurred when attempting to reschedule the meeting.',
      );
    },
  });
