import { createTheme, LinkProps } from '@mui/material';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { ChevronDownIcon } from '@primer/octicons-react';
import { forwardRef } from 'react';

import {
  StyledCheckedCheckbox,
  StyledUnheckedCheckbox,
} from '../view/widgets/styledCheckbox/StyledCheckbox';

const LinkBehaviour = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

LinkBehaviour.displayName = 'LinkBehaviour';

const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
      contrastText: '#3E445B',
    },
    secondary: {
      main: '#3E445B',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#ec7979',
    },
    success: {
      main: '#67D157',
    },
  },
  typography: {
    fontFamily: [
      'Satoshi-Variable',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    fontWeightRegular: 500,
    subtitle1: {
      color: '#8C90A6',
      fontWeight: '500',
      fontSize: '1em',
    },
    subtitle2: {
      color: '#8C90A6',
      fontWeight: '600',
      fontSize: '1em',
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehaviour,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '.MuiAccordion-root': {
            border: 'none',
          },
          '.Mui-disabled': {
            color: 'var(--default-disabled-color)',
            backgroundColor: 'var(--default-disabled-bg-color)',
          },
          '&::before': {
            display: 'none',
          },
          boxShadow: 'none',
          borderRadius: 'var(--default-border-radius)',
          border: '1px solid #DDDEE3',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '.MuiInputBase-input': {
            borderRadius: 'var(--default-border-radius)',
          },
          '.Mui-disabled': {
            color: 'var(--default-disabled-color)',
            backgroundColor: 'var(--default-disabled-bg-color)',
          },
          '&.Mui-paper': {
            borderRadius: 'var(--default-border-radius)',
          },
          '& .MuiChip-label': {
            color: 'black',
          },
          border: '1px solid #DDDEE3',
          borderRadius: 'var(--default-border-radius)',
          '&::placeholder': {
            color: '#8C90A6',
          },
          '&.Mui-error': {
            borderColor: 'var(--default-error-color)',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ChevronDownIcon,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          '& .MuiAutocomplete-listbox': {
            '& .MuiAutocomplete-option': {
              '&[aria-selected="true"]': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
              },
            },
          },
        },
      },
    },
    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       textTransform: 'none',
    //       minWidth: '10rem',
    //       '&.Mui-selected': {
    //         color: 'var(--tab-selected-color)',
    //         fontWeight: 'bold',
    //         backgroundColor: 'var(--tab-selected-bg-color)',
    //         borderRadius: 'var(--default-border-radius)',
    //       },
    //       '&.MuiButtonBase-root': {
    //         fontSize: '1rem',
    //       },
    //     },
    //   },
    // },
    // MuiTabs: {
    //   styleOverrides: {
    //     indicator: {
    //       height: 0,
    //     },
    //   },
    // },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-selected': {
            color: '#3E445B',
            fontWeight: 'bold',
          },
          '&.MuiButtonBase-root': {
            fontSize: '1rem',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#3E445B',
        },
        root: {
          borderBottom: '1px solid #E3E3E3',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#3E445B',
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <StyledUnheckedCheckbox />,
        checkedIcon: <StyledCheckedCheckbox />,
      },
      styleOverrides: {
        root: {
          color: '#AEAEB2',
          borderRadius: 'var(--default-border-radius)',
          '&.Mui-checked': {
            color: '#3E445B',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&.MuiInput-underline:after': {
            borderBottomColor: '#3E445B',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiMenu-paper': {
            padding: 5,
            borderRadius: 'var(--default-border-radius)',
          },
          '& .MuiMenuItem-root': {
            paddingY: 1,
            color: '#707484',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: 'var(--default-border-radius)',
          '&.Mui-selected': {
            backgroundColor: '#f5f5f5',
            fontWeight: 'bold',
            color: '#3E445B',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
          // color: '#8C90A6',
          // borderRadius: 'var(--default-border-radius)',
          // backgroundColor: 'white',
          // padding: 10,
          // boxShadow:
          //   'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
        },
      },
    },
  },
}); // Base MUI theme

export default baseTheme;
