import { ApiConfigV2 } from '../../apiConfigV2';
import { EmailTemplateDetail } from '../../types/emailTemplates/EmailTemplate';
import { NetworkInstanceV2 } from './NetworkInstance';
import {
  Email,
  EmailOverride,
  EmailTemplate as EmailTemplateType,
} from '../../types/emailTemplates/email';

export class EmailTemplate {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  create = async (emailTemplate: Partial<EmailTemplateDetail>) =>
    this.client.post(ApiConfigV2.api.emailTemplates.create, {
      name: emailTemplate.name,
      lang: emailTemplate.lang,
      email: encodeURIComponent(emailTemplate.emailTemplate as string),
      subject: encodeURIComponent(emailTemplate.subjectTemplate as string),
    });

  getOne = async (templateId: string) => {
    return this.client.get(
      ApiConfigV2.api.emailTemplates.getOne.replace(
        '{{templateId}}',
        templateId,
      ),
    );
  };

  getList = async (page?: number) => {
    const config = { params: { page } };
    return this.client.get(ApiConfigV2.api.emailTemplates.getList, config);
  };

  updateEmail = async (id: string, email: Partial<Email>) =>
    this.client.patch(
      ApiConfigV2.api.emailTemplates.getEmail.replace('{{id}}', id),
      {
        name: email.name,
        description: email.description,
        config: email.config,
      },
    );
  createEmail = async (email: Partial<Email>) =>
    this.client.post(ApiConfigV2.api.emailTemplates.getEmails, {
      name: email.name,
      description: email.description,
      config: email.config,
      isActive: true,
      isHidden: false,
      canToggle: true,
    });

  getEmailList = async (page?: number, pageSize?: number, q?: string) => {
    const config = { params: { page, pageSize, q } };
    return this.client.get(ApiConfigV2.api.emailTemplates.getEmails, config);
  };

  getEmail = async (id: string) => {
    return this.client.get(
      ApiConfigV2.api.emailTemplates.getEmail.replace('{{id}}', id),
    );
  };

  getEmailTemplateList = async (
    page?: number,
    emailId?: string,
    lang?: string,
    orgId?: number,
  ) => {
    const config = { params: { page, emailId, lang, orgId } };
    return this.client.get(ApiConfigV2.api.emailTemplates.getTemplates, config);
  };

  getEmailTemplate = async (id: string) => {
    return this.client.get(
      ApiConfigV2.api.emailTemplates.getTemplate.replace('{{id}}', id),
    );
  };

  createEmailTemplate = async (email: Partial<EmailTemplateType>) =>
    this.client.post(ApiConfigV2.api.emailTemplates.getTemplates, {
      emailId: email.emailId,
      subject: encodeURIComponent(email.subject as string),
      template: encodeURIComponent(email.template as string),
      orgId: email.orgId,
      lang: email.lang,
    });

  updateEmailTemplate = async (id: string, template: string, subject: string) =>
    this.client.patch(
      ApiConfigV2.api.emailTemplates.getTemplate.replace('{{id}}', id),
      {
        template: encodeURIComponent(template),
        subject: encodeURIComponent(subject),
      },
    );

  getEmailOverrideList = async (
    page?: number,
    emailId?: string,
    lang?: string,
    orgId?: number,
  ) => {
    const config = { params: { page, emailId, lang, orgId } };
    return this.client.get(
      ApiConfigV2.api.emailTemplates.getOverrideList,
      config,
    );
  };

  createEmailOverride = async (override: Partial<EmailOverride>) =>
    this.client.post(ApiConfigV2.api.emailTemplates.getOverrideList, {
      orgId: override.orgId,
      emailId: override.emailId,
      config: override.config,
      isActive: true,
    });

  updateEmailOverride = async (id: string, override: Partial<EmailOverride>) =>
    this.client.patch(
      ApiConfigV2.api.emailTemplates.getOverride.replace('{{id}}', id),
      {
        config: override.config,
        isActive: override.isActive,
      },
    );

  getEmailOverride = async (id: string) =>
    this.client.get(
      ApiConfigV2.api.emailTemplates.getOverride.replace('{{id}}', id),
    );
}
