import { Box, IconButton, Link, Tooltip } from '@mui/material';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { InfoIcon } from '@primer/octicons-react';
import moment from 'moment';
import {
  OrganisationStatusInList,
  PilotMaxDays,
  TrialMaxDays,
} from '../../../../../shared/constants/Organisation';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';
import StatusChip from '../../../../components/StatusChip/StatusChip';
import {
  GenerateActivePlanSummary,
  SortActivePlansByExpiryDate,
} from '../../../../../utilities/Common';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';
import Config from '../../../../../config';

export interface OrgMappedProviders {
  providerName: string;
  providerId: any;
  providerServices: any;
}
export const OrgMappedProviderColumns: ({
  handleDeleteProvider,
}: {
  handleDeleteProvider: (index: number) => void;
}) => GridColDef[] = ({ handleDeleteProvider }) => [
  {
    flex: 1,
    minWidth: 80,
    field: 'providerId',
    headerName: 'providerId',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.providerId ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'providerName',
    headerName: 'providerName',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.providerName ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'services',
    headerName: 'Services',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.services ?? 'N/A'} />;
    },
  },
  {
    flex: 0.5,
    minWidth: 20,
    field: 'action',
    headerName: 'Delete',
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <IconButton
          color="error"
          onClick={() => handleDeleteProvider(params?.row?.providerId)}
        >
          {MaterialIcons.Delete}
        </IconButton>
      );
    },
  },
];
