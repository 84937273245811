import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationDetailItem } from '../../../../../../shared/types/organisation/OrganisationDetailItem';

export const useOutplacement = (onMigrationSuccess: () => void) =>
  useMutation({
    mutationFn: ({
      orgId,
      outplacementDetails,
    }: {
      orgId: number;
      outplacementDetails: any;
    }) => OrganisationServiceV2.runOutplacement(orgId, outplacementDetails),
    onError: (error: any) => {
      if (error.response.status === 500) {
        toast.error(
          'An error occurred while migrating user to outplacement organisation.',
        );
      } else if (error && error.response && error.response.data) {
        const errorMessage =
          error?.response?.data?.errors[0] || error.response.data.message;
        toast.error(`Error: ${errorMessage}`);
      } else {
        toast.error(
          'An error occurred while migrating user to outplacement organisation.',
        );
      }
    },
    onSuccess(_data, { outplacementDetails }) {
      toast.success('successfully migrated to outplacement org', {
        duration: 10000,
      });
      onMigrationSuccess();
    },
  });
