import { Box, InputLabel, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { MaterialIcons } from '../../../../../../assets/MaterialIcons';

import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import { OrganisationTeamMemberItem } from '../../../../../../shared/types/organisation/OrganisationTeamMemberItem';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import AddMembersDialog from '../organisationMembers/AddMembersDialog';
import MemberTableViewRevised from '../organisationMembers/MemberTableViewRevised';
import AddTeamDialog from './AddTeamDialog';

const EditTeamDialog = ({
  open,
  handleClose,
  refreshTeams,
  teamToEdit,
  teams,
  orgId,
}: {
  open: boolean;
  handleClose: () => void;
  refreshTeams: () => void;
  teamToEdit: OrganisationTeamItem | null;
  teams: OrganisationTeamItem[];
  orgId: number;
}) => {
  const [teamData, setTeamData] = useState<Partial<OrganisationTeamItem>>({});
  const [teamMembers, setTeamMembers] = useState<OrganisationTeamMemberItem[]>(
    [],
  );
  const [csRep, setCsRep] = useState(false);
  const [showAddMemberDialog, setShowAddMemberDialog] =
    useState<boolean>(false);

  const [showAddChildTeamDialog, setShowAddChildTeamDialog] =
    useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const getMembersOfTeam = useCallback(async () => {
    if (teamData.id) {
      let response = null;

      try {
        response = await OrganisationServiceV2.getMembersByTeamIdV2(
          teamData.id,
        );

        if (response && response?.data?.success) {
          setTeamMembers(response?.data?.data?.members ?? []);
          setCsRep(response?.data?.data?.csRep ?? false);
        }
      } catch (error) {
        toast.error(
          "An error occurred while attempting to get this team's members.", // eslint-disable-line quotes
        );
      }
    }
  }, [teamData.id]);

  useEffect(() => {
    if (teamToEdit) {
      setTeamData(teamToEdit);
    }
  }, [teamToEdit]);

  useEffect(() => {
    if (teamData.id) {
      getMembersOfTeam();
    }
  }, [getMembersOfTeam, teamData.id]);

  // For Add-ons
  const addOns = teamData?.addOns?.trimStart().split(' ') ?? [];

  const handleToggleCoach = () => {
    let updatedAddOns = [];
    if (addOns.includes('coach')) {
      updatedAddOns = addOns.filter((addOn: string) => addOn !== 'coach');
    } else {
      updatedAddOns = [...addOns, 'coach'];
    }
    setTeamData({ ...teamData, addOns: updatedAddOns.join(' ') });
  };
  const refreshData = () => {
    getMembersOfTeam();
    refreshTeams();
  };

  const handleUpdateTeam = async () => {
    if (teamData.id) {
      let response = null;

      try {
        setLoading(true);
        response = await OrganisationServiceV2.updateTeamV2(teamData.id, {
          name: teamData.name,
          addOns: teamData.addOns,
          coachCredits: teamData.coachCredits,
        });

        if (response && response.data.success) {
          toast.success('Team updated successfully!');
          refreshTeams();
          handleClose();
        }
      } catch (error) {
        toast.error('An error occurred while attempting to update the team.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChildTeamCreation = async (teamName: string) => {
    try {
      if (!teamData?.id) return;

      setLoading(true);

      const response = await OrganisationServiceV2.addChildTeam(teamData?.id, {
        teamName,
      });

      if (response && response.data.success) {
        toast.success('Child Team added successfully!');
        setShowAddChildTeamDialog(false);
        refreshTeams();
        handleClose();
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'An error occurred while attempting to update the team.',
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <GenericDialog
        open={open}
        handleClose={handleClose}
        noFullScreen
        title={`Viewing team '${teamToEdit?.name ?? ''}'`}
        extraElements={
          <Box display={'flex'} justifyContent={'flex-end'}>
            <StyledButton
              variant="ghost"
              size="small"
              color="gray"
              startIcon={MaterialIcons.Add}
              onClick={() => setShowAddChildTeamDialog(true)}
            >
              Add Child Team
            </StyledButton>
            <StyledButton
              size="small"
              sx={{ width: '8rem' }}
              onClick={handleUpdateTeam}
            >
              Save
            </StyledButton>
          </Box>
        }
      >
        <Box padding={3}>
          {/* <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SwitchButton
              size="small"
              margin="0 0 1rem"
              active={addOns.includes('coach')}
              onClick={handleToggleCoach}
            >
              {`Coach ${
                teamData?.addOns?.includes('coach') ? 'enabled' : 'disabled'
              }`}
            </SwitchButton>
            {teamData.addOns && teamData.addOns !== '' && (
              <Box display="flex" alignItems="center">
                <Typography fontWeight="bold" marginRight={2}>
                  Add-ons:
                </Typography>
                {teamData.addOns
                  .split(' ')
                  .map(
                    (addOn: string) =>
                      addOn !== '' && (
                        <Chip
                          key={addOn}
                          color="secondary"
                          label={addOn.toUpperCase()}
                        />
                      )
                  )}
              </Box>
            )}
          </Box> */}
          <Box display="flex" alignItems="flex-end">
            <Box width="50%" marginRight={2}>
              <InputLabel>Team name</InputLabel>
              <TextField
                id="teamData-name"
                fullWidth
                value={teamData.name}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                ) => {
                  setTeamData({
                    ...teamData,
                    name: event.target.value,
                  });
                }}
              />
            </Box>
            {/* {teamData?.addOns?.includes('coach') && (
              <Box width="50%">
                <InputLabel>Max usage</InputLabel>
                <TextField
                  type="number"
                  fullWidth
                  id="standard-max-usage"
                  value={teamData?.coachCredits}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                  ) => {
                    setTeamData({
                      ...teamData,
                      coachCredits: Number(event.target.value),
                    });
                  }}
                />
              </Box>
            )} */}
          </Box>

          <Box marginTop={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" fontWeight="bold">
                Team members
              </Typography>

              <StyledButton
                color="gray"
                variant="ghost"
                onClick={() => setShowAddMemberDialog(true)}
                startIcon={MaterialIcons.Add}
              >
                Add members
              </StyledButton>
            </Box>

            <MemberTableViewRevised
              members={teamMembers}
              refreshData={refreshData}
              teams={teams}
              orgId={orgId}
              teamId={teamToEdit?.id}
              csRep={csRep}
            />
          </Box>
        </Box>
      </GenericDialog>
      {teamData.id && (
        <AddMembersDialog
          open={showAddMemberDialog}
          handleClose={() => setShowAddMemberDialog(false)}
          refreshData={refreshData}
          teamId={teamData.id}
          existingEmails={teamMembers.map(
            (teamMember: OrganisationTeamMemberItem) => teamMember.emailAddress,
          )}
          orgId={orgId}
        />
      )}

      {teamData?.id && (
        <AddTeamDialog
          open={showAddChildTeamDialog}
          handleClose={() => setShowAddChildTeamDialog(false)}
          handleSubmit={(teamName: string) => handleChildTeamCreation(teamName)}
          parentTeam={teamToEdit}
          loading={loading}
          key={`${teamData?.id}-${open}`}
        />
      )}
    </>
  );
};

export default EditTeamDialog;
