import moment, { Moment } from 'moment';
import lodash, { clone } from 'lodash';
import { ISlotTime } from './interfaces/Date';
import {
  IProviderDaySlot,
  ISlotRange,
  ISlots,
} from '../view/components/ScheduleTimeSlots/types';
import { dayOfWeekNumbers } from '../shared/constants/Common';
import { ILocationsList } from '../view/screens/inPerson/utils/types/Locations';

export const getTimeZone = () => moment.tz.guess(true);

export const getSlotTz = (
  date: string,
  slot: string,
  timeZone: string,
  duration?: number,
) => {
  const slotDateTime = getSlotDateTime(date, +slot);
  return {
    date,
    slot,
    displayDate: slotDateTime.tz(timeZone).format('YYYY/MM/DD'),
    displayTime: slotDateTime.tz(timeZone).format('hh:mmA'),
    displayTimeEnd: duration
      ? slotDateTime.tz(timeZone).add(duration, 's').format('hh:mmA')
      : slotDateTime.tz(timeZone).format('hh:mmA'),
  };
};

const getSlotDateTime = (date: string, slot: number) =>
  moment.utc(
    `${date} ${Math.floor((+slot * 30) / 60).toFixed(0)}:${(+slot * 30) % 60}`,
    'YYYY/MM/DD HH:mm',
  );

export const getSlotsByWeekDay = (slotDays: ISlots[], timeZone: string) => {
  const offset = moment.tz(timeZone).utcOffset();

  const weekDays = slotDays.reduce(
    (
      res: Record<string, Omit<IProviderDaySlot, 'slotsRange'>>,
      cur: ISlots,
    ) => {
      if (cur.slots) {
        cur.slots.split(',').forEach((slot: string) => {
          const time = moment(
            `${cur.dayOfWeek} ${slotToTime(+slot)}`,
            'd HH:mm',
          ).add(offset, 'minute');

          const day = time.format('d');
          const locationId = cur.locationId || 'virtual';

          if (!res[day]) {
            res[day] = {
              day: day,
              dayDisplay: time.format('dddd'),
              slots: { [locationId]: [], virtual: [] },
            };
          }

          if (!res[day].slots[locationId]) {
            res[day].slots[locationId] = [];
          }

          res[day].slots[locationId].push(
            +time.format('HH') * 2 +
              +Math.floor(+time.format('mm') / 30).toFixed(0),
          );
        });
      }
      return res;
    },
    {},
  );
  return convertDayMapToSlotRange(weekDays);
};

export const slotToTime = (slot: number) =>
  `${formatNumberTo2Digits(
    +Math.floor((+slot * 30) / 60).toFixed(0),
  )}:${formatNumberTo2Digits((+slot * 30) % 60)}`;

/**
 * Method to convert slots from one timezone to another timezone
 * Used in Care Calendar where people are in singapore timezone and setting slots in provider's timezone.
 * @param param0
 * @returns
 */
export const convertSlotsByTimezone = ({
  slots,
  fromTimeZone = 'Asia/Singapore',
  toTimeZone,
  fromDate,
}: {
  slots: number[];
  fromTimeZone?: string;
  toTimeZone: string;
  fromDate: string;
}) => {
  const fromOffset = moment.tz(fromTimeZone).utcOffset();
  const toOffset = moment.tz(toTimeZone).utcOffset();

  const [startingSlot, endingSlot] = slots;

  const startTime = moment(
    `${fromDate} ${slotToTime(startingSlot)}`,
    'YYYY/MM/DD HH:mm',
  )
    .subtract(fromOffset, 'minutes')
    .add(toOffset, 'minutes');

  const endTime = moment(
    `${fromDate} ${slotToTime(endingSlot)}`,
    'YYYY/MM/DD HH:mm',
  )
    .subtract(fromOffset, 'minutes')
    .add(toOffset, 'minutes');

  const multiDaySlot = moment(startTime)
    .clone()
    .startOf('day')
    .isBefore(moment(endTime).clone().startOf('day'));

  const startSlot =
    +startTime.format('HH') * 2 +
    +Math.floor(+startTime.format('mm') / 30).toFixed(0);
  const endSlot =
    +endTime.format('HH') * 2 +
    +Math.floor(+endTime.format('mm') / 30).toFixed(0);

  if (!multiDaySlot) {
    return [
      {
        localDate: startTime.format('YYYY/MM/DD'),
        slots: [startSlot, endSlot],
      },
    ];
  } else {
    return [
      {
        localDate: startTime.format('YYYY/MM/DD'),
        slots: [startSlot, 47],
      },
      {
        localDate: endTime.format('YYYY/MM/DD'),
        slots: [0, endSlot],
      },
    ];
  }
};

/**
 * Generates 15 minutes time
 * @param slot
 * @returns
 */
export const slotTo15MinTime = (slot: number) =>
  `${formatNumberTo2Digits(
    +Math.floor((+slot * 15) / 60).toFixed(0),
  )}:${formatNumberTo2Digits((+slot * 15) % 60)}`;

const formatNumberTo2Digits = (input: number) => `0${input}`.slice(-2);

export const getSlotsByDate = (slotDays: any[], timeZone: string) => {
  const offset = moment.tz(timeZone).utcOffset();

  const weekDays = slotDays.reduce((res: any, cur: any) => {
    if (cur.slots) {
      cur.slots.split(',').forEach((slot: string) => {
        const time = moment(
          `${cur.date} ${slotToTime(+slot)}`,
          'YYYY/MM/DD HH:mm',
        ).add(offset, 'minute');

        const formattedTime = time.format('YYYY/MM/DD');
        const locationId = cur.locationId || 'virtual';

        if (!res[formattedTime]) {
          res[formattedTime] = {
            day: formattedTime,
            dayDisplay: formattedTime,
            slots: { [locationId]: [], virtual: [] },
            id: cur.id,
            variant: cur.variant,
            localDate: cur?.localDate ?? '',
          };
        }

        if (!res[formattedTime].slots[locationId]) {
          res[formattedTime].slots[locationId] = [];
        }

        res[formattedTime].slots[locationId].push(
          +time.format('HH') * 2 +
            +Math.floor(+time.format('mm') / 30).toFixed(0),
        );
      });
    }
    return res;
  }, {});
  return convertDayMapToSlotRange(weekDays);
};

const convertDayMapToSlotRange = (
  input: Record<string, Omit<IProviderDaySlot, 'slotsRange'>>,
) => {
  const weekDays = clone(input) as Record<string, IProviderDaySlot>;

  Object.keys(weekDays).forEach((key: string) => {
    weekDays[key].slotsRange = [];
    Object.entries(weekDays[key].slots).forEach(
      ([locationIdKey, slotsForLocationId]) => {
        if (slotsForLocationId.length) {
          weekDays[key].slots[locationIdKey] = slotsForLocationId.sort(
            (a: number, b: number) => a - b,
          );

          let current = slotsForLocationId[0];
          let last = slotsForLocationId[0];

          slotsForLocationId.forEach((cur: number, index: number) => {
            if (index === 0) {
              // skip
            } else if (last === cur - 1) {
              last = cur;
            } else {
              weekDays[key].slotsRange.push({
                locationId: locationIdKey === 'virtual' ? null : locationIdKey,
                slots: [current, last],
              });

              current = cur;
              last = cur;
            }
          });

          weekDays[key].slotsRange.push({
            locationId: locationIdKey === 'virtual' ? null : locationIdKey,
            slots: [current, last],
          });
        }
      },
    );
  });

  return weekDays;
};

export const convertTo12HourFormat = (time: string, format = 'h:mm A') =>
  moment(time, 'HH:mm').format(format);

export const generate30MinTimeIntervals = ({
  offset = 0,
  isEnd,
  is12HrFormat = true,
}: {
  offset?: number;
  isEnd?: boolean;
  is12HrFormat?: boolean;
}): ISlotTime[] => {
  // TODO make it generic
  const format = is12HrFormat ? 'hh:mm A' : 'HH:mm';
  const timeIntervals: ISlotTime[] = lodash.range(offset, 48).map((slot) => ({
    slot,
    time: convertTo12HourFormat(slotToTime(+slot + (isEnd ? 1 : 0)), format),
  }));

  // for (let hour = 0; hour < 24; hour += 1) {
  //   timeIntervals.push(moment({ hour }).format('h:mm A'));
  //   timeIntervals.push(
  //     moment({
  //       hour,
  //       minute: 30,
  //     }).format('h:mm A'),
  //   );
  // }

  return timeIntervals;
};

export const transformSlotsToWeekDay = (
  slotMap: Record<string, IProviderDaySlot>,
  timeZone = getTimeZone(),
  clinicsList: Partial<ILocationsList[]> = [],
  toUtcSlots = true,
) => {
  const offset = moment.tz(timeZone).utcOffset();

  const momentArray: { [locationId: string]: Moment[] } = { virtual: [] };

  Object.values(slotMap).forEach((providerSlots) => {
    providerSlots.slotsRange.forEach((slotRange) => {
      const locationId = slotRange.locationId || 'virtual';
      if (!momentArray[locationId]) {
        momentArray[locationId] = [];
      }

      const mergedSlots = lodash.range(
        slotRange.slots[0],
        +slotRange.slots[1] + 1,
      );

      mergedSlots.forEach((mergedSlot) => {
        if (toUtcSlots) {
          momentArray[locationId].push(
            moment(
              `${providerSlots.day} ${slotToTime(+mergedSlot)}`,
              'd HH:mm',
            ).subtract(offset, 'minutes'),
          );
        } else {
          momentArray[locationId].push(
            moment(
              `${providerSlots.day} ${slotToTime(+mergedSlot)}`,
              'd HH:mm',
            ),
          );
        }
      });
    });
  });

  const dMap: Record<string, { [locationId: string]: Set<number> }> =
    Object.fromEntries(
      dayOfWeekNumbers.map((day) => [
        day,
        {
          ...Object.fromEntries(
            clinicsList.map((clinic) => [clinic?.id, new Set<number>()]),
          ),
          virtual: new Set<number>(),
        },
      ]),
    );

  Object.entries(momentArray).forEach(([locId, times]) => {
    times.forEach((time) => {
      if (!dMap[+time.format('d')][locId]) {
        dMap[+time.format('d')][locId] = new Set();
      }

      dMap[+time.format('d')][locId].add(
        +time.format('HH') * 2 +
          +Math.floor(+time.format('mm') / 30).toFixed(0),
      );
    });
  });

  const body: ISlots[] = Object.entries(dMap).flatMap(([day, slotsOfDay]) =>
    Object.entries(slotsOfDay).map(([locId, slotsAtLoc]) => {
      const slots: number[] = [];
      slotsAtLoc.forEach((slot) => slots.push(slot));
      return {
        dayOfWeek: +day,
        locationId: locId === 'virtual' ? null : locId,
        slots: slots.sort((a: number, b: number) => a - b).join(','),
      };
    }),
  );

  return body;
};

export const getSlotsByWeekDayForDailyOverrides = (
  slotDays: ISlots[],
  timeZone: string,
) => {
  const offset = moment.tz(timeZone).utcOffset();

  const weekDays = slotDays.reduce(
    (
      res: Record<string, Omit<IProviderDaySlot, 'slotsRange'>>,
      cur: ISlots,
    ) => {
      if (cur.slots) {
        cur.slots.split(',').forEach((slot: string) => {
          const time = moment(
            `${cur.dayOfWeek} ${slotToTime(+slot)}`,
            'd HH:mm',
          ).add(offset, 'minute');
          if (!res[cur.dayOfWeek]) {
            res[cur.dayOfWeek] = {
              day: time.format('d'),
              dayDisplay: time.format('dddd'),
              slots: { [cur.locationId || 'virtual']: [], virtual: [] },
            };
          }

          if (!res[cur.dayOfWeek].slots[cur.locationId || 'virtual']) {
            res[cur.dayOfWeek].slots[cur.locationId || 'virtual'] = [];
          }

          res[cur.dayOfWeek].slots[cur.locationId || 'virtual'].push(
            +time.format('HH') * 2 +
              +Math.floor(+time.format('mm') / 30).toFixed(0),
          );
        });
      }
      return res;
    },
    {},
  );

  Object.keys(weekDays).forEach((day) => {
    Object.keys(weekDays[day].slots).forEach((key) => {
      weekDays[day].slots[key] = lodash.uniq(weekDays[day].slots[key]).sort();
    });
  });

  return convertDayMapToSlotRange(weekDays);
};

/**
 * Checks for overlapping slots
 * @param newSlot
 * @param existingSlots
 * @returns {boolean}
 */
export const validateSlotsOverlap = (
  newSlot: ISlotRange,
  existingSlots: ISlotRange[],
): boolean => {
  return existingSlots?.some(
    (existingSlot) =>
      (newSlot.slots[0] >= existingSlot.slots[0] &&
        newSlot.slots[0] <= existingSlot.slots[1]) ||
      (newSlot.slots[1] >= existingSlot.slots[0] &&
        newSlot.slots[1] <= existingSlot.slots[1]) ||
      (newSlot.slots[0] <= existingSlot.slots[0] &&
        newSlot.slots[1] >= existingSlot.slots[1]),
  );
};
/**
 * Returns hourly difference between timezones
 *
 * For eg: -2.5 Hrs
 * @param timezone1
 * @param timezone2
 * @returns
 */
export const getDifferenceBetweenTwoTimezones = (
  timezone1: string,
  timezone2: string,
) => {
  const timeInTimezone1 = moment().tz(timezone1);
  const timeInTimezone2 = moment().tz(timezone2);

  const offset1 = timeInTimezone1.utcOffset();
  const offset2 = timeInTimezone2.utcOffset();

  const diffInMinutes = offset2 - offset1;

  const sign = diffInMinutes >= 0 ? '+' : '-';

  const absoluteDiffInMinutes = Math.abs(diffInMinutes);
  const hours = Math.floor(absoluteDiffInMinutes / 60);
  const minutes = absoluteDiffInMinutes % 60;

  const formattedDifference = `${sign}${hours}:${minutes
    .toString()
    .padStart(2, '0')}`;

  return formattedDifference;
};

/**
 * Get all the weekly startdates based on given range
 * @param startDate
 * @param endDate
 * @returns
 */
export const getWeeklyStartDates = (
  startDate: Moment | null,
  endDate: Moment | null,
) => {
  const weeklyDates: string[] = [];

  if (!startDate || !endDate) return weeklyDates;

  const start = moment(startDate).startOf('week'); // Start of the week for startDate
  const end = moment(endDate).endOf('week'); // End of the week for endDate

  while (start.isSameOrBefore(end)) {
    weeklyDates.push(start.format('YYYY-MM-DD'));
    start.add(1, 'week'); // Move to the next week
  }

  return weeklyDates;
};
