import { Theme } from '@emotion/react';
import { Box, SxProps } from '@mui/material';
import { motion, Variants } from 'framer-motion';
import { ReactNode } from 'react';

const MotionBox = motion(Box);

const fadeVariants = {
  enter: {
    opacity: 0,
  },
  center: {
    opacity: 1,
    transition: { ease: 'easeOut', duration: 0.25 },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.25,
      type: 'tween',
    },
  },
};

const slideVariants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? '100%' : '-100%',
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '100%' : '-100%',
      opacity: 0,
    };
  },
};

const SlidingSection = ({
  variant = 'slide',
  children,
  sx,
}: {
  variant?: 'slide' | 'fade';
  children: ReactNode;
  sx?: SxProps<Theme>;
}) => (
  <MotionBox
    variants={variant === 'slide' ? slideVariants : fadeVariants}
    initial='enter'
    animate='center'
    exit='exit'
    transition={{
      x: { type: 'tween', duration: 0.5 },
    }}
    sx={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      ...(Array.isArray(sx) ? sx : [sx]),
    }}
  >
    {children}
  </MotionBox>
);

export default SlidingSection;
