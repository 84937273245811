import { useQuery } from '@tanstack/react-query';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';

export const useGetOrgDomainEntityConfigs = (orgId?: number) =>
  useQuery({
    queryKey: [orgId],
    staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes
    cacheTime: 10 * 60 * 1000, // Data is kept in cache for 10 minutes
    queryFn: () => {
      if (orgId) {
        return OrganisationServiceV2.getDomainEntityConfigs(orgId);
      }
    },
    meta: {
      errorMessage:
        'An error occurred while attempting to get the org entity configs.',
    },
    select: (data) => data?.data?.data ?? [],
  });
