import React, { useRef, useState } from 'react';

import XLSX from 'xlsx';
import { Box } from '@mui/system';
import { UploadIcon } from '@primer/octicons-react';
import StyledButton from '../../widgets/styledButton/StyledButton';
import toast from 'react-hot-toast';
import { OrganisationServiceV2 } from '../../../shared/service/services_v2';

const onImportExcel = (ref: any, onUploaded: (data: any) => void) => {
  //  gets the uploaded file object

  // const { files } = file.target;

  //  the file is read through the filereader object

  const fileReader = new FileReader();

  fileReader.onload = (event) => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { result } = event.target;

      //  the entire excel spreadsheet object is read as a binary stream

      const workbook = XLSX.read(result, { type: 'binary' });

      //  stores the retrieved data

      let data: any = [];

      //  walk through each sheet to read （ by default, only the first table is read ）

      for (const sheet in workbook.Sheets) {
        // esline-disable-next-line

        if (Object.prototype.hasOwnProperty.call(workbook.Sheets, sheet)) {
          //  using sheet_to_json  method will be excel  into json  data

          data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));

          // break; //  if you only take the first table, uncomment the row
        }
      }

      //  finally obtained and formatted json  data
      onUploaded(data);
      console.log(' uploaded successfully ！', data);
    } catch (e) {
      //  here you can throw a hint that the file type error is incorrect

      console.error(' incorrect file type ！');
    }
  };

  //  open the file in binary mode

  fileReader.readAsBinaryString(ref.files[0]);
};

export const ExcelUploadView = ({ orgId }: { orgId: number }) => {
  const [selectedFile, setSelectedFile] = React.useState<File>();
  const [fileName, setfileName] = React.useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const FileUploader = () => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleClick = (_event: any) => {
      hiddenFileInput?.current?.click();
    };

    const handleChange = (event: any) => {
      setSelectedFile(event.target.files[0]);
      setfileName(event.target.files[0].name);
    };

    return (
      <Box display={'flex'}>
        <StyledButton
          onClick={handleClick}
          color="gray"
          variant={'ghost'}
          startIcon={<UploadIcon size={24} />}
        >
          Upload Members
        </StyledButton>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </Box>
    );
  };

  const handleUpload = async (file: Blob) => {
    toast.loading('Upload in progress...');

    let response = null;

    try {
      setLoading(true);
      response = await OrganisationServiceV2.uploadExcel(orgId, file);

      if (response && response.data.success) {
        toast.success(
          'Upload successful, please refresh the page to see changes!',
        );
        setSelectedFile(undefined);
      }
    } catch (error) {
      toast.error(
        'An error occurred while attempting to import the Excel file.',
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {!selectedFile ? (
        <FileUploader />
      ) : (
        <>
          <StyledButton
            onClick={() => {
              handleUpload(selectedFile as Blob);
            }}
            startIcon={<UploadIcon size={18} />}
            disabled={loading}
          >
            Finish Import
          </StyledButton>
        </>
      )}
    </Box>
  );
};
