import {
  Autocomplete,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useContext, useEffect, useMemo, useState } from 'react';
import toast, { LoaderIcon } from 'react-hot-toast';
import { OrganisationServiceV2 } from '../../../../../shared/service/services_v2';
import { GenericDialog } from '../../../../components/genericDialog';
import StyledButton from '../../../../widgets/styledButton/StyledButton';
import LabelWithAsterik from '../../../../components/LabelWithAsterik/LabelWithAsterik';
import { useGetProviderListNoPagination } from '../../../provider/hooks/UseGetProviderList';
import SearchableCheckBox from '../../../../widgets/searchableCheckBox/SearchableCheckBox';

const AddOrgProviderDialog = ({
  open,
  handleClose,
  handleSuccess,
  orgId,
}: {
  open: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  orgId: string;
}) => {
  const [providerMap, setProviderMap] = useState<any>({});

  const { data: providerList } = useGetProviderListNoPagination();

  const [loading, setLoading] = useState(false);

  const handleDialogClose = () => {
    handleClose();
  };
  useEffect(() => {
    if (providerList) {
      setProviderMap(
        providerList?.reduce((res: any, cur: any) => {
          res[cur.userId] = cur;
          return res;
        }, {}),
      );
    }
  }, [providerList]);

  const [selectedProviderId, setSelectedProviderId] = useState<
    number | string | null
  >(null);

  const handleAddOrgProvider = async () => {
    try {
      if (selectedProviderId) {
        const response = await OrganisationServiceV2.addOrgMappedProviders(
          +orgId,
          +selectedProviderId,
        );
        if (response && response.data.success) {
          handleSuccess();
          handleDialogClose();
        }
      }
    } catch (err) {
      toast.error('An error occurred while attempting to add domain.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <GenericDialog
      noFullScreen
      open={open}
      handleClose={handleDialogClose}
      title={'Add Provider'}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '150px' }}
      >
        <Grid item xs={4}>
          <InputLabel>Providers</InputLabel>
          <Autocomplete
            key={`${selectedProviderId}`}
            options={providerList || []}
            getOptionLabel={(option) =>
              option?.profile?.name || `${option.userId}`
            }
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Provider" />
            )}
            size="small"
            value={
              providerList?.find(
                (provider) => provider.userId === selectedProviderId,
              ) || null
            }
            onChange={(event, newValue) => {
              setSelectedProviderId(newValue ? newValue.userId : null);
            }}
            isOptionEqualToValue={(option, value) =>
              option.userId === value?.userId
            }
          />
          <StyledButton
            padding="0.5rem"
            sx={{ width: '7.5rem' }}
            onClick={async () => {
              handleAddOrgProvider();
            }}
          >
            Submit
          </StyledButton>
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

export default AddOrgProviderDialog;
