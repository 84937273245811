import { Fragment, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import copy from 'copy-to-clipboard';
import { CopyIcon, PencilIcon } from '@primer/octicons-react';

import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { MaterialIcons } from '../../../../../../../assets/MaterialIcons';
import { OrganisationAccessCode } from '../../../../../../../shared/types/organisation/OrganisationDetailItem';
import StyledButton from '../../../../../../widgets/styledButton/StyledButton';
import AddAccessCodeDialog from './AddAccessCodeDialog';
import { MarketingServiceV2 } from '../../../../../../../shared/service/services_v2';
import FeatureBox from '../../../../../../widgets/featureBox';
import { OrganisationoDetailContext } from '../../OrganisationDetailView';
import SectionHeader from '../../../../../../components/SectionHeader/SectionHeader';
import { TooltipTypography } from '../../../../../../components/TooltipTypography/TooltipTypography';

const AccessCodesView = () => {
  const { accessCodes, getAccessCodes, refetchAuditLogs, setRefetchAuditLogs } =
    useContext(OrganisationoDetailContext);

  const [showAddAccessCodeDialog, setShowAddAccessCodeDialog] = useState<{
    open: boolean;
    edit: boolean;
    accessCode: OrganisationAccessCode | null;
  }>({ open: false, edit: false, accessCode: null });

  const { orgId }: { orgId: string } = useParams();

  const handleSuccess = () => {
    getAccessCodes();
    setShowAddAccessCodeDialog({
      open: false,
      edit: false,
      accessCode: null,
    });
    setRefetchAuditLogs(!refetchAuditLogs);
  };

  return (
    <>
      <Box>
        <SectionHeader
          title={<Typography variant="h5">Access codes</Typography>}
          rightElement={
            <StyledButton
              size="small"
              color="gray"
              variant="ghost"
              startIcon={MaterialIcons.Add}
              onClick={() =>
                setShowAddAccessCodeDialog({
                  open: true,
                  edit: false,
                  accessCode: null,
                })
              }
              sx={{ margin: 0 }}
            >
              Add access code
            </StyledButton>
          }
          sx={{ marginBottom: 2 }}
        />

        <Grid
          container
          spacing={2}
          padding={1}
          sx={{
            maxHeight: '250px',
            overflow: 'hidden',
            overflowY: 'scroll',
          }}
        >
          {accessCodes
            ?.sort(
              (a, b) =>
                a.currentUsage / a.maxUsage - b.currentUsage / b.maxUsage,
            )
            .map((accessCode: OrganisationAccessCode) => (
              <Fragment key={accessCode.code}>
                <Grid item xs={4}>
                  <FeatureBox
                    sx={{
                      margin: 0,
                      height: '100px',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                        }}
                      >
                        <TooltipTypography
                          sx={{
                            fontSize: '18px',
                            maxWidth: '4vw',
                            fontWeight: 700,
                          }}
                          title={accessCode.code}
                        />
                        <IconButton
                          onClick={() => {
                            copy(accessCode.code);
                            toast.success(`${accessCode.code} Copied!`);
                          }}
                          sx={{ margin: 0 }}
                        >
                          <CopyIcon size={14} />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setShowAddAccessCodeDialog({
                              open: true,
                              edit: true,
                              accessCode: accessCode,
                            });
                          }}
                          sx={{ margin: 0 }}
                        >
                          <PencilIcon size={14} />
                        </IconButton>
                      </Box>

                      <TooltipTypography
                        variant="subtitle2"
                        maxWidth={'5vw'}
                        color={
                          accessCode.currentUsage < accessCode.maxUsage
                            ? 'var(--chip-green-color)'
                            : 'var(--chip-red-color)'
                        }
                        title={`(${accessCode.currentUsage} / ${accessCode.maxUsage})`}
                      />
                    </Box>
                    <TooltipTypography
                      title={accessCode.domainRestriction || ' '}
                      variant="subtitle1"
                    />
                  </FeatureBox>
                </Grid>
              </Fragment>
            ))}
        </Grid>
      </Box>

      <AddAccessCodeDialog
        orgId={orgId}
        open={showAddAccessCodeDialog.open}
        handleClose={() =>
          setShowAddAccessCodeDialog({
            open: false,
            edit: false,
            accessCode: null,
          })
        }
        handleSuccess={handleSuccess}
        edit={showAddAccessCodeDialog.edit}
        accessCode={showAddAccessCodeDialog.accessCode}
        key={`${showAddAccessCodeDialog.open}-${showAddAccessCodeDialog.edit}`}
      />
    </>
  );
};

export default AccessCodesView;
