import {
  Grid,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import { OrganisationTeamMemberRole } from '../../../../../../shared/constants/Organisation';
import {
  ChiefProviderServiceV2,
  OrganisationServiceV2,
} from '../../../../../../shared/service/services_v2';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { Country } from '../../../../../../shared/types/provider/Provider';
import { useGetCountries } from '../../../../../../utilities/hooks/useGetCountries';

const AddMembersDialog = ({
  open,
  handleClose,
  refreshData,
  existingEmails,
  teamId,
  orgId,
}: {
  open: boolean;
  handleClose: () => void;
  refreshData: () => void;
  existingEmails: string[];
  teamId: number;
  orgId: number;
}) => {
  const [members, setMembers] = useState<
    {
      emailAddress: string;
      role: string;
      countryCode?: string;
    }[]
  >([{ emailAddress: '', role: '0', countryCode: '' }]);

  const { data: countries } = useGetCountries();
  const [addHRManagerToAllTeams, setAddHRManagerToAllTeams] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null); // Change type to Country | null

  const handleSubmit = async () => {
    let response = null;
    let HrResponse = null;
    let ManagerResponse = null;
    let filteredHrEmails = [];
    let filteredManagerEmails = [];
    try {
      if (addHRManagerToAllTeams === true) {
        filteredHrEmails = members
          ?.filter((item) => item.role === '2')
          ?.map((item) => item.emailAddress);

        if (filteredHrEmails.length) {
          const hrObj = {
            emailList: filteredHrEmails,
            role: '2',
          };
          HrResponse = await OrganisationServiceV2.bulkAddTeamMembers(
            orgId,
            hrObj,
          );
        }

        filteredManagerEmails = members
          ?.filter((item) => item.role === '1')
          ?.map((item) => item.emailAddress);

        if (filteredManagerEmails.length) {
          const ManagerObj = {
            emailList: filteredManagerEmails,
            role: '1',
          };
          ManagerResponse = await OrganisationServiceV2.bulkAddTeamMembers(
            orgId,
            ManagerObj,
          );
        }
      } else {
        response = await OrganisationServiceV2.addMultipleTeamMembers(teamId, {
          newMembers: members.filter(
            (member) => !existingEmails.includes(member.emailAddress),
          ),
          existingMembers: members.filter((member) =>
            existingEmails.includes(member.emailAddress),
          ),
        });
      }
      if (
        (response && response.data.success) ||
        (HrResponse && HrResponse.data.success) ||
        (ManagerResponse && ManagerResponse.data.success)
      ) {
        refreshData();
        toast.success(
          'Member Adding to All Teams Job has started. Changes will be reflected shortly. Please check back later.',
        );
        handleClose();
        setMembers([{ emailAddress: '', role: '0' }]);
      }
    } catch (error) {
      toast.error(
        'An error occurred while adding the team members. Please try again.',
      );
    }
  };

  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      noFullScreen
      title="Add members"
      extraElements={
        <>
          {members.some(
            (member) => member.role === '1' || member.role === '2',
          ) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={addHRManagerToAllTeams}
                  onChange={() => setAddHRManagerToAllTeams((prev) => !prev)}
                />
              }
              label="Add HR or Manager to all teams"
            />
          )}

          <StyledButton
            color="light"
            variant="ghost"
            startIcon={MaterialIcons.Add}
            onClick={() =>
              setMembers([...members, { emailAddress: '', role: '0' }])
            }
          >
            Add another member
          </StyledButton>
          <StyledButton sx={{ width: '7rem' }} onClick={handleSubmit}>
            Save
          </StyledButton>
        </>
      }
    >
      <Grid
        padding={5}
        maxWidth="75vw"
        width="75vw"
        maxHeight="75vh"
        overflow="auto"
        container
        spacing={3}
        columns={15}
      >
        {members.map(
          (
            member: {
              emailAddress: string;
              role: string;
              countryCode?: string;
            },
            index: number,
          ) => {
            const emailExists = existingEmails.includes(member.emailAddress);
            return (
              <>
                <Grid item xs={7}>
                  <InputLabel>Email address</InputLabel>
                  <TextField
                    value={member.emailAddress}
                    fullWidth
                    type="email"
                    error={emailExists}
                    autoFocus
                    helperText={
                      emailExists
                        ? 'A member with this email already exists in this team. Submitting will overwrite them.'
                        : ' '
                    }
                    onChange={(event) => {
                      const oldData = [...members];
                      oldData[index].emailAddress = event.target.value;
                      setMembers(oldData);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel>Role</InputLabel>
                  <Select
                    fullWidth
                    value={member.role}
                    onChange={(event) => {
                      const oldData = [...members];
                      oldData[index].role = event.target.value;
                      setMembers(oldData);
                    }}
                  >
                    {Object.keys(OrganisationTeamMemberRole).map(
                      (key: string) => (
                        <MenuItem key={key} value={key}>
                          {OrganisationTeamMemberRole[key].label}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Country</InputLabel>
                  <Autocomplete
                    disabled={false}
                    options={countries ?? []}
                    getOptionLabel={(option: Country) => option.countryName}
                    value={
                      countries?.find(
                        (country) => country.code === member.countryCode,
                      ) || null
                    }
                    onChange={(event: any, newValue: Country | null) => {
                      const oldData = [...members];
                      oldData[index].countryCode = newValue?.code || '';
                      setMembers(oldData);
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                    key={`countryAutocomplete${member.countryCode}`}
                  />
                </Grid>
                <Grid>
                  <IconButton
                    color="error"
                    size="large"
                    onClick={() =>
                      setMembers(members.filter((item, i) => i !== index))
                    }
                  >
                    {MaterialIcons.Delete}
                  </IconButton>
                </Grid>
              </>
            );
          },
        )}
      </Grid>
    </GenericDialog>
  );
};

export default AddMembersDialog;
