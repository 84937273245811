import {
  ProductionHostname,
  ProductionJPHostname,
  StorageItems,
} from '../shared/constants/App';
import Cookies from 'js-cookie';
import { removeItemFromLocalStorage } from './Storage';
import { gotoRoute } from './Path';

export const isProduction = () =>
  window.location.hostname === ProductionHostname;

export const isProductionJapan = () =>
  window.location.hostname === ProductionJPHostname;

/**
 * Removes cookie & localstorage
 */
export const logOutUser = () => {
  Cookies.remove('user');
  removeItemFromLocalStorage(StorageItems.USER_INFO);
  gotoRoute('/admin/login');
};
