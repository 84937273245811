import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  CaseNoteTypes,
  CaseNotesMeetingStatusMapping,
  CaseNotesMeetingTypeMapping,
} from '../../../../../shared/constants/Provider';
import {
  convertUnixEpochToDateTime,
  getDisplayDate,
} from '../../../../../utilities/Display';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';
import { encodeUserId } from '../../../provider/detail/providerDetailTabs/meetings/ProviderSessionView';
import { ICaseNotes } from '../types/CaseNotes';

export const CaseNotesGridColumns: GridColDef[] = [
  {
    flex: 1,
    minWidth: 120,
    field: 'id',
    headerName: 'Id',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return <TooltipTypography title={params?.row?.id} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'caseId',
    headerName: 'Case ID',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.userId ? encodeUserId(params?.row?.userId) : '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={params?.row?.userId ? encodeUserId(params?.row?.userId) : ''}
        />
      );
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'friendlyName',
    headerName: 'Friendly Name',
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params.row?.friendlyName ?? '',
    sortable: true,
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return <TooltipTypography title={params.row?.friendlyName ?? ''} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'provider',
    headerName: 'Provider',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return <TooltipTypography title={params.row?.provider ?? ''} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'status',
    headerName: 'Session Status',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      CaseNotesMeetingStatusMapping?.[params.row?.status] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={CaseNotesMeetingStatusMapping?.[params.row?.status] ?? ''}
        />
      );
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'type',
    headerName: 'Session Medium',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      CaseNotesMeetingTypeMapping?.[params.row?.type] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={CaseNotesMeetingTypeMapping?.[params.row?.type] ?? ''}
        />
      );
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'scheduledDate',
    headerName: 'Session Date',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.scheduledDate
        ? getDisplayDate(params?.row?.scheduledDate)
        : '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={
            params?.row?.scheduledDate
              ? getDisplayDate(params?.row?.scheduledDate)
              : ''
          }
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'scheduledStartTime',
    headerName: 'Session Start Time',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.scheduledStartTime
        ? convertUnixEpochToDateTime(+params?.row?.scheduledStartTime)
        : '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={
            params?.row?.scheduledStartTime
              ? convertUnixEpochToDateTime(+params?.row?.scheduledStartTime)
              : ''
          }
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'scheduledEndTime',
    headerName: 'Session End Time',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.scheduledEndTime
        ? convertUnixEpochToDateTime(+params?.row?.scheduledEndTime)
        : '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={
            params?.row?.scheduledEndTime
              ? convertUnixEpochToDateTime(+params?.row?.scheduledEndTime)
              : ''
          }
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'mainPresentingIssue',
    headerName: 'Main Presenting Issue',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.notes?.[CaseNoteTypes.PrimaryGoal] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={params?.row?.notes?.[CaseNoteTypes.PrimaryGoal] ?? ''}
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'subPresentingIssue',
    headerName: 'Sub Presenting Issue',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.notes?.[CaseNoteTypes.SecondaryGoal] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={params?.row?.notes?.[CaseNoteTypes.SecondaryGoal] ?? ''}
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'riskLevel',
    headerName: 'Risk Level',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.notes?.[CaseNoteTypes.RiskAssesment] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={params?.row?.notes?.[CaseNoteTypes.RiskAssesment] ?? ''}
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'techniques',
    headerName: 'Techniques/modalities/interventions',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.notes?.[CaseNoteTypes.InterventionsAndModality] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={
            params?.row?.notes?.[CaseNoteTypes.InterventionsAndModality] ?? ''
          }
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'formulations',
    headerName: 'Formulation',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.notes?.[CaseNoteTypes.FormulationAndTreatment] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={
            params?.row?.notes?.[CaseNoteTypes.FormulationAndTreatment] ?? ''
          }
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'recommendations',
    headerName: 'Recommendations',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.notes?.[CaseNoteTypes.Recommendations] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={params?.row?.notes?.[CaseNoteTypes.Recommendations] ?? ''}
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'additionalSessionRequired',
    headerName: 'Additional Session Required',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.notes?.[CaseNoteTypes.AdditonalSessionRecommended] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={
            params?.row?.notes?.[CaseNoteTypes.AdditonalSessionRecommended] ??
            ''
          }
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'progressTowardsGoals',
    headerName: 'Progress Towards Goals',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.notes?.[CaseNoteTypes.ProgressTowardsGoals] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={params?.row?.notes?.[CaseNoteTypes.ProgressTowardsGoals] ?? ''}
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'followUpPlan',
    headerName: 'Follow Up Plan',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.notes?.[CaseNoteTypes.FollowUpPlan] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={params?.row?.notes?.[CaseNoteTypes.FollowUpPlan] ?? ''}
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'sharedFollowUpPlan',
    headerName: 'Shared Follow Up Plan',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.notes?.[CaseNoteTypes.SharedFollowUpPlan] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={params?.row?.notes?.[CaseNoteTypes.SharedFollowUpPlan] ?? ''}
        />
      );
    },
  },
  {
    flex: 1.5,
    minWidth: 150,
    field: 'suicidalAssesment',
    headerName: 'Suicidal Assesment',
    headerAlign: 'center',
    align: 'center',
    sortable: true,
    valueGetter: (params: GridRenderCellParams<ICaseNotes>) =>
      params?.row?.notes?.[CaseNoteTypes.SuicidalAssesment] ?? '',
    renderCell: (params: GridRenderCellParams<ICaseNotes>) => {
      return (
        <TooltipTypography
          title={params?.row?.notes?.[CaseNoteTypes.SuicidalAssesment] ?? ''}
        />
      );
    },
  },
];
