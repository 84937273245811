import { Switch } from 'react-router-dom';
import { AuthenticatedRoute } from '../../components/authenticatedRoute/AuthenticatedRoute';
import Config from '../../../config';
import UsersList from './UsersList';

const UserRoleManagementView = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path={Config.paths.userManagement.home}
        component={UsersList}
      />
    </Switch>
  );
};

export default UserRoleManagementView;
