import { NetworkInstanceV2 } from './NetworkInstance';
import { ApiConfigV2 } from '../../apiConfigV2';

export class Content {
  private client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getAppLocalesStatus = () => {
    return this.client.get(ApiConfigV2.api.course.appLocales);
  };

  syncAppLocales = (language: string) => {
    return this.client.post(ApiConfigV2.api.course.appLocales, {
      languages: [language],
    });
  };
}
