import {
  Box,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';

import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { ConfigItem } from '../../../../../../shared/types/organisation/OrganisationDetailItem';
import ConfirmDialog from '../../../../../components/confirmDialog/ConfirmDialog';
import FeatureBox from '../../../../../widgets/featureBox';
import { OrganisationoDetailContext } from '../OrganisationDetailView';
import OrganisationMappedProviders from '../OrganisationMappedProviders';
import OrganisationDomainMappings from '../sso/OrganisationDomainMappings';
import ConfigRow from './ConfigRow';
import { ConfigKeys } from './constants';

const ConfigTableColumns = ['ID', 'Key', 'Value'];

const ConfigTable = ({
  showLiteMode,
  configList,
  configToEdit,
  setConfigToEdit,
  getConfigs,
}: {
  showLiteMode?: boolean;
  configList: ConfigItem[];
  getConfigs: () => void;
  configToEdit: ConfigItem | null;
  setConfigToEdit: React.Dispatch<React.SetStateAction<ConfigItem | null>>;
}) => {
  const { orgData } = useContext(OrganisationoDetailContext);
  const [configToDelete, setConfigToDelete] = useState<ConfigItem | null>(null);

  const handleSelect = (itemToSelect: ConfigItem) => {
    setConfigToEdit(itemToSelect);
  };

  const handleDeselect = () => {
    setConfigToEdit(null);
  };

  const handleUpdate = async (config: ConfigItem) => {
    let response = null;

    try {
      response = await OrganisationServiceV2.updateConfig(config);

      if (response && response.data.success) {
        toast.success('Config updated successfully!');
        getConfigs();
        handleDeselect();
      }
    } catch (error) {
      toast.error('An error occurred while attempting to update the config.');
    }
  };
  const handleDelete = async () => {
    if (!orgData?.id) return;

    if (configToDelete) {
      try {
        const response = await OrganisationServiceV2.deleteOrganisationConfig(
          orgData.id,
          configToDelete.id,
        );

        if (response && response.data.success) {
          toast.success('Config deleted successfully!');
          getConfigs();
          setConfigToDelete(null);
        }
      } catch (error) {
        toast.error('An error occurred while attempting to delete the config.');
      }
    }
  };

  return (
    <>
      {showLiteMode && configList?.length ? (
        <FeatureBox>
          <Grid container={true} rowSpacing={2} columnSpacing={3}>
            {showLiteMode &&
              configList.map((config: ConfigItem) => {
                return (
                  <Grid key={`config-key-${config.key}`} item xs={6}>
                    <Box flexGrow={1}>
                      <InputLabel htmlFor={'input-label-' + config.key}>
                        {ConfigKeys[config.key]
                          ? ConfigKeys[config.key].title
                          : config.key}
                      </InputLabel>
                      <TextField
                        onChange={(event) => {
                          const { value } = event.target;
                          // setUpdatedData({
                          //   ...updatedData,
                          //   totalHeadCount: isNaN(Number(value)) ? 0 : Number(value),
                          // });
                        }}
                        autoFocus
                        fullWidth
                        disabled={true}
                        id="totalHeadCount"
                        value={config.value}
                        placeholder="Add total head count"
                        sx={{ marginBottom: 2 }}
                      />
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </FeatureBox>
      ) : (
        <></>
      )}

      {!showLiteMode && (
        <TableContainer sx={{ maxWidth: '70vw' }}>
          <Table>
            <TableHead>
              <TableRow>
                {ConfigTableColumns.map((column: string) => (
                  <TableCell
                    key={column}
                    align="center"
                    sx={{ fontWeight: 'bold' }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '& .MuiTableRow-root': {
                  transition: 'all 0.1s linear',
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: '#ededed',
                    '& .MuiTableCell-root': {
                      color: 'secondary.main',
                    },
                  },
                },
                '& .MuiTableCell-root': {
                  color: '#8C90A6',
                  align: 'center',
                  fontSize: 16,
                },
              }}
            >
              {configList.map((config: ConfigItem) => (
                <ConfigRow
                  key={config.id}
                  configData={config}
                  selectedConfig={configToEdit?.id}
                  handleSelect={handleSelect}
                  handleDeselect={handleDeselect}
                  handleUpdate={handleUpdate}
                  setConfigToDelete={setConfigToDelete}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <OrganisationMappedProviders orgId={orgData?.id} />
      <OrganisationDomainMappings />

      <ConfirmDialog
        open={!!configToDelete}
        handleClose={() => setConfigToDelete(null)}
        onConfirm={handleDelete}
        title="Delete config?"
      >
        Are you sure you want to delete{' '}
        {configToDelete ? (
          <>
            the config <strong>{configToDelete.key}</strong>
          </>
        ) : (
          'this config'
        )}
        ?
      </ConfirmDialog>
    </>
  );
};

export default ConfigTable;
