import { Box } from '@mui/material';

const NoDataComponent = ({
  noDataMsg = ' Sorry !! No results found',
}: {
  noDataMsg?: string;
}) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{ p: '24px' }}
  >
    {noDataMsg || (
      <>
        <Box display={'flex'} flexDirection={'column'} gap={4}>
          <Box sx={{ fontSize: '16px', color: '#252B2C', textAlign: 'center' }}>
            {noDataMsg}
          </Box>
        </Box>
      </>
    )}
  </Box>
);

export default NoDataComponent;
