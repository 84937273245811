import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { ClearOutlined } from '@mui/icons-material';
import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import {
  OrganisationTeamMemberRoleDropdownValues,
  TeamMemberStatusDropdownValues,
  TeamMemberStatusEnum,
} from '../../../../../../shared/constants/Organisation';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { NumberFieldStyles } from '../../../../../../shared/styles/Common';
import { OrganisationTeamMemberItem } from '../../../../../../shared/types/organisation/OrganisationTeamMemberItem';
import useDebounce from '../../../../../../utilities/hooks/useDebounce';
import SectionHeader from '../../../../../components/SectionHeader/SectionHeader';
import TableViewComponent from '../../../../../components/TableViewComponent/TableViewComponent';
import ConfirmDialog from '../../../../../components/confirmDialog/ConfirmDialog';
import DownloadTemplateView from '../../../../../components/fileUpload/downloadTemplate';
import { ExcelExportView } from '../../../../../components/fileUpload/excelExport';
import { ExcelUploadView } from '../../../../../components/fileUpload/excelUpload';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { ClientMembersGridColumns } from '../../../utils/grid-columns/ClientMembersGridColumns';
import BulkOffboardDialog from './BulkOffboardDialog';
import DeleteMembersByEmailDialog from './DeleteMembersByEmailDialog';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import EditBulkMembersTeamDialog from './EditBulkMembersTeamDialog';
import { ItemContains } from '../../../../../../utilities/Common';
import { AlertIcon } from '@primer/octicons-react';
import MembersInMultipleTeamsDialog from './MembersInMultipleTeamsDialog';
import { OrganisationoDetailContext } from '../OrganisationDetailView';

interface IMemberTableRevisedProps {
  orgId: number;
  csRep?: boolean; // it denotes whether current user is the representative of this org or not, on which some fields are shown
  refreshMembers: () => void;
  data: OrganisationTeamMemberItem[];
  handleEditRow: (index: number) => void;
  teams: OrganisationTeamItem[];
  teamId?: number; // denotes whether it's a particular team view or global members view (all members)
}

/**
 * Max limit for changing user status & teams at a single time
 */
export const MAX_LIMIT_FOR_BLK_MEMS_UPDATE = 50;

const MemberTableRevised = ({
  refreshMembers,
  data,
  teams,
  orgId,
  handleEditRow,
  teamId,
  csRep = false,
}: IMemberTableRevisedProps) => {
  const { membersInMultipleTeams } = useContext(OrganisationoDetailContext);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [showConfirmDeleteMembersDialog, setShowConfirmDeleteMembersDialog] =
    useState<boolean>(false);

  const [
    showConfirmActivateMembersDialog,
    setShowConfirmActivateMembersDialog,
  ] = useState<boolean>(false);

  const [
    showConfirmChangePendingMembersDialog,
    setShowConfirmChangePendingMembersDialog,
  ] = useState<boolean>(false);

  const [showDeleteMembersByEmailDialog, setShowDeleteMembersByEmailDialog] =
    useState<boolean>(false);

  const [showBulkOffboardDialog, setShowBulkOffboardDialog] =
    useState<boolean>(false);

  const [showBulkTeamChangeDialog, setShowBulkTeamChangeDialog] =
    useState<boolean>(false);

  const [
    showMembersInMultipleTeamsDialog,
    setShowMembersInMultipleTeamsDialog,
  ] = useState(false);

  const [memberIds, setMemberIds] = useState<number[]>([]);

  useEffect(() => {
    setMemberIds([]);
  }, [data]);

  const [filterInputs, setFilterInputs] = useState<{
    id: string;
    emailAddress: string;
    userId: string;
    organisationTeamId: string;
    organisationTeamName: string;
    countryName: string;
    status: string;
    role: string;
  }>({
    id: '',
    emailAddress: '',
    userId: '',
    organisationTeamId: '',
    organisationTeamName: '',
    countryName: '',
    status: '',
    role: '',
  });
  const debouncedFilter = useDebounce(filterInputs, 500);

  const deleteSelectedMembers = async () => {
    let response = null;

    try {
      setConfirmLoading(true);
      response = await OrganisationServiceV2.deleteMultipleMembersById(
        memberIds,
      );

      if (response && response.data.success) {
        toast.success('Selected members removed successfully!');
        refreshMembers();
        setShowConfirmDeleteMembersDialog(false);
      }
    } catch (error) {
      toast.error(
        'An error occurred while trying to remove the selected members. Please try again.',
      );
    } finally {
      setConfirmLoading(false);
    }
  };

  const activateSelectedMembers = async () => {
    let response = null;

    try {
      setConfirmLoading(true);
      response = await OrganisationServiceV2.activateSelectedMembers(
        orgId,
        memberIds,
      );

      if (response && response.data.success) {
        toast.success('Selected members activated successfully!');
        refreshMembers();
        setShowConfirmActivateMembersDialog(false);
      }
    } catch (error) {
      toast.error(
        'An error occurred while trying to remove the selected members. Please try again.',
      );
    } finally {
      setConfirmLoading(false);
    }
  };

  const changeStatusToPending = async () => {
    try {
      if (
        DisableChangeToPendingButton ||
        memberIds?.length > MAX_LIMIT_FOR_BLK_MEMS_UPDATE
      ) {
        return;
      }

      setConfirmLoading(true);
      const response = await OrganisationServiceV2.updateMembersToPendingState(
        orgId,
        memberIds,
      );
      if (response && response.data.success) {
        toast.success('Removed the selected members successfully!');
        refreshMembers();
        setShowConfirmChangePendingMembersDialog(false);
      }
    } catch (error) {
      toast.error(
        'An error occurred while trying to change status of the selected members. Please try again.',
      );
    } finally {
      setConfirmLoading(false);
    }
  };

  const filterData = useMemo(() => {
    const filteredData = [];
    for (const item of data) {
      let isMatch = true;

      if (filterInputs.id !== '' && !ItemContains(item?.id, filterInputs?.id)) {
        isMatch = false;
      }

      if (
        filterInputs.emailAddress !== '' &&
        !ItemContains(item?.emailAddress, filterInputs?.emailAddress)
      ) {
        isMatch = false;
      }

      if (
        filterInputs.userId !== '' &&
        !ItemContains(item?.userId, filterInputs?.userId)
      ) {
        isMatch = false;
      }

      if (
        filterInputs.organisationTeamId !== '' &&
        !ItemContains(
          item?.organisationTeamId,
          filterInputs?.organisationTeamId,
        )
      ) {
        isMatch = false;
      }

      if (
        filterInputs.organisationTeamName !== '' &&
        !ItemContains(
          item?.organisationTeamName,
          filterInputs?.organisationTeamName,
        )
      ) {
        isMatch = false;
      }

      if (
        filterInputs.countryName !== '' &&
        !ItemContains(item?.countryName, filterInputs?.countryName)
      ) {
        isMatch = false;
      }

      if (
        filterInputs.role !== '' &&
        !ItemContains(item?.role, filterInputs?.role)
      ) {
        isMatch = false;
      }

      if (
        filterInputs.status !== '' &&
        !ItemContains(item?.status, filterInputs?.status)
      ) {
        isMatch = false;
      }

      if (isMatch) {
        filteredData.push(item);
      }
    }
    return filteredData;
  }, [debouncedFilter, data]);

  const columns = useMemo(() => {
    return ClientMembersGridColumns({ handleEditRow });
  }, [data, handleEditRow]);

  const groupByTeamNames = useMemo(() => {
    return Array.from(
      new Set(data?.map((item) => item?.organisationTeamName)?.filter(Boolean)),
    )?.map((item) => ({ label: item, value: item }));
  }, [data]);

  const groupByCountryNames = useMemo(() => {
    return Array.from(
      new Set(data?.map((item) => item?.countryName)?.filter(Boolean)),
    )?.map((item) => ({ label: item, value: item }));
  }, [data]);

  const SelectedTeamName = useMemo(() => {
    return groupByTeamNames?.find(
      (item) => item?.value === filterInputs?.organisationTeamName,
    );
  }, [groupByTeamNames, filterInputs?.organisationTeamName]);

  const SelectedCountryName = useMemo(() => {
    return groupByCountryNames?.find(
      (item) => item?.value === filterInputs?.countryName,
    );
  }, [groupByCountryNames, filterInputs?.countryName]);

  const SelectedRole = useMemo(() => {
    return OrganisationTeamMemberRoleDropdownValues?.find(
      (item) => item?.value === filterInputs?.role,
    );
  }, [filterInputs?.role]);

  const SelectedStatus = useMemo(() => {
    return TeamMemberStatusDropdownValues?.find(
      (item) => item?.value === filterInputs?.status,
    );
  }, [filterInputs?.status]);

  /**
   * To make sure only Active/New users can migrate to Pending state
   */
  const DisableChangeToPendingButton = useMemo(() => {
    return data?.some(
      (item) =>
        memberIds?.includes(item?.id) &&
        item?.status !== TeamMemberStatusEnum.ACTIVE &&
        item?.status !== TeamMemberStatusEnum.NEW,
    );
  }, [memberIds, filterData, data]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <InputLabel>Ref ID</InputLabel>
          <TextField
            fullWidth
            type="number"
            size="small"
            placeholder=""
            value={filterInputs.id}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.id ? 'visible' : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({ ...prev, id: '' }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({ ...prev, id: e.target.value }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
        {csRep && (
          <Grid item xs={3}>
            <InputLabel>Email</InputLabel>
            <TextField
              fullWidth
              size="small"
              value={filterInputs.emailAddress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        visibility: filterInputs.emailAddress
                          ? 'visible'
                          : 'hidden',
                      }}
                      onClick={() =>
                        setFilterInputs((prev) => ({
                          ...prev,
                          emailAddress: '',
                        }))
                      }
                    >
                      <ClearOutlined fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setFilterInputs((prev) => ({
                  ...prev,
                  emailAddress: e.target.value,
                }));
              }}
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <InputLabel>User ID</InputLabel>
          <TextField
            fullWidth
            type="number"
            size="small"
            value={filterInputs.userId}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.userId ? 'visible' : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({ ...prev, userId: '' }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({ ...prev, userId: e.target.value }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Team ID</InputLabel>
          <TextField
            fullWidth
            type="number"
            size="small"
            value={filterInputs.organisationTeamId}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      visibility: filterInputs.organisationTeamId
                        ? 'visible'
                        : 'hidden',
                    }}
                    onClick={() =>
                      setFilterInputs((prev) => ({
                        ...prev,
                        organisationTeamId: '',
                      }))
                    }
                  >
                    <ClearOutlined fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFilterInputs((prev) => ({
                ...prev,
                organisationTeamId: e.target.value,
              }));
            }}
            sx={NumberFieldStyles}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Team Name</InputLabel>
          <Autocomplete
            fullWidth={true}
            size="small"
            options={groupByTeamNames}
            value={SelectedTeamName}
            getOptionLabel={(option) => option.value}
            onChange={(event, newValue) => {
              setFilterInputs((prev) => ({
                ...prev,
                organisationTeamName: newValue?.value ?? '',
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Country</InputLabel>
          <Autocomplete
            fullWidth={true}
            size="small"
            options={groupByCountryNames}
            value={SelectedCountryName}
            getOptionLabel={(option) => option.value}
            onChange={(event, newValue) => {
              setFilterInputs((prev) => ({
                ...prev,
                countryName: newValue?.value ?? '',
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Role</InputLabel>
          <Autocomplete
            fullWidth={true}
            size="small"
            options={OrganisationTeamMemberRoleDropdownValues}
            value={SelectedRole}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              setFilterInputs((prev) => ({
                ...prev,
                role: newValue?.value ?? '',
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={3}>
          <InputLabel>Status</InputLabel>
          <Autocomplete
            fullWidth={true}
            size="small"
            options={TeamMemberStatusDropdownValues}
            value={SelectedStatus}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
              setFilterInputs((prev) => ({
                ...prev,
                status: newValue?.value ?? '',
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: 4 }} />
      {/* Only showing this in org view not in teams view */}
      {!teamId && membersInMultipleTeams?.length ? (
        <Box display={'flex'} justifyContent={'flex-end'}>
          <StyledButton
            color="red"
            startIcon={<AlertIcon />}
            onClick={() => setShowMembersInMultipleTeamsDialog(true)}
            size="small"
            margin={0}
          >
            Check Duplicate Users
          </StyledButton>
        </Box>
      ) : (
        <></>
      )}

      <Box
        paddingX={3}
        marginTop={2}
        height="2rem"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {memberIds.length > 0 ? (
          <>
            <Typography variant="subtitle1">
              <b>{memberIds.length}</b> member
              {memberIds.length !== 1 && 's'} selected
              <StyledButton
                color="gray"
                variant="ghost"
                startIcon={MaterialIcons.Close}
                onClick={() => setMemberIds([])}
                sx={{ margin: 0 }}
              >
                Clear Selection
              </StyledButton>
            </Typography>
            <Box display={'flex'} justifyContent={'flex-end'}>
              {teamId ? (
                <StyledButton
                  color="gray"
                  variant="ghost"
                  startIcon={MaterialIcons.Switch}
                  onClick={() => setShowBulkTeamChangeDialog(true)}
                >
                  Change Team
                </StyledButton>
              ) : (
                <></>
              )}

              <StyledButton
                variant="ghost"
                startIcon={MaterialIcons.CheckBoxCircle}
                onClick={() => setShowConfirmActivateMembersDialog(true)}
                sx={{ color: 'var(--chip-green-color)' }}
              >
                Activate selected members
              </StyledButton>
              <StyledButton
                color="red"
                variant="ghost"
                startIcon={MaterialIcons.Delete}
                onClick={() => setShowConfirmDeleteMembersDialog(true)}
              >
                Remove selected members
              </StyledButton>
              <StyledButton
                color="gray"
                variant="ghost"
                onClick={() => setShowConfirmChangePendingMembersDialog(true)}
                startIcon={MaterialIcons.History}
              >
                Make it Pending
              </StyledButton>
            </Box>
          </>
        ) : (
          <Box display="flex">
            <StyledButton
              color="red"
              variant="ghost"
              startIcon={MaterialIcons.Delete}
              onClick={() => setShowDeleteMembersByEmailDialog(true)}
            >
              Delete members by email
            </StyledButton>
            <StyledButton
              color="red"
              variant="ghost"
              startIcon={MaterialIcons.Delete}
              onClick={() => setShowBulkOffboardDialog(true)}
            >
              Bulk offboard members
            </StyledButton>
            <ExcelExportView
              data={data}
              fileName={`Export:${orgId}:${new Date().toISOString()}`}
            />
            <ExcelUploadView orgId={orgId} />
            <DownloadTemplateView />
          </Box>
        )}
      </Box>

      <Box mt={2}>
        <TableViewComponent
          rows={filterData}
          columns={columns}
          total={filterData?.length}
          pageSizeCustom={pageSize}
          currentPage={Math.ceil(currentPage / pageSize)}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
          }}
          onPageChange={(page) => {
            const skipTemp = page * pageSize;
            setCurrentPage(skipTemp);
          }}
          checkboxSelection={true}
          keepNonExistentRowsSelected={true}
          onRowSelection={(value) => setMemberIds(value as number[])}
          rowSelectionModel={memberIds}
          hideColumns={!csRep ? ['emailAddress'] : []}
        />
      </Box>
      <ConfirmDialog
        onConfirm={activateSelectedMembers}
        handleClose={() => setShowConfirmActivateMembersDialog(false)}
        open={showConfirmActivateMembersDialog}
        title="Activate members?"
        disabled={memberIds?.length > 10 || confirmLoading}
      >
        {memberIds?.length > 10 ? (
          <>You cannot activate more than 10 members at a time.</>
        ) : (
          <>
            Are you sure you want to activate {memberIds.length} member
            {memberIds?.length !== 1 ? 's' : ''}?
          </>
        )}
      </ConfirmDialog>
      <ConfirmDialog
        onConfirm={deleteSelectedMembers}
        handleClose={() => setShowConfirmDeleteMembersDialog(false)}
        open={showConfirmDeleteMembersDialog}
        title="Remove members?"
        disabled={confirmLoading}
      >
        Are you sure you want to remove {memberIds.length} member
        {memberIds?.length !== 1 ? 's' : ''} from the organisation?
      </ConfirmDialog>

      <ConfirmDialog
        onConfirm={changeStatusToPending}
        handleClose={() => setShowConfirmChangePendingMembersDialog(false)}
        open={showConfirmChangePendingMembersDialog}
        title="Change status to Pending?"
        disabled={
          DisableChangeToPendingButton ||
          memberIds?.length > MAX_LIMIT_FOR_BLK_MEMS_UPDATE ||
          confirmLoading
        }
      >
        {DisableChangeToPendingButton
          ? 'Only Active/New users can be changed to Pending status.'
          : memberIds?.length > MAX_LIMIT_FOR_BLK_MEMS_UPDATE
          ? `Only ${MAX_LIMIT_FOR_BLK_MEMS_UPDATE} members are allowed to be updated at a single time.`
          : `Are you sure you want to change ${memberIds.length} member${
              memberIds?.length !== 1 ? 's' : ''
            } status to Pending status?`}
      </ConfirmDialog>

      <DeleteMembersByEmailDialog
        handleClose={() => setShowDeleteMembersByEmailDialog(false)}
        refreshData={refreshMembers}
        open={showDeleteMembersByEmailDialog}
        orgId={orgId}
      />
      <BulkOffboardDialog
        handleClose={() => setShowBulkOffboardDialog(false)}
        refreshData={refreshMembers}
        open={showBulkOffboardDialog}
      />
      <EditBulkMembersTeamDialog
        open={showBulkTeamChangeDialog}
        handleClose={() => setShowBulkTeamChangeDialog(false)}
        teams={teams}
        refreshData={refreshMembers}
        memberIds={memberIds}
        orgId={orgId}
        currentTeamId={teamId}
      />

      <MembersInMultipleTeamsDialog
        open={showMembersInMultipleTeamsDialog}
        onClose={() => setShowMembersInMultipleTeamsDialog(false)}
      />
    </>
  );
};

export default MemberTableRevised;
