import React from 'react';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { MaterialIcons } from '../../../assets/MaterialIcons';

export const HeaderView = ({
  title,
  onClose,
  extraElements,
}: {
  title?: string;
  onClose: () => void;
  extraElements?: React.ReactNode;
}) => (
  <AppBar
    sx={{
      position: 'relative',
      boxShadow: 'none',
      // backgroundColor: '#F9F9F9',
      padding: '0.5rem',
    }}
  >
    <Toolbar>
      <Typography
        variant="h5"
        fontWeight="bold"
        marginRight={5}
        sx={{
          flex: 1,
        }}
      >
        {title}
      </Typography>
      <Box display="flex" alignItems="center" gap={3}>
        {extraElements}
      </Box>

      <IconButton
        onClick={() => onClose()}
        aria-label="close"
        size="small"
        sx={{
          marginLeft: 8,
          backgroundColor: '#D7D7D7',
          color: '#FFFFFF',
        }}
      >
        {MaterialIcons.Close}
      </IconButton>
    </Toolbar>
  </AppBar>
);
