import { Box, Grid, IconButton, Typography } from '@mui/material';
import { IAuditLog } from './utils/types/audit-log.interface';
import SectionHeader from '../SectionHeader/SectionHeader';
import { getDisplayDateTime } from '../../../utilities/Display';
import { MaterialIcons } from '../../../assets/MaterialIcons';
import { Dispatch, useState } from 'react';

const AuditLogRow = ({
  log,
  setOpenDetailView,
}: {
  log: IAuditLog;
  setOpenDetailView: Dispatch<
    React.SetStateAction<{
      open: boolean;
      log: IAuditLog | null;
    }>
  >;
}) => {
  return (
    <Grid item xs={12} key={log._id}>
      <SectionHeader
        title={
          <Typography fontWeight="bold">
            {log?.event ?? log?.operation}
          </Typography>
        }
        subTitle={`${getDisplayDateTime(log?.createdAt)} | ${
          log.userName ?? log?.userId
        }`}
        rightElement={
          <IconButton onClick={() => setOpenDetailView({ open: true, log })}>
            {MaterialIcons.LogIcon}
          </IconButton>
        }
      />
    </Grid>
  );
};

export default AuditLogRow;
