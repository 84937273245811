import axios, { AxiosInstance } from 'axios';

import toast from 'react-hot-toast';
import { getUserCookie } from '../../../utilities/Api';
import { logOutUser } from '../../../utilities/App';
import { ApiConfigV2 } from '../../apiConfigV2';

const instance = axios.create({
  baseURL: ApiConfigV2.base.admin,
  timeout: 30000,
});

instance.defaults.headers.common.context = 'dashboard';
instance.defaults.headers.common['Content-Type'] =
  'application/json; charset=utf-8';

// Add a request interceptor
instance.interceptors.request.use(
  (request) => {
    if (request.url && !request.url.includes('s3.amazonaws.com')) {
      request.headers.Authorization = getUserCookie();
    }
    // console.log('%c Request: ', 'background: #00f; color: #fff', request);
    return request;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error),
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // console.log('%c Response: ', 'background: #0f0; color: #fff', response);
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        logOutUser();
      } else if (
        error.response.data &&
        error.response.data.errorCode === 1003
      ) {
        toast.error(error.response.data.message);
      }
      console.log(
        '%c Error: ',
        'background: #f00; color: #fff',
        error.response,
      );
    }
    return Promise.reject(error);
  },
);
export { instance as NetworkClient };
export type NetworkInstanceV2 = AxiosInstance;
