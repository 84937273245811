import { Box, Step, StepButton, StepLabel, Stepper } from '@mui/material';
import {
  AlertIcon,
  LawIcon,
  LogIcon,
  OrganizationIcon,
} from '@primer/octicons-react';
import { useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { useMemo, useState } from 'react';
import { OrganisationStatusInList } from '../../../../../../shared/constants/Organisation';
import { CreditPlanRequestPayload } from '../../../../../../shared/types/organisation/OrganisationDetailItem';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton, {
  ArrowButton,
} from '../../../../../widgets/styledButton/StyledButton';
import { addClientFormValidationSchema } from '../../../utils/schemas/ValidateAddClientForm.schema';
import { useCreateOrganisation } from '../hooks/useCreateOrganisation';
import ChooseBillingPlan from './ChooseBillingPlan';
import OrgDetailsStep from './OrgDetailsStep';
import OtherBillingInfo from './OtherBillingInfo';

const steps = [
  {
    name: 'orgDetailStep',
    label: 'Add basic details',
    icon: <OrganizationIcon size={18} />,
  },
  {
    name: 'billingStep',
    label: 'Choose billing plan',

    icon: <LawIcon size={18} />,
  },
  {
    name: 'otherBillingInfo',
    label: 'Other billing info',

    icon: <LogIcon size={18} />,
  },
];

export interface IAddClientInputs {
  name: string;
  email: string;
  contactNumber: string;
  status: keyof Omit<typeof OrganisationStatusInList, '0'> | undefined;
  domains: string;
  defaultTeamStatus: string;
  offBoardingStrategy: number | undefined;
  defaultCountryStatus: number | undefined;
  totalHeadCount: number | undefined;
  contractValue: string;
  contractStartDate: string;
  selectedPlans: CreditPlanRequestPayload[];
}

const AddOrganisationDialogBase = ({
  open,
  getOrgsList,
  handleClose,
}: {
  open: boolean;
  getOrgsList: () => void;
  handleClose: () => void;
}) => {
  const { mutate, isLoading } = useCreateOrganisation(() => {
    getOrgsList();
    handleClose();
  });

  const formik = useFormik<IAddClientInputs>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      contactNumber: '',
      status: undefined,
      domains: '',
      defaultTeamStatus: '',
      offBoardingStrategy: undefined,
      defaultCountryStatus: undefined,
      totalHeadCount: undefined,
      contractValue: '',
      contractStartDate: '',
      selectedPlans: [],
    },
    validationSchema: addClientFormValidationSchema,
    onSubmit: async () => {
      const { selectedPlans, ...orgData } = formik?.values;

      mutate({
        orgData: { ...orgData, domains: orgData?.domains?.split(',') ?? [] },
        creditRequestPayload: selectedPlans,
      });
      formik.resetForm();
    },
  });

  const [[activeStep, direction], setPage] = useState([0, 0]);
  const paginate = (newDirection: number) => {
    setPage([activeStep + newDirection, newDirection]);
  };

  const handleSubmittedPlansChange = (
    newSubmittedPlans: CreditPlanRequestPayload[],
  ) => {
    formik?.setFieldValue('selectedPlans', newSubmittedPlans);
  };

  const errorInForm: Record<string, boolean> = useMemo(() => {
    const {
      errors: { name, email, contactNumber, domains, status, totalHeadCount },
    } = formik ?? {};

    return {
      '0': Boolean(name && email && contactNumber && domains),
      '1': Boolean(status),
      '2': Boolean(totalHeadCount),
    };
  }, [formik?.errors]);

  const handleDialogClose = () => {
    formik.resetForm();
    setPage([0, 0]);
    handleClose();
  };

  return (
    <GenericDialog
      open={open}
      handleClose={handleDialogClose}
      noFullScreen
      title="Add a client"
      extraElements={
        <>
          {activeStep !== 0 && (
            <ArrowButton
              direction="left"
              variant="ghost"
              sx={{ width: '8rem' }}
              onClick={() => paginate(-1)}
            >
              Previous
            </ArrowButton>
          )}
          {activeStep === steps.length - 1 ? (
            <StyledButton
              sx={{ width: '8rem' }}
              size="small"
              type="submit"
              onClick={formik.handleSubmit}
              disabled={isLoading}
            >
              Submit
            </StyledButton>
          ) : (
            <ArrowButton
              size="small"
              variant="ghost"
              sx={{ width: '8rem' }}
              onClick={() => paginate(1)}
            >
              Next
            </ArrowButton>
          )}
        </>
      }
    >
      <Box padding={3}>
        <Stepper nonLinear activeStep={activeStep} alternativeLabel>
          {steps.map(({ label, icon }, index) => (
            <Step key={label}>
              <StepButton
                icon={errorInForm[`${index}`] ? <AlertIcon fill="red" /> : icon}
                sx={{
                  '& .Mui-active.MuiStepLabel-label': { fontWeight: 'bold' },
                  '& .Mui-active svg': { color: 'black' },
                  '.MuiStepLabel-label': { fontWeight: 'light' },
                  svg: { color: 'gainsboro' },
                  cursor: 'default',
                }}
              >
                <StepLabel error={errorInForm[`${index}`]}>{label}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Box
          sx={{
            overflowX: 'hidden',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            height: '60vh',
            width: '100%',
            mt: 3,
          }}
        >
          <AnimatePresence initial={false} custom={direction}>
            {activeStep === 0 && (
              <OrgDetailsStep key="orgStep" formik={formik} />
            )}
            {activeStep === 1 && (
              <ChooseBillingPlan
                key="billingStep"
                onSubmittedPlansChange={handleSubmittedPlansChange}
                formik={formik}
              />
            )}
            {activeStep === 2 && (
              <OtherBillingInfo key="otherBillingInfo" formik={formik} />
            )}
          </AnimatePresence>
        </Box>
      </Box>
    </GenericDialog>
  );
};

export default AddOrganisationDialogBase;
