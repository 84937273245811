import { isProduction, isProductionJapan } from '../utilities/App';
import { EnvType } from './constants/App';

const EnvironmentVariables: any = {
  [EnvType.Localhost]: {
    baseUrl: 'http://localhost:5005/api/',
    talkyUrl: 'http://localhost:5001/api/',
    contentUrl: 'http://localhost:5000/api/',
    authUrl: 'http://localhost:5003/api/',
    providerTeamId: 4,
  },
  [EnvType.Staging]: {
    baseUrl: 'https://stage.internal.intellect.co/api/',
    talkyUrl: 'https://stage.internal.intellect.co/api/',
    contentUrl: 'https://stage.internal.intellect.co/api/',
    authUrl: 'https://stage.internal.intellect.co/api/',
    providerTeamId: 4,
  },
  [EnvType.Production]: {
    baseUrl: 'https://internal.intellect.co/api/',
    talkyUrl: 'https://internal.intellect.co/api/',
    contentUrl: 'https://internal.intellect.co/api/',
    authUrl: 'https://internal.intellect.co/api/',
    providerTeamId: 89,
  },
  [EnvType.ProductionJp]: {
    baseUrl: 'https://jp-api.internal.intellect.co/api/chief',
    talkyUrl: 'https://jp-api.internal.intellect.co/api/chief',
    contentUrl: 'https://jp-api.internal.intellect.co/api/chief',
    authUrl: 'https://jp-api.internal.intellect.co/api',
    providerTeamId: 89,
    insightsDashboard: 'https://wmd.internal.intellect.co',
  },
};

const Environment =
  EnvironmentVariables[
    isProduction()
      ? EnvType.Production
      : isProductionJapan()
      ? EnvType.ProductionJp
      : EnvType.Staging
  ];

const ApiConfig = {
  base: {
    admin: Environment.baseUrl,
    talky: Environment.talkyUrl,
    content: Environment.contentUrl,
  },
  api: {
    auth: {
      me: `${Environment.authUrl}user/auth/me`,
      login: `${Environment.authUrl}user/auth/login`,
      deleteRequestList: `${Environment.authUrl}user/auth/delete/list`,
      deleteRequestConfirm: `${Environment.authUrl}user/auth/delete/confirm`,
      getSsoUrl: `${Environment.authUrl}user/sso/verify/email`,
    },
    config: 'dashboard/config',
    course: {
      list: 'dashboard/course/',
      detail: `${Environment.contentUrl}app/course/{{courseId}}?version=2`,
      add: 'dashboard/course/',
      update: 'dashboard/course/{{courseId}}',
      session: {
        add: 'dashboard/course/session/',
        details: 'dashboard/course/session/',
        update: 'dashboard/course/session/{{id}}',
        open: `${Environment.contentUrl}app/course/session/open/`,
      },
      page: {
        add: 'dashboard/course/session/module/group/page/',
        update: 'dashboard/course/session/module/group/page/{{id}}',
        content: 'dashboard/course/session/module/page/content/',
        order: 'dashboard/course/session/module/group/page/order/',
      },
      module: {
        add: 'dashboard/course/session/{sessionId}/module',
        update: 'dashboard/course/session/module/{{id}}',
      },
      questionnaire: {
        detail: 'dashboard/questionnaire/{{id}}',
        addQuestion: 'dashboard/questionnaire/{{id}}/questions',
        updateQuestion: 'dashboard/questionnaire/question',
        optionGroups: 'dashboard/questionnaire/optionGroup',
        score: 'dashboard/questionnaire/question/score',
        scoring: {
          data: 'dashboard/questionnaire/scoring/',
          personality: 'dashboard/questionnaire/scoring/personality',
          trait: 'dashboard/questionnaire/scoring/trait',
        },
      },
      spellCheck: 'dashboard/course/session/spellCheck',
      expectations: {
        add: 'dashboard/course/{{id}}/expectation',
        delete: 'dashboard/course/expectation/{{id}}',
      },
      grouping: {
        add: 'dashboard/course/{{id}}/group/',
        remove: 'dashboard/course/group/',
      },
    },
    reward: {
      add: 'dashboard/rewards/',
      list: 'dashboard/rewards/',
      detail: 'dashboard/rewards/{{id}}',
      update: 'dashboard/rewards/{{id}}',
      delete: 'dashboard/rewards/{{id}}',
    },
    marketing: {
      signupList: 'dashboard/marketing/signup/list',
      campaigns: 'dashboard/marketing/campaign',
      codes: 'dashboard/marketing/code',
    },
    provider: {
      list: 'provider/dashboard',
      update: 'provider/dashboard/{{providerId}}',
      sessions: 'provider/dashboard/{{providerId}}/sessions',
      updateSlots: 'provider/dashboard/{{providerId}}/slots',
      getSlots: 'provider/dashboard/slots/{{providerId}}',
      attributes: 'provider/dashboard/attributes/{{providerId}}',
      languages: 'provider/dashboard/languages/{{providerId}}',
      saveRanking: 'provider/dashboard/{{providerId}}/rank',
      getRanking: 'provider/dashboard/expertise/{{providerId}}',
      meeting: {
        meetingSlots: `${Environment.talkyUrl}meeting/admin/slots`,
        reschedule: `${Environment.talkyUrl}meeting/admin/{{meetingId}}/reschedule`,
        cancel: `${Environment.talkyUrl}meeting/admin/{{meetingId}}/cancel`,
      },
      holiday: {
        list: 'provider/dashboard/{{providerId}}/holiday',
        delete: 'provider/dashboard/{{providerId}}/holiday/{{id}}',
      },
      client: {
        list: 'provider/dashboard/{{providerId}}/client',
        delete: 'provider/dashboard/{{providerId}}/client/{{userId}}',
      },
      coach: {
        manualAssign: 'provider/dashboard/{{providerId}}/client/{{userId}}',
      },
      profile: 'provider/dashboard/profile',
    },
    content: {
      module: {
        map: 'dashboard/content/session/{{sessionId}}/module/map',
      },
      session: {
        translate: {
          getItem: 'dashboard/content/session/translation/',
          status: 'dashboard/content/session/translate/status',
          import: 'dashboard/content/session/translate/import',
          export: 'dashboard/content/session/translate/export',
        },
        clone: 'dashboard/content/session/clone',
        checkin: {
          add: 'dashboard/content/session/{{sessionId}}/checkin/',
          delete: 'dashboard/content/session/checkin/{{checkin}}',
        },
      },
    },
    billing: {
      plan: 'dashboard/billing/plan/',
      associatedPool: 'dashboard/billing/plan/:id/associatedPool',
      user: {
        credit: {
          request: 'dashboard/billing/user/credit/request',
          refund: 'app/billing/credit/refund/{{userId}}',
        },
      },
    },
  },
  values: {
    providerTeamId: Environment.providerTeamId,
  },
};

export { ApiConfig };
