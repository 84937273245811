import { IconButton, Tooltip } from '@mui/material';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { InfoIcon } from '@primer/octicons-react';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';

export const RenderPlansGridColumns: (payload: {
  handleOpenPopover: (event: any) => void;
}) => GridColDef[] = ({ handleOpenPopover }) => [
  {
    flex: 1,
    minWidth: 60,
    field: 'planName',
    headerName: 'Name',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.planName ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 50,
    field: 'planType',
    headerName: 'Type',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.planType ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 60,
    field: 'planUtilisation',
    headerName: 'Utilisation',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          <TooltipTypography title={params.row?.planUtilisation ?? 'N/A'} />
          <Tooltip
            title={'Click for a monthly break down'}
            onClick={handleOpenPopover}
            data-pool-id={params?.row?.id}
          >
            <IconButton>
              <InfoIcon size={16} />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  },
  {
    flex: 1,
    minWidth: 80,
    field: 'restrictions',
    headerName: 'Restrictions',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.planRestrictions} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'planNotes',
    headerName: 'Notes',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.planNotes} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'planValidFrom',
    headerName: 'Valid From',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.planValidFrom} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'planValidUntil',
    headerName: 'Valid Until',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.planValidUntil} />;
    },
  },
];
