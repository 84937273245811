import React from 'react';
import toast from 'react-hot-toast';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import Config from '../../../config';
import { getUserCookie } from '../../../utilities/Api';

export const AuthenticatedRoute = ({
  component: Component,
  path,
}: RouteProps) => {
  const isLoggedIn = !!getUserCookie();

  const prevLocation = useLocation();
  if (!isLoggedIn) {
    toast.error('Your session has timed out. Please sign in again.');
    return (
      <Redirect
        to={`${Config.paths.login}?redirectTo=${prevLocation.pathname}`}
      />
    );
  }

  return <Route component={Component} path={path} />;
};
