import { useCallback, useState } from 'react';
import { OrganisationTeamMemberItem } from '../../../../../../shared/types/organisation/OrganisationTeamMemberItem';

import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import EditMemberDialog from './EditMembersDialog';
import MemberTableRevised from './MemberTableRevised';

const MemberTableViewRevised = ({
  refreshData,
  members,
  teams,
  orgId,
  teamId,
  csRep = false,
}: {
  refreshData: () => void;
  members: OrganisationTeamMemberItem[];
  teams: OrganisationTeamItem[];
  orgId: number;
  teamId?: number; // denotes whether it's a particular team view or global members view (all members)
  csRep?: boolean; // it denotes whether current user is the representative of this org or not, on which some fields are shown
}) => {
  const [showEditMemberDialog, setShowEditMemberDialog] =
    useState<boolean>(false);
  const [memberToBeEdited, setMemberToBeEdited] =
    useState<OrganisationTeamMemberItem | null>(null);

  const handleEditRow = useCallback(
    (index: number) => {
      const findMember = members?.find((item) => item?.id === index) ?? null;
      if (findMember) {
        setMemberToBeEdited({
          ...findMember,
        });
        setShowEditMemberDialog(true);
      }
    },
    [members],
  );

  const handleEditMemberDialogClose = () => {
    setMemberToBeEdited(null);
    setShowEditMemberDialog(false);
  };

  return (
    <>
      <MemberTableRevised
        orgId={orgId}
        refreshMembers={refreshData}
        handleEditRow={handleEditRow}
        data={members}
        teams={teams}
        teamId={teamId}
        csRep={csRep}
      />

      <EditMemberDialog
        member={memberToBeEdited}
        teams={teams}
        open={showEditMemberDialog}
        handleClose={handleEditMemberDialogClose}
        refreshData={refreshData}
        orgId={orgId}
        csRep={csRep}
      />
    </>
  );
};

export default MemberTableViewRevised;
