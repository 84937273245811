import { Box, IconButton, Link, Tooltip } from '@mui/material';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { InfoIcon } from '@primer/octicons-react';
import moment from 'moment';
import {
  OrganisationStatusInList,
  PilotMaxDays,
  TrialMaxDays,
} from '../../../../../shared/constants/Organisation';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';
import StatusChip from '../../../../components/StatusChip/StatusChip';
import {
  GenerateActivePlanSummary,
  SortActivePlansByExpiryDate,
} from '../../../../../utilities/Common';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';
import Config from '../../../../../config';

export const ClientsGridColumnsV3: (payload: {
  onAnalyticsClick: (id: number) => void;
}) => GridColDef[] = ({ onAnalyticsClick }) => [
  {
    flex: 0.5,
    minWidth: 60,
    field: 'id',
    headerName: 'ID',

    renderCell: (params: GridRenderCellParams) => {
      const diffDays = Math.abs(
        moment(params?.row?.createdAt).diff(moment(), 'days'),
      );
      const status: keyof typeof OrganisationStatusInList =
        params.row?.status ?? 0;

      const pilotDaysExceeded =
        OrganisationStatusInList[status].title === 'Pilot' &&
        diffDays > PilotMaxDays;
      const trialDaysExceeded =
        OrganisationStatusInList[status].title === 'Trial' &&
        diffDays > TrialMaxDays;

      return (
        <>
          <TooltipTypography title={params.row?.id ?? 'N/A'} />
          {(pilotDaysExceeded || trialDaysExceeded) && (
            <Tooltip
              color="error"
              title={
                'Warning: ' +
                (pilotDaysExceeded
                  ? `${PilotMaxDays} days exceeded as Pilot`
                  : `${TrialMaxDays} days exceeded as Trial`)
              }
            >
              <IconButton>
                <InfoIcon size={18} />
              </IconButton>
            </Tooltip>
          )}
        </>
      );
    },
  },
  {
    flex: 0.75,
    minWidth: 60,
    field: 'name',
    headerName: 'Name',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          <Link
            sx={{ color: 'black' }}
            href={`${Config.paths.organisationV3}/${params?.row?.id}`}
            className="txt-overflow"
          >
            <TooltipTypography title={params.row?.name ?? 'N/A'} />
          </Link>
        </>
      );
    },
  },
  {
    flex: 0.5,
    minWidth: 60,
    field: 'validity',
    headerName: 'Validity',
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const data =
        SortActivePlansByExpiryDate(params?.row?.credits ?? []) ?? [];
      return (
        <TooltipTypography
          title={
            data?.[0]?.expiresAt
              ? moment(data?.[0]?.expiresAt).format('MMM DD, YYYY')
              : '-'
          }
        />
      );
    },
  },
  {
    flex: 0.5,
    minWidth: 50,
    field: 'status',
    headerName: 'Status',
    renderCell: (params: GridRenderCellParams) => {
      const status: keyof typeof OrganisationStatusInList =
        params.row?.status ?? 0;

      return (
        <StatusChip
          bgColor={OrganisationStatusInList[status].bgColor}
          color={OrganisationStatusInList[status].color}
          title={OrganisationStatusInList[status]?.title ?? 'N/A'}
        />
      );
    },
  },
  {
    flex: 2,
    minWidth: 120,
    field: 'activePlans',
    headerName: 'Active Plans',
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const data = GenerateActivePlanSummary(params?.row?.credits ?? []);
      return (
        <TooltipTypography title={data?.length ? data?.join(', ') : '-'} />
      );
    },
  },
  {
    flex: 0.5,
    minWidth: 50,
    field: 'actions',
    headerName: 'Actions',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Box>
          <Tooltip title="Overview">
            <IconButton
              LinkComponent={Link}
              href={`${Config.paths.organisationV3}/${params?.row?.id}`}
            >
              {MaterialIcons.FileIcon}
            </IconButton>
          </Tooltip>
          <Tooltip title="Management">
            <IconButton
              LinkComponent={Link}
              href={`${Config.paths.organisationV3}/${params?.row?.id}/#management`}
            >
              {MaterialIcons.GearIcon}
            </IconButton>
          </Tooltip>
          <Tooltip title="Analytics">
            <IconButton onClick={() => onAnalyticsClick(params?.row?.id)}>
              {MaterialIcons.Leads}
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
  },
];
