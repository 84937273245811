import { Box, Typography } from '@mui/material';
import React from 'react';

interface ISectionHeaderProps {
  title: string | JSX.Element;
  titleChip?: JSX.Element;
  subTitle?: string | JSX.Element;
  leftIcon?: JSX.Element;
  rightElement?: JSX.Element;
  titleStyles?: React.CSSProperties;
  sx?: React.CSSProperties;
}

/** Common Section Header Component */
const SectionHeader = ({
  title,
  titleChip,
  subTitle,
  leftIcon,
  rightElement,
  titleStyles,
  sx,
}: ISectionHeaderProps) => {
  return (
    <Box
      className="sectionHeader"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap={'wrap'}
      gap={'10px'}
      sx={{
        ...sx,
      }}
    >
      <Box display="flex" gap="10px" alignItems="center">
        {leftIcon}
        <Box>
          <Box display="flex" gap="8px" alignItems="center">
            <Typography
              color="textPrimary"
              variant="h4"
              fontWeight="bold"
              sx={{ ...titleStyles }}
            >
              {title}
            </Typography>
            {titleChip}
          </Box>
          <Typography variant="subtitle2">{subTitle}</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row-reverse"
        gap={1}
      >
        {rightElement}
      </Box>
    </Box>
  );
};

export default SectionHeader;
