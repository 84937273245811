import { Typography } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import { SsoDomain } from '../../../../../../shared/types/organisation/OrganisationDetailItem';
import SectionHeader from '../../../../../components/SectionHeader/SectionHeader';
import StatusChip from '../../../../../components/StatusChip/StatusChip';
import TableViewComponent from '../../../../../components/TableViewComponent/TableViewComponent';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { ClientsDomainConfigsColumns } from '../../../utils/grid-columns/ClientDomainConfigsColumns';
import { useGetOrgDomainEntityConfigs } from '../hooks/useGetOrgDomainEntityConfigs';
import { OrganisationoDetailContext } from '../OrganisationDetailView';
import AddDomainDialog from './AddDomainDialog';

const OrganisationDomainMappings = () => {
  const { orgData, domainConfigs, getOrgDomains } = useContext(
    OrganisationoDetailContext,
  );

  const { data: orgSsoEntityConfigs } = useGetOrgDomainEntityConfigs(
    orgData?.id,
  );

  const [pageSize, setPageSize] = useState<number>(7);
  const [page, setPage] = useState<number>(0);
  const [openDomainDialog, setOpenDomainDialog] = useState<{
    open: boolean;
    edit: boolean;
    data?: SsoDomain;
  }>({ open: false, edit: false });

  const isSSOEnabled = useMemo(() => {
    return domainConfigs?.some((item) => item?.isActive);
  }, [domainConfigs]);

  const handleClose = () => {
    setOpenDomainDialog({ open: false, edit: false, data: undefined });
  };

  const handleSuccess = () => {
    handleClose();
    getOrgDomains();
  };

  return (
    <>
      <SectionHeader
        title={
          <Typography
            color="textPrimary"
            variant="h6"
            fontWeight="bold"
            margin={0}
          >
            SSO Integration
          </Typography>
        }
        titleChip={
          <>
            <StatusChip
              bgColor={
                isSSOEnabled
                  ? 'var(--chip-green-bg-color)'
                  : 'var(--chip-red-bg-color)'
              }
              color={
                isSSOEnabled
                  ? 'var(--chip-green-color)'
                  : 'var(--chip-red-color)'
              }
              title={isSSOEnabled ? 'Enabled' : 'Disabled'}
            />
          </>
        }
        rightElement={
          <>
            <StyledButton
              variant="ghost"
              startIcon={MaterialIcons.DomainAddIcon}
              disabled={!domainConfigs?.[0]?.id}
              onClick={() =>
                setOpenDomainDialog({
                  open: true,
                  edit: false,
                  data: undefined,
                })
              }
            >
              Add Domain
            </StyledButton>
          </>
        }
      />
      <TableViewComponent
        rows={domainConfigs ?? []}
        pageSizeCustom={pageSize}
        currentPage={page}
        getRowId={(row: any) => row?.id}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        onPageChange={(page) => {
          setPage(page);
        }}
        columns={ClientsDomainConfigsColumns({
          setOpenDomainDialog,
        })}
      />

      <AddDomainDialog
        open={openDomainDialog?.open}
        edit={openDomainDialog?.edit}
        existingDomain={openDomainDialog?.data}
        handleClose={handleClose}
        handleSuccess={handleSuccess}
        orgSsoEntityConfigs={orgSsoEntityConfigs}
      />
    </>
  );
};

export default OrganisationDomainMappings;
