import {
  Autocomplete,
  Grid,
  InputLabel,
  Paper,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { MAX_LIMIT_FOR_BLK_MEMS_UPDATE } from './MemberTableRevised';

const EditBulkMembersTeamDialog = ({
  open,
  handleClose,
  refreshData,
  teams,
  currentTeamId,
  memberIds,
  orgId,
}: {
  open: boolean;
  handleClose: () => void;
  refreshData: () => void;
  teams: OrganisationTeamItem[];
  currentTeamId?: number;
  memberIds: number[];
  orgId: number;
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [teamsData, setTeamsData] = useState<OrganisationTeamItem[]>([]);

  useEffect(() => {
    if (teams?.length) {
      const tempTeams = teams.reduce(
        (acc: OrganisationTeamItem[], item: OrganisationTeamItem) => {
          acc.push(item);
          if (item.childTeams?.length) {
            acc.push(...item.childTeams);
          }
          return acc;
        },
        [],
      );
      setTeamsData(tempTeams);
    }
  }, [teams]);

  const [selectedNewTeam, setSelectedNewTeam] =
    useState<OrganisationTeamItem | null>(null);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const currentTeam = useMemo(() => {
    return teams?.find((item) => item?.id === currentTeamId);
  }, [teams, currentTeamId]);

  const onClose = () => {
    setShowConfirmation(false);
    setSelectedNewTeam(null);
    handleClose();
  };

  const handleTeamUpdate = async () => {
    try {
      if (
        !selectedNewTeam ||
        !currentTeamId ||
        memberIds?.length > MAX_LIMIT_FOR_BLK_MEMS_UPDATE
      )
        return;

      setConfirmLoading(true);

      console.log('Payload:', {
        orgId,
        currentTeamId,
        newTeamId: selectedNewTeam?.id,
        memberIds,
      });
      const response =
        await OrganisationServiceV2.updateMultipleMembersTeamById({
          orgId,
          currentTeamId,
          newTeamId: selectedNewTeam?.id,
          memberIds,
        });

      if (response && response.data.success) {
        toast.success('Removed the selected members successfully!');
        refreshData();
        onClose();
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'An error occurred while trying to change team of the selected members. Please try again.',
      );
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <GenericDialog
      noFullScreen
      handleClose={onClose}
      open={open}
      title="Change Team"
      extraElements={
        <>
          {!showConfirmation ? (
            <StyledButton
              sx={{ width: '8rem' }}
              size="large"
              onClick={() => setShowConfirmation(true)}
              disabled={!selectedNewTeam}
            >
              Save
            </StyledButton>
          ) : (
            <></>
          )}
        </>
      }
    >
      {memberIds?.length > MAX_LIMIT_FOR_BLK_MEMS_UPDATE ? (
        <Typography variant="h6">
          {`Only ${MAX_LIMIT_FOR_BLK_MEMS_UPDATE} members are allowed to be updated
        at a single time.`}
        </Typography>
      ) : (
        <>
          {!showConfirmation ? (
            <Grid container spacing={4}>
              <Grid item xs={6} width={6}>
                <InputLabel>Current Team</InputLabel>
                <TextField
                  value={currentTeam?.name}
                  disabled
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} width={6}>
                <InputLabel>New Team</InputLabel>
                <Autocomplete
                  fullWidth={true}
                  value={selectedNewTeam}
                  options={teamsData?.filter(
                    (item) => item?.id !== currentTeam?.id,
                  )}
                  getOptionLabel={(option) => option?.name}
                  onChange={(event, newValue) => {
                    setSelectedNewTeam(newValue);
                  }}
                  size="small"
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <Slide
            direction="up"
            in={showConfirmation}
            mountOnEnter
            unmountOnExit
          >
            <Paper
              variant="elevation"
              elevation={0}
              sx={{ margin: 0, padding: 0 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} width={12}>
                  <Typography variant="h6">
                    Are you sure you want to change team of {memberIds.length}{' '}
                    member{memberIds?.length !== 1 ? 's' : ''} from{' '}
                    <b>
                      <i>{currentTeam?.name}</i>
                    </b>{' '}
                    to{' '}
                    <b>
                      <i>{selectedNewTeam?.name}</i>
                    </b>
                    ?
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  width={12}
                  display={'flex'}
                  justifyContent={'flex-end'}
                >
                  <StyledButton
                    variant="ghost"
                    size="large"
                    onClick={() => setShowConfirmation(false)}
                    color="red"
                    padding="0.5rem"
                    sx={{ width: '7.5rem' }}
                    autoFocus
                  >
                    {'Cancel'}
                  </StyledButton>
                  <StyledButton
                    size="large"
                    padding="0.5rem"
                    sx={{ width: '7.5rem' }}
                    disabled={confirmLoading || !selectedNewTeam}
                    onClick={handleTeamUpdate}
                  >
                    {'Yes'}
                  </StyledButton>
                </Grid>
              </Grid>
            </Paper>
          </Slide>
        </>
      )}
    </GenericDialog>
  );
};

export default EditBulkMembersTeamDialog;
