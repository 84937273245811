import { ApiConfigV2 } from '../../apiConfigV2';
import { CreditRequest } from '../../types/organisation/Credit';
import { BillingPlan } from '../../types/organisation/OrganisationDetailItem';
import { NetworkInstanceV2 } from './NetworkInstance';

export class BillingV2 {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getPlans = async (): Promise<BillingPlan[]> =>
    this.client
      .get(ApiConfigV2.api.billing.savePlan)
      .then((response) => response.data.data);

  savePlan = async (params: Partial<BillingPlan>) => {
    const { createdAt, updatedAt, ...plan } = params;

    if (plan.id) {
      return this.client.put(ApiConfigV2.api.billing.savePlan + plan.id, plan);
    }
    return this.client.post(ApiConfigV2.api.billing.savePlan, plan);
  };

  getCreditRequests = (): Promise<CreditRequest[]> =>
    this.client
      .get(ApiConfigV2.api.billing.user.credit.request)
      .then((response) => response.data.data);

  updateCreditRequest = async (requestId: number, status: string) =>
    this.client.put(
      `${ApiConfigV2.api.billing.user.credit.request}/${requestId}`,
      {
        status,
      },
    );

  refundCredits = async (userId: string) =>
    this.client.put(
      ApiConfigV2.api.billing.user.credit.refund.replace('{{userId}}', userId),
    );

  userInfo = async (userId: number, email: string) => {
    let param: string;
    if (userId) {
      param = `userId=${userId}`;
    } else if (email) {
      param = `email=${email}`;
    } else {
      return null;
    }
    return this.client.get(`${ApiConfigV2.api.user.info}?${param}`);
  };

  fetchUserCreditHistory = async (userId: number) => {
    return this.client.get(
      ApiConfigV2.api.billing.user.history.replace(
        '{{userId}}',
        userId.toString(),
      ),
    );
  };

  resetUserTotpMfa = async (userId: number) => {
    return this.client.put(ApiConfigV2.api.user.resetTotp, { userId });
  };

  forceLogOutUser = async (userId: number) => {
    return this.client.put(ApiConfigV2.api.user.forceLogOut, { userId });
  };
}
