import { IconButton, Tooltip } from '@mui/material';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { InfoIcon } from '@primer/octicons-react';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';

export const AccessCodeUsersGridColumns: GridColDef[] = [
  {
    flex: 1,
    minWidth: 60,
    field: 'id',
    headerName: 'UserId',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.id ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 50,
    field: 'inviteCode',
    headerName: 'InviteCode',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.inviteCode ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 50,
    field: 'email',
    headerName: 'Email',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.email ?? 'N/A'} />;
    },
  },
];
