import moment from 'moment';

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getDisplayDate = (input: string) =>
  moment(input).format('DD MMM YY');

export const getDisplayDateTime = (input: string) =>
  moment(input).format('DD MMM YY hh:MM:ss A');

export const convertUnixEpochToDateTime = (input: number) =>
  moment.unix(input).format('DD MMM YY hh:MM:ss A');

/**
 * Shortens string and Appends ... at the end
 * @param value
 * @param length
 * @returns {string}
 */
export const shortenLongerStringValue = (value: string, length: number) => {
  if (!value) return '';

  return value?.length > length ? value?.substring(0, length) + '...' : value;
};
