import moment from 'moment';
import { CreditPoolTypes } from '../shared/constants/Organisation';
import { CreditPool } from '../shared/types/organisation/OrganisationDetailItem';
import { OrganisationTeamItem } from '../shared/types/organisation/OrganisationTeamItem';
import _ from 'lodash';

// This is used to check one string contains another string or not
export const ItemContains = (
  dataItem: string | number,
  filterItem: string | number,
) => {
  return dataItem
    ?.toString()
    ?.trim()
    ?.toLowerCase()
    ?.includes(filterItem?.toString()?.trim()?.toLowerCase());
};

/**
 * Common function to give plan summary in an array
 * @param credits
 * @returns {string[]}
 */
export const GenerateActivePlanSummary = (credits: CreditPool[]) => {
  const summary = credits
    ?.sort(
      (a, b) =>
        new Date(a?.expiresAt).getTime() - new Date(b?.expiresAt).getTime(),
    )
    ?.sort((a, b) => +a.type - +b.type)
    ?.filter((pool) => {
      const diffDays = moment(pool.expiresAt).diff(moment(), 'days');
      return diffDays > 0;
    })
    ?.map((pool) => {
      const heading =
        CreditPoolTypes.find((item) => item.type === pool.type)?.label || '';
      let type = '';
      let globalCredits = '';
      let userCredits = '';

      if (pool.credits === null && pool.creditPerUser === null)
        type = 'Unlimited';
      else if (pool.credits === null && pool.creditPerUser !== null) {
        userCredits = pool?.creditPerUser ? `${pool?.creditPerUser}x` : '';
        type = 'User';
      } else if (pool.credits !== null && pool.creditPerUser !== null) {
        globalCredits = pool?.credits ? `${pool?.credits}` : '';
        userCredits = pool?.creditPerUser ? `${pool?.creditPerUser}x` : '';
        type = 'Hybrid';
      } else if (pool.credits !== null && pool.creditPerUser === null) {
        globalCredits = pool?.credits ? `${pool?.credits}` : '';
        type = 'Pool';
      }

      return `${heading} (${type}${globalCredits ? `/${globalCredits}` : ''}${
        userCredits ? `/${userCredits}` : ''
      })`;
    });

  return summary;
};

/**
 * To sort active plans by expiry dates
 * @param credits
 * @returns {CreditPool[]}
 */
export const SortActivePlansByExpiryDate = (credits: CreditPool[]) => {
  const summary = credits
    ?.filter((pool) => {
      const diffDays = moment(pool.expiresAt).diff(moment(), 'days');
      return diffDays > 0;
    })
    ?.sort(
      (a, b) =>
        new Date(a?.expiresAt).getTime() - new Date(b?.expiresAt).getTime(),
    );

  return summary;
};

/**
 * Function to cound child teams count
 * @param team
 * @returns
 */
export const getChildTeamCountSum = (team: OrganisationTeamItem) => {
  let childCountSum = 0;

  if (team.childTeams && team.childTeams.length > 0) {
    for (const child of team.childTeams) {
      childCountSum += child.memberCount; // Add the count of the current child team
      childCountSum += getChildTeamCountSum(child); // Add the counts of further nested child teams
    }
  }

  return childCountSum;
};

// Domain Validation
export const validateDomain = (url: string) => {
  const urlRegex =
    /^(((http|https):\/\/|)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;
  if (urlRegex.test(url)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Finds difference between two array of objects
 * @param array1
 * @param array2
 * @param key
 * @returns
 */
export const GetArrayObjectDifference = (
  array1: Record<string, any>[],
  array2: Record<string, any>[],
  key = 'id',
) => {
  const addedItems = _.differenceWith(
    array1,
    array2,
    (a, b) => a[key] === b[key],
  );
  const removedItems = _.differenceWith(
    array2,
    array1,
    (a, b) => a[key] === b[key],
  );
  const updatedItems = array1.filter((modifiedItem) => {
    const originalItem = array2.find(
      (originalItem) => originalItem[key] === modifiedItem[key],
    );
    return originalItem && !_.isEqual(modifiedItem, originalItem);
  });

  return {
    updated: addedItems?.length || updatedItems?.length || removedItems?.length,
    data: {
      addedItems,
      removedItems,
      updatedItems,
    },
  };
};
/**
 * Finds difference between two arrays [1,2] & [1,2,3] -> removed: [3]
 * @param array1
 * @param array2
 * @returns
 */
export const GetArrayDifference = (array1: any[], array2: any[]) => {
  const addedItems = _.differenceWith(array1, array2, (a, b) => a === b);
  const removedItems = _.differenceWith(array2, array1, (a, b) => a === b);
  return {
    updated: addedItems?.length || removedItems?.length,
    data: {
      addedItems,
      removedItems,
    },
  };
};
