import { Tab, Tabs } from '@mui/material';
import { useContext, useState } from 'react';
import TeamView from './organisationTeams/TeamView';
import { OrganisationoDetailContext } from './OrganisationDetailView';
import MemberTableViewRevised from './organisationMembers/MemberTableViewRevised';
import DomainUsersView from './organisationNonMembers/DomainUsersView';
import AccessCodeUsersView from './organisationNonMembers/AccessCodeUsersView';

const OrganisationManagement = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const { teams, members, getTeamsAndMembers, orgData, getOrgData, csRep } =
    useContext(OrganisationoDetailContext);

  return (
    <>
      <Tabs
        value={selectedTabIndex}
        onChange={(_event, newValue) => {
          setSelectedTabIndex(newValue);
        }}
        aria-label="Course detail tabs"
        sx={{ mb: 3 }}
      >
        <Tab label="Teams" value={0} />
        <Tab label="Members" value={1} />
        <Tab label="Domain Users" value={2} />
        <Tab label="AccessCode Users" value={3} />
      </Tabs>

      {selectedTabIndex === 0 && orgData ? (
        <TeamView
          teams={teams}
          orgData={orgData}
          refreshTeams={getTeamsAndMembers}
          refreshOrg={getOrgData}
        />
      ) : (
        <></>
      )}
      {selectedTabIndex === 1 && orgData ? (
        <MemberTableViewRevised
          members={members}
          refreshData={getTeamsAndMembers}
          teams={teams}
          orgId={orgData?.id}
          csRep={csRep}
        />
      ) : (
        <></>
      )}
      {selectedTabIndex === 2 && orgData ? (
        <DomainUsersView orgId={orgData?.id} />
      ) : (
        <></>
      )}
      {selectedTabIndex === 3 && orgData ? (
        <AccessCodeUsersView orgId={orgData?.id} />
      ) : (
        <></>
      )}
    </>
  );
};

export default OrganisationManagement;
