import { Grid, InputLabel, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';

const AddTeamDialog = ({
  open,
  handleClose,
  handleSubmit,
  parentTeam,
  loading,
}: {
  open: boolean;
  parentTeam?: OrganisationTeamItem | null;
  handleClose: () => void;
  handleSubmit: (name: string) => void;
  loading: boolean;
}) => {
  const [teamName, setTeamName] = useState<string>('');

  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      noFullScreen
      title={`Add ${parentTeam ? 'Child Team' : 'Team'}`}
      extraElements={
        <StyledButton
          sx={{ width: '8rem' }}
          size="small"
          onClick={() => handleSubmit(teamName)}
          disabled={loading}
        >
          Submit
        </StyledButton>
      }
    >
      <Grid container spacing={2}>
        {parentTeam && (
          <Grid item xs={6} width={6}>
            <InputLabel>Parent Team</InputLabel>
            <Typography fontWeight={'bold'} color={'secondary'}>
              {parentTeam?.name}
            </Typography>
          </Grid>
        )}

        <Grid item xs={6} width={6}>
          <InputLabel>{parentTeam ? 'Child ' : ''}Team name</InputLabel>
          <TextField
            fullWidth
            value={teamName}
            onChange={(event) => setTeamName(event.target.value)}
            size="small"
          />
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

export default AddTeamDialog;
