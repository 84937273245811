import { useQuery } from '@tanstack/react-query';
import { ChiefProviderServiceV2 } from '../../../../shared/service/services_v2';

export const useGetProviderList = (
  page: number,
  pageSize: number,
  name: string,
  orgIds: number[],
  countryIds: number[],
  services: string[],
  languages: string[],
  isActive?: boolean,
) =>
  useQuery({
    queryKey: [
      'provider-list',
      name,
      page,
      pageSize,
      orgIds,
      countryIds,
      services,
      languages,
      isActive,
    ],
    queryFn: () =>
      ChiefProviderServiceV2.getAllProviders({
        name,
        page,
        pageSize,
        orgIds,
        countryIds,
        services,
        languages,
        isActive,
      }),
  });

export const useGetProviderListNoPagination = (
  selectedLocations: string[] = [],
) =>
  useQuery({
    queryKey: ['provider-list', selectedLocations],
    queryFn: () =>
      ChiefProviderServiceV2.getAllProviders({
        noPagination: true,
        select: 'provider.timezone,profile.name',
        locationIds: selectedLocations,
      }),
    staleTime: 15 * 60 * 1000, // Data is fresh for 15 minutes
    cacheTime: 20 * 60 * 1000, // Data is kept in cache for 20 minutes
    select: (data) => data?.providers,
  });
