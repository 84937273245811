/* eslint-disable @typescript-eslint/no-empty-function */
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import toast, { Toaster } from 'react-hot-toast';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ToasterConfig } from './config/ToasterConfig';
import baseTheme from './config/Theme';
import { ThemeProvider } from '@mui/material';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { isProduction } from './utilities/App';

// replace console.* for disable log on production
if (isProduction()) {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query?.meta?.errorMessage) {
        toast.error(
          String(query?.meta?.errorMessage) ??
            `Something went wrong: ${(error as AxiosError).message}`,
        );
      }
    },
  }),
});

ReactDOM.render(
  <StrictMode>
    <Toaster position="top-right" gutter={8} toastOptions={ToasterConfig} />
    <ThemeProvider theme={baseTheme}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
