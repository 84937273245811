import { useQuery } from '@tanstack/react-query';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OperationalCountryItem } from '../../../../../../shared/types/organisation/OrganisationDetailItem';

export const useGetOrgOperationalCountries = () =>
  useQuery({
    queryKey: ['org-operational-countries'],
    queryFn: () => OrganisationServiceV2.getOperationalCountriesList(),
    staleTime: 30 * 60 * 1000, // Data is fresh for 30 minutes
    cacheTime: 40 * 60 * 1000, // Data is kept in cache for 40 minutes
    select: (data) =>
      (data?.data?.data as OperationalCountryItem[])?.map(
        (item) => item?.countryName,
      ) ?? [],
    meta: {
      errorMessage:
        'An error occurred while attempting to get org operational countries.',
    },
  });
