import { Grid, InputLabel, TextField } from '@mui/material';

import { useContext } from 'react';

import { OrganisationStatusEnum } from '../../../../../shared/constants/Organisation';
import OrganisationDetailSectionHeader from './OrganisationDetailSectionHeader';
import { OrganisationoDetailContext } from './OrganisationDetailView';

const OrganisationInformation = () => {
  const { isEditing, updatedData, setUpdatedData, orgData } = useContext(
    OrganisationoDetailContext,
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} width={12}>
        <OrganisationDetailSectionHeader
          title="Information"
          editingModule={'ORG_INFORMATION'}
        />
      </Grid>
      <Grid item xs={12} width={12}>
        <InputLabel id="email-address">Client Name</InputLabel>
        <TextField
          onChange={(event) => {
            const { value } = event.target;
            setUpdatedData((prev) => ({
              ...prev,
              name: value,
            }));
          }}
          autoFocus
          fullWidth
          disabled={
            !isEditing.ORG_INFORMATION ||
            (orgData?.status &&
              (+orgData?.status === OrganisationStatusEnum.Closed ||
                +orgData?.status === OrganisationStatusEnum.Active))
          }
          id="name"
          value={updatedData?.name ?? ''}
          placeholder="Add Name"
          size="small"
        />
      </Grid>
      <Grid item xs={12} width={12}>
        <InputLabel id="email-address">Client PIC Email</InputLabel>
        <TextField
          onChange={(event) => {
            const { value } = event.target;
            setUpdatedData((prev) => ({
              ...prev,
              email: value,
            }));
          }}
          autoFocus
          fullWidth
          disabled={!isEditing.ORG_INFORMATION}
          id="email-address"
          value={updatedData?.email ?? ''}
          placeholder="Add Email Address"
          size="small"
        />
      </Grid>
      <Grid item xs={12} width={12}>
        <InputLabel id="contact-number">Client PIC Contact</InputLabel>
        <TextField
          onChange={(event) => {
            const { value } = event.target;
            setUpdatedData((prev) => ({
              ...prev,
              contactNumber: value,
            }));
          }}
          autoFocus
          fullWidth
          disabled={!isEditing.ORG_INFORMATION}
          id="contact-number"
          value={updatedData?.contactNumber ?? ''}
          placeholder="Add contact number"
          size="small"
        />
      </Grid>
      <Grid item xs={12} width={12}>
        <InputLabel id="domains">
          Client Domains {isEditing ? '(separated by commas)' : ''}
        </InputLabel>
        <TextField
          onChange={(event) =>
            setUpdatedData((prev) => ({
              ...prev,
              domains: event.target.value.split(','),
            }))
          }
          fullWidth
          disabled={!isEditing.ORG_INFORMATION}
          id="domains"
          value={updatedData?.domains?.join(',') ?? ''}
          placeholder="Add domains(comma separated)"
          size="small"
        />
      </Grid>
    </Grid>
  );
};

export default OrganisationInformation;
