import { Box, Grid, InputLabel, Typography } from '@mui/material';
import ReactJson from 'react-json-view';
import { TooltipTypography } from '../TooltipTypography/TooltipTypography';
import { GenericDialog } from '../genericDialog';
import { IAuditLog } from './utils/types/audit-log.interface';

interface IAuditLogDetailsModal {
  open: boolean;
  handleClose: () => void;
  log: IAuditLog | null;
}
const AuditLogDetailsModal = ({
  open,
  handleClose,
  log,
}: IAuditLogDetailsModal) => {
  return (
    <>
      <GenericDialog
        noFullScreen
        open={open}
        handleClose={handleClose}
        title={'Log Details'}
      >
        <Grid container spacing={2}>
          <Grid item xs={2} width={2}>
            <InputLabel>User</InputLabel>
            <TooltipTypography
              variant="subtitle2"
              title={log?.userName ?? '-'}
            />
          </Grid>
          <Grid item xs={2} width={2}>
            <InputLabel>User ID</InputLabel>
            <TooltipTypography variant="subtitle2" title={log?.userId ?? '-'} />
          </Grid>
          <Grid item xs={2} width={2}>
            <InputLabel>Ref ID</InputLabel>
            <TooltipTypography variant="subtitle2" title={log?.refId ?? '-'} />
          </Grid>
          <Grid item xs={2} width={2}>
            <InputLabel>Event</InputLabel>
            <Typography variant="subtitle2">{log?.event ?? '-'}</Typography>
          </Grid>
          <Grid item xs={2} width={2}>
            <InputLabel>Operation</InputLabel>
            <Typography variant="subtitle2">{log?.operation ?? '-'}</Typography>
          </Grid>
          <Grid item xs={2} width={2}>
            <InputLabel>Transaction ID</InputLabel>
            <TooltipTypography
              variant="subtitle2"
              title={log?.transactionId ?? '-'}
            />
          </Grid>
          <Grid item xs={12} width={12}>
            <Box
              sx={{
                padding: '1rem 1.25rem',
                margin: '1rem 0',
              }}
            >
              <ReactJson
                src={log?.data ?? {}}
                name="Log"
                displayDataTypes={false}
              />
            </Box>
          </Grid>
        </Grid>
      </GenericDialog>
    </>
  );
};

export default AuditLogDetailsModal;
