import { NetworkInstanceV2 } from './NetworkInstance';
import {
  ContentAttributesV2,
  CourseField,
  Topic,
} from '../../types/course_v2/CourseDetail';
import {
  CourseSessionV2,
  SessionField,
} from '../../types/course_v2/CourseSession';
import {
  OptionGroupItemV2,
  RecommendationV2,
  ScoringDetailScoreV2,
  SessionModulePageV2,
  TraitScoreV2,
} from '../../types/course_v2/CourseModule';
import { ApiConfigV2 } from '../../apiConfigV2';
import { getUserCookie } from '../../../utilities/Api';

export class CourseV2 {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getAllCourses = () => this.client.get(ApiConfigV2.api.course.list);

  addCourse = (
    courseTitle: string,
    description: string,
    shortDescription: string,
    languages: Array<string>,
    searchSummary?: string,
  ) =>
    this.client.post(ApiConfigV2.api.course.add, {
      courseTitle,
      description,
      shortDescription,
      languages,
      searchSummary,
    });

  updateCourse = (body: CourseField) =>
    this.client.post(ApiConfigV2.api.course.update, {
      ...body,
      mainImageUrl: body.mainImage,
    });

  publishCourse = (courseId: string) =>
    this.client.post(
      ApiConfigV2.api.course.publish.replace('{{courseId}}', courseId),
    );

  getCourseDetails = (id: string) =>
    this.client.get(ApiConfigV2.api.course.detail.replace('{{courseId}}', id));

  getCourseTranslations = (id: string) =>
    this.client.get(
      ApiConfigV2.api.course.translations.replace('{{courseId}}', id),
    );

  addOrUpdateTranslation = (data: unknown) =>
    this.client.post(ApiConfigV2.api.course.addOrUpdateTranslation, data);

  bulkUpdateTranslation = (data: {
    targetLanguage: string;
    translations: { translationId: string; data: Record<string, string> }[];
  }) => this.client.post(ApiConfigV2.api.course.bulkUpdateTranslation, data);

  copyTranslationsByBaseLanguage = (
    translationId: string,
    targetId: string,
    type: string,
    targetLang: string | null,
    dryRun: boolean,
  ) =>
    this.client.post(ApiConfigV2.api.course.copyTranslationByBaseLanguage, {
      source_translation_id: translationId,
      target_id: targetId,
      type,
      ...(targetLang !== 'all' && { target_lang: targetLang }),
      dry_run: dryRun,
    });

  getCourseSessions = (id: string) =>
    this.client.get(
      ApiConfigV2.api.course.session.list.replace('{{courseId}}', id),
    );

  getSessionDetails = (id: string) =>
    this.client.get(ApiConfigV2.api.course.session.details + id);

  getSessionTranslations = (id: string) =>
    this.client.get(
      ApiConfigV2.api.course.session.translations.replace('{{sessionId}}', id),
    );

  addSessionGroup = (body: { courseId: string; sessionGroupTitle: string }) =>
    this.client.post(ApiConfigV2.api.course.sessionGroup.add, body);

  deleteSessionGroup = (courseId: string, groupId: string) =>
    this.client.delete(
      ApiConfigV2.api.course.sessionGroup.delete
        .replace('{{courseId}}', courseId)
        .replace('{{groupId}}', groupId),
    );

  addSeparateSession = (body: {
    sessionTitle: string;
    sessionDescription: string;
    sessionCategory: string[];
    sessionId?: string;
    searchSummary?: string;
  }) => this.client.post(ApiConfigV2.api.course.session.add, body);

  addSessionToLP = (body: {
    courseId?: string;
    sessionTitle: string;
    sessionDescription: string;
    sessionGroupId?: string;
    sessionId?: string;
  }) => this.client.post(ApiConfigV2.api.course.session.add, body);

  publishSession = (sessionId: string) =>
    this.client.post(
      ApiConfigV2.api.course.session.publish.replace(
        '{{sessionId}}',
        sessionId,
      ),
    );

  addCheckin = (body: {
    checkinCourseId: string;
    sessionTitle: string;
    sessionDescription: string;
  }) =>
    this.client.post(ApiConfigV2.api.course.checkin.add, {
      ...body,
      uniqueRevisionToken: 'unique-token',
    });

  addCheckinToSession = (
    sessionId: string,
    body: {
      checkinId: string;
      ignoreDailyRestriction: number;
      lockTime: number;
    },
  ) =>
    this.client.post(
      ApiConfigV2.api.course.session.addCheckin.replace(
        '{{sessionId}}',
        sessionId,
      ),
      {
        ...body,
        uniqueRevisionToken: 'unique-token',
      },
    );

  getCheckins = (courseId: string) =>
    this.client.get(
      ApiConfigV2.api.course.checkin.list.replace('{{courseId}}', courseId),
    );

  deleteCheckin = (sessionId: string, checkinId: string) =>
    this.client.delete(
      ApiConfigV2.api.course.checkin.delete
        .replace('{{sessionId}}', sessionId)
        .replace('{{checkinId}}', checkinId),
    );

  // savePageContent = (content: ContentItem[]) => {
  //   return this.client.post(ApiConfigV2.api.course.module.page.content, {
  //     data: content,
  //   });
  // };

  updateSession = (
    sessionBaseData: {
      courseId: string | null;
      sessionGroupId: string | null;
      sessionId: string;
      sessionDescription: string;
    },
    session: Partial<SessionField>,
  ) =>
    this.client.post(ApiConfigV2.api.course.session.update, {
      ...session,
      ...sessionBaseData,
    });

  addModule = (body: {
    sessionId: string;
    sessionModuleTitle: string;
    sessionModuleType: string;
    attributes: ContentAttributesV2;
    sessionModuleId?: string;
  }) => this.client.post(ApiConfigV2.api.course.module.add, body);

  addHtmlModule = (body: {
    sessionId: string;
    sessionModuleTitle: string;
    sessionModuleType: string;
    sessionModuleId?: string;
  }) => this.client.post(ApiConfigV2.api.course.module.add, body);

  updateModuleOrder = (sessionId: string, moduleIds: string[]) =>
    this.client.post(
      ApiConfigV2.api.course.session.updateModuleOrder.replace(
        '{{sessionId}}',
        sessionId,
      ),
      { arrangedOrder: moduleIds },
    );

  updatePageOrder = (sessionModuleId: string, newOrder: string[]) =>
    this.client.patch(
      ApiConfigV2.api.course.module.updatePageOrder.replace(
        '{{moduleId}}',
        sessionModuleId,
      ),
      { page_order: newOrder },
    );

  updatePageContent = (
    sessionModuleId: string,
    pageData: SessionModulePageV2,
  ) => {
    const { content, _id, pageId, attributes, ...rest } = pageData;

    return this.client.post(ApiConfigV2.api.course.module.page.updateContent, {
      sessionModuleId,
      pageContent: content,
      pageId: _id,
      attributes,
      ...rest,
    });
  };

  // updatePageOrder = (order: { id: number; order: number }[]) => {
  //   return this.client.post(ApiConfigV2.api.course.module.page.order, {
  //     data: order,
  //   });
  // };

  addContentPage = (sessionModuleId: string) =>
    this.client.post(ApiConfigV2.api.course.module.page.add, {
      sessionModuleId,
      attributes: [],
      pageContent: [],
    });

  getPageById = (moduleId: string, pageId: string, language: string) =>
    this.client.get(
      ApiConfigV2.api.course.module.page.fetch
        .replace('{{moduleId}}', moduleId)
        .replace('{{pageId}}', pageId),
      { params: { language } },
    );

  togglePageVisibility = (moduleId: string, pageId: string) =>
    this.client.patch(
      ApiConfigV2.api.course.module.page.toggleVisibility
        .replace('{{moduleId}}', moduleId)
        .replace('{{pageId}}', pageId),
    );

  addOrUpdateQuestion = (
    moduleId: string,
    body: {
      questionId: string | null;
      questionTitle: string;
      placeholder: string;
      questionType: number;
      questionInputType: number;
      options: OptionGroupItemV2[] | null;
      traitScore: TraitScoreV2[];
    },
  ) => {
    const { questionId, ...bodyWithoutId } = body;

    return this.client.post(
      ApiConfigV2.api.course.module.questionnaire.addOrUpdate.replace(
        '{{moduleId}}',
        moduleId,
      ),
      body,
    );
  };

  updateQuestionnaireProperties = (
    moduleId: string,
    body: {
      scoringEnabled?: boolean;
      showResult?: boolean;
      showInProfile?: boolean;
    },
  ) =>
    this.client.patch(
      ApiConfigV2.api.course.module.questionnaire.updateProperties.replace(
        '{{moduleId}}',
        moduleId,
      ),
      body,
    );

  searchOptionGroupLabels = (query: string) =>
    this.client.get(
      ApiConfigV2.api.course.module.questionnaire.searchOptionGroupLabels.replace(
        '{{query}}',
        query,
      ),
    );

  addOptionGroup = (body: {
    groupTitle: string;
    options: Array<OptionGroupItemV2>;
  }) =>
    this.client.post(
      ApiConfigV2.api.course.module.questionnaire.addOptionGroup,
      body,
    );

  addPersonalityTrait = (body: { title: string; description: string }) =>
    this.client.post(
      ApiConfigV2.api.course.module.questionnaire.addPersonalityTrait,
      { ...body, scores: [] },
    );

  getTraitsList = () =>
    this.client.get(ApiConfigV2.api.course.module.questionnaire.getTraitsList);

  getTraitDetails = (traitId: string) =>
    this.client.get(
      ApiConfigV2.api.course.module.questionnaire.getTraitDetails.replace(
        '{{traitId}}',
        traitId,
      ),
    );

  deleteQuestion = (moduleId: string, questionId: string) =>
    this.client.delete(
      ApiConfigV2.api.course.module.questionnaire.delete
        .replace('{{moduleId}}', moduleId)
        .replace('{{questionId}}', questionId),
    );

  fetchPersonalities = () =>
    this.client.get(
      ApiConfigV2.api.course.module.questionnaire.getPersonalities,
    );

  addOrUpdateTrait = (traitId: string, body: Partial<ScoringDetailScoreV2>) =>
    this.client.patch(
      ApiConfigV2.api.course.module.questionnaire.addOrUpdateTraitScore.replace(
        '{{traitId}}',
        traitId,
      ),
      body,
    );

  getRecommendations = () =>
    this.client.get(
      ApiConfigV2.api.course.module.questionnaire.getRecommendationsList,
    );

  addOrEditRecommendation = (body: Partial<RecommendationV2>) => {
    const { _id, image, translationKeys, ...restBody } = body;
    return this.client.post(
      ApiConfigV2.api.course.module.questionnaire.addOrEditRecommendation,
      { ...restBody, recommendationId: _id, imageUrl: image },
    );
  };

  getRecommendationTranslations = (id: string) =>
    this.client.get(
      ApiConfigV2.api.course.module.questionnaire.getRecommendationTranslations.replace(
        '{{recommendationId}}',
        id,
      ),
    );

  getTopics = (): Promise<{ topics: Topic[]; total: number }> =>
    this.client
      .get(ApiConfigV2.api.course.getTopics)
      .then((response) => response.data.data);

  getSessionTranslationsDownloadLink = (id: string) =>
    `${
      ApiConfigV2.api.course.session.getTranslationDownloadLink
    }?id=${id}&type=session&token=${getUserCookie()}`;

  getCourseTranslationsDownloadLink = (id: string) =>
    `${
      ApiConfigV2.api.course.session.getTranslationDownloadLink
    }?id=${id}&type=course&token=${getUserCookie()}`;

  uploadTranslationFile = (file: File, languages: string[]) => {
    const formData = new FormData();
    formData.append('excel_file', file);

    return this.client.post(
      ApiConfigV2.api.course.importTranslations +
        '?languages=' +
        languages.join(','),
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  };

  cloneCourseOrSession = (
    type: string,
    id: string,
    body: Partial<Record<'privilege' | 'categories' | 'language', string>>,
  ) =>
    this.client.post(
      ApiConfigV2.api.course.clone
        .replace('{{type}}', type)
        .replace('{{courseId}}', id),
      { ...body, privilege: Number(body.privilege) },
    );

  // updateContentPage = (
  //   pageId: number,
  //   pageData: Partial<ContentPage>,
  // ) => {
  //   return this.client.put(
  //     ApiConfigV2.api.course.module.page.update.replace(
  //       '{{id}}',
  //       String(pageId),
  //     ),
  //     pageData,
  //   );
  // };

  // deleteContentData = (pageId: number) => {
  //   return this.client.delete(
  //     ApiConfigV2.api.course.module.page.content + String(pageId),
  //   );
  // };

  // getOpenSessions = (type: string = 'rescue') => {
  //   return this.client.get(
  //     ApiConfigV2.api.course.session.open + type + '?dataVersion=2',
  //   );
  // };

  // addSessionModule = (
  //   sessionId: number,
  //   body: {
  //     title: string;
  //     type: number;
  //     isActive: boolean;
  //     questionnaire: any;
  //   },
  // ) => {
  //   return this.client.post(
  //     ApiConfigV2.api.course.module.add.replace(
  //       '{sessionId}',
  //       String(sessionId),
  //     ),
  //     body,
  //   );
  // };

  // getQuesionnaire = (questionnaireId: string) => {
  //   return this.client.get(
  //     ApiConfigV2.api.course.module.questionnaire.detail.replace(
  //       '{{id}}',
  //       questionnaireId,
  //     ),
  //   );
  // };

  // addQuestionnaireQuestions = (
  //   questionnaireId: string,
  //   questions: Question[],
  // ) => {
  //   return this.client.post(
  //     ApiConfigV2.api.course.module.questionnaire.addQuestion.replace(
  //       '{{id}}',
  //       questionnaireId,
  //     ),
  //     questions,
  //   );
  // };

  // updateQuestionnaireQuestion = (question: Question) => {
  //   return this.client.put(
  //     ApiConfigV2.api.course.module.questionnaire.updateQuestion,
  //     question,
  //   );
  // };

  // getOptions = (searchText: string) => {
  //   return this.client.get(
  //     ApiConfigV2.api.course.module.questionnaire.optionGroups +
  //       '?searchKey=' +
  //       searchText,
  //   );
  // };

  // spellCheck = async (text: string) => {
  //   const response = await this.client.post(
  //     ApiConfigV2.api.course.spellCheck,
  //     {
  //       text,
  //       language: 'en-US',
  //     },
  //   );
  //   return (
  //     (response && response.data && response.data.data.matches) || []
  //   );
  // };

  // getSessionModuleMap = async (sessionId: number) => {
  //   const response = await this.client.get(
  //     ApiConfigV2.api.content.module.map.replace(
  //       '{{sessionId}}',
  //       String(sessionId),
  //     ),
  //   );
  //   return response.data.data.map((d: any) => ({
  //     id: d.id,
  //     source: d.parentModuleId,
  //     target: d.childModuleId,
  //     conditions: d.conditions,
  //   }));
  // };

  // saveSessionModuleMap = async (
  //   sessionId: number,
  //   edge: {
  //     source: number;
  //     target: number;
  //     conditions: any[];
  //     id?: number;
  //   }[],
  // ) => {
  //   const response = await this.client.post(
  //     ApiConfigV2.api.content.module.map.replace(
  //       '{{sessionId}}',
  //       String(sessionId),
  //     ),
  //     edge.map(e => ({
  //       id: e.id,
  //       parentModuleId: e.source,
  //       childModuleId: e.target,
  //       conditions: e.conditions,
  //     })),
  //   );
  //   return response && response.data && response.data.data;
  // };

  // courseExpectationAdd = (courseId: string, expectation: any) => {
  //   return this.client.post(
  //     ApiConfigV2.api.course.expectations.add.replace(
  //       '{{id}}',
  //       courseId,
  //     ),
  //     expectation,
  //   );
  // };

  // courseExpectationDelete = async (expectationId: number) => {
  //   const result = await this.client.delete(
  //     ApiConfigV2.api.course.expectations.delete.replace(
  //       '{{id}}',
  //       String(expectationId),
  //     ),
  //   );
  //   return !!result && !!result.data;
  // };

  // courseSessionGroupingRemove = (groupId: number) => {
  //   return this.client.delete(
  //     ApiConfigV2.api.course.grouping.remove + groupId,
  //   );
  // };
}
