import { useMutation } from '@tanstack/react-query';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationDetailItem } from '../../../../../../shared/types/organisation/OrganisationDetailItem';
import toast from 'react-hot-toast';

export const useUpdateOrganisation = (onSuccess: () => void) =>
  useMutation({
    mutationFn: ({
      orgId,
      updatedData,
      isStatusUpdated,
    }: {
      orgId: number;
      updatedData: Partial<OrganisationDetailItem>;
      isStatusUpdated?: boolean;
    }) => OrganisationServiceV2.update(orgId, updatedData, isStatusUpdated),
    onSuccess,
    onError: (err: any) => {
      toast.error(
        err?.response?.data?.message ||
          'Error occured while updating organisation, try after sometime.',
      );
    },
  });
