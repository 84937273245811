import { IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Dispatch, SetStateAction } from 'react';
import { MaterialIcons } from '../../../../../assets/MaterialIcons';
import { OrganisationStatusInList } from '../../../../../shared/constants/Organisation';
import { SsoDomain } from '../../../../../shared/types/organisation/OrganisationDetailItem';
import { getDisplayDateTime } from '../../../../../utilities/Display';
import StatusChip from '../../../../components/StatusChip/StatusChip';
import { TooltipTypography } from '../../../../components/TooltipTypography/TooltipTypography';

export const ClientsDomainConfigsColumns: (payload: {
  setOpenDomainDialog: Dispatch<
    SetStateAction<{
      open: boolean;
      edit: boolean;
      data?: SsoDomain | undefined;
    }>
  >;
}) => GridColDef[] = ({ setOpenDomainDialog }) => [
  {
    flex: 1,
    minWidth: 60,
    field: 'domainName',
    headerName: 'Name',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params.row?.domainName ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 50,
    field: 'status',
    headerName: 'Status',
    renderCell: (params: GridRenderCellParams) => {
      const status: keyof typeof OrganisationStatusInList =
        params.row?.status ?? 0;

      return (
        <StatusChip
          bgColor={
            params?.row?.isActive
              ? 'var(--chip-green-bg-color)'
              : 'var(--chip-red-bg-color)'
          }
          color={
            params?.row?.isActive
              ? 'var(--chip-green-color)'
              : 'var(--chip-red-color)'
          }
          title={params?.row?.isActive ? 'Active' : 'Inactive'}
        />
      );
    },
  },
  {
    flex: 1,
    minWidth: 50,
    field: 'integrationStatus',
    headerName: 'Integration Status',
    renderCell: (params: GridRenderCellParams<SsoDomain>) => {
      const status = params.row?.ssoConfig?.status ?? 'N/A';

      return (
        <StatusChip
          bgColor={
            status === 'LIVE'
              ? 'var(--chip-green-bg-color)'
              : 'var(--chip-yellow-bg-color)'
          }
          color={
            status === 'LIVE'
              ? 'var(--chip-green-color)'
              : 'var(--chip-yellow-color)'
          }
          title={status}
        />
      );
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'updatedAt',
    headerName: 'Updated At',
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <TooltipTypography
          title={
            params?.row?.updatedAt
              ? getDisplayDateTime(params?.row?.updatedAt)
              : '-'
          }
        />
      );
    },
  },
  {
    flex: 0.5,
    minWidth: 20,
    field: 'action',
    headerName: 'Actions',
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <IconButton
          onClick={() =>
            setOpenDomainDialog({ open: true, edit: true, data: params?.row })
          }
        >
          {MaterialIcons.Edit}
        </IconButton>
      );
    },
  },
];
