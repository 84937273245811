import { Box, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TooltipTypography } from '../TooltipTypography/TooltipTypography';

export default function LabelWithHelp({
  label,
  helpText,
  maxWidth,
}: {
  label: string;
  helpText: string;
  maxWidth?: string;
}) {
  return (
    <Box display="flex" gap={1} alignItems="center">
      <TooltipTypography title={label} maxWidth={maxWidth} />

      <Tooltip title={helpText}>
        <InfoOutlinedIcon sx={{ width: 18, height: 18, cursor: 'pointer' }} />
      </Tooltip>
    </Box>
  );
}
