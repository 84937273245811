import { ClearOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { UserManagementService } from '../../../shared/service/services_v2';
import useDebounce from '../../../utilities/hooks/useDebounce';
import LabelWithHelp from '../../components/LabelWithHelp/LabelWithHelp';
import { GenericDialog } from '../../components/genericDialog';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { addUserFormValidationSchema } from './utils/schemas/ValidateAddUserForm.schema';
import {
  IAssociatedRole,
  IUser,
} from './utils/types/user-management.interface';

interface IAddUserInputs {
  id?: string;
  name: string;
  email?: string | null;
  associatedRoles: number[];
}

const EMAIL_SUFFIX = '@intellect.co';

const AddUserDialog = ({
  open,
  edit,
  roles,
  handleClose,
  handleSuccess,
  existingUser,
}: {
  open: boolean;
  edit: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  roles: IAssociatedRole[];
  existingUser: Partial<IUser> | null;
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [emailSearch, setEmailSearch] = useState('');
  const [availableUsersMap, setAvailableUsersMap] = useState<{
    [email: string]: { name: string; id: string };
  }>({});

  /**
   * Searches Non Staff users
   * @param email
   */
  const searchUser = async (email: string) => {
    try {
      const response = await UserManagementService.searchUser(email);
      if (response && response?.data?.success) {
        const users: {
          id: string;
          email: string;
          name: string;
        }[] = response?.data?.data?.items ?? [];

        const map = users.reduce(
          (
            result: {
              [email: string]: { name: string; id: string };
            },
            item,
          ) => {
            result[item.email] = { name: item.name, id: item.id };

            return result;
          },
          {},
        );
        setAvailableUsersMap(map);
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          'An error occurred while attempting to add a user',
      );
    }
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
  } = useFormik<IAddUserInputs>({
    enableReinitialize: true,
    initialValues: {
      name: existingUser?.name || '',
      email: existingUser?.email?.replace(EMAIL_SUFFIX, '') || '',
      associatedRoles:
        existingUser?.associatedRoles?.map((item) => item?.id) ?? [],
    },
    validationSchema: addUserFormValidationSchema,

    onSubmit: async () => {
      if (!edit) {
        handleAddUser();
      } else {
        handleEditUser();
      }
    },
  });

  const debouncedFilter = useDebounce(emailSearch, 500);

  useEffect(() => {
    // Making an api call when atleast 3 letters are there
    if (debouncedFilter?.length > 2) {
      searchUser(debouncedFilter);
    } else {
      setAvailableUsersMap({});
    }
  }, [debouncedFilter]);

  /**
   * Handler for Adding User
   */
  const handleAddUser = async () => {
    let response = null;
    try {
      setSubmitLoading(true);
      const { email, ...restPayload } = values;
      response = await UserManagementService.addUser({
        email: email + EMAIL_SUFFIX,
        ...restPayload,
      });
      if (response && response?.data?.success) {
        toast.success('User added successfully!');
        resetForm();
        handleSuccess();
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          'An error occurred while attempting to add a user',
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  /**
   * Handler for Editing User
   */
  const handleEditUser = async () => {
    let response = null;
    try {
      setSubmitLoading(true);
      if (!existingUser?.id) throw new Error();
      const { email, ...restPayload } = values;

      response = await UserManagementService.editUser(existingUser?.id, {
        email: email + EMAIL_SUFFIX,
        ...restPayload,
      });

      if (response && response?.data?.success) {
        toast.success('User updated successfully!');
        resetForm();
        handleSuccess();
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ??
          'An error occurred while attempting to add a user',
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  const selectedRoles = useMemo(() => {
    return roles?.filter((item) => values?.associatedRoles?.includes(item?.id));
  }, [roles, values?.associatedRoles]);

  return (
    <GenericDialog
      noFullScreen
      open={open}
      handleClose={handleClose}
      title={`${edit ? 'Edit' : 'Add'} User`}
      extraElements={
        <StyledButton
          size="large"
          sx={{ width: '10rem' }}
          loading={true}
          type="submit"
          onClick={handleSubmit}
          disabled={submitLoading}
        >
          Submit
        </StyledButton>
      }
    >
      <Box height={'300px'}>
        <Grid container spacing={2} padding={3}>
          <Grid item sm={6}>
            <InputLabel htmlFor="name">Name</InputLabel>
            <TextField
              fullWidth
              id="name"
              placeholder="Name"
              size="small"
              value={values.name}
              onChange={handleChange}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        visibility: values.name ? 'visible' : 'hidden',
                      }}
                      onClick={() => setFieldValue('name', '')}
                    >
                      <ClearOutlined fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={4}>
            <InputLabel htmlFor="type">Role</InputLabel>
            <Autocomplete
              multiple
              disablePortal
              limitTags={1}
              options={roles}
              getOptionLabel={(option) => option?.name}
              value={selectedRoles}
              onChange={(event, newValue) => {
                setFieldValue(
                  'associatedRoles',
                  newValue?.map((item) => item?.id),
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={
                    touched.associatedRoles && Boolean(errors.associatedRoles)
                  }
                  helperText={touched.associatedRoles && errors.associatedRoles}
                />
              )}
              size="small"
              ChipProps={{ size: 'small' }}
              key={1}
            />
          </Grid>
          <Grid item sm={6}>
            <InputLabel htmlFor="email">
              <LabelWithHelp
                label="Email"
                helpText="Searches through all Non-Staff users & for new value Press Enter"
              />
            </InputLabel>
            {edit ? (
              <TextField
                fullWidth
                id="email"
                size="small"
                placeholder="email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="start">
                        {EMAIL_SUFFIX}
                      </InputAdornment>
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            visibility: values.email ? 'visible' : 'hidden',
                          }}
                          onClick={() => setFieldValue('email', '')}
                        >
                          <ClearOutlined fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
            ) : (
              <Autocomplete
                freeSolo
                disablePortal
                options={Object.keys(availableUsersMap)}
                value={values?.email}
                onChange={(event, newValue: any) => {
                  const existingUser = availableUsersMap?.[newValue];
                  if (existingUser) {
                    setFieldValue('name', existingUser?.name);
                    setFieldValue('id', existingUser?.id);
                  }
                  setFieldValue(
                    'email',
                    newValue?.replace(EMAIL_SUFFIX, '') ?? '',
                  );
                }}
                onInputChange={(event, newValue) => {
                  setEmailSearch(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <InputAdornment position="start">
                            {EMAIL_SUFFIX}
                          </InputAdornment>
                        </>
                      ),
                    }}
                  />
                )}
                size="small"
                ChipProps={{ size: 'small' }}
                key={2}
                loading={searchLoading}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </GenericDialog>
  );
};

export default AddUserDialog;
