import { Grid, InputLabel, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormikProps } from 'formik';
import moment, { Moment } from 'moment';
import LabelWithAsterik from '../../../../../components/LabelWithAsterik/LabelWithAsterik';
import SlidingSection from '../../../../../components/slidingSection/SlidingSection';
import { IAddClientInputs } from './AddOrganisationDialogBase';

const OtherBillingInfo = ({
  formik,
}: {
  formik: FormikProps<IAddClientInputs>;
}) => {
  return (
    <SlidingSection>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <InputLabel id="totalHeadCount">
            <LabelWithAsterik title="Total Head Count" />
          </InputLabel>
          <TextField
            InputProps={{
              inputProps: {
                inputMode: 'numeric',
                pattern: '[0-9]*',
                min: 0,
              },
            }}
            onChange={(event) => {
              const { value } = event.target;
              formik.setFieldValue(
                'totalHeadCount',
                isNaN(Number(value)) ? 0 : Number(value),
              );
            }}
            autoFocus
            fullWidth
            id="total head count"
            name="totalHeadCount"
            value={formik?.values?.totalHeadCount ?? ''}
            error={
              formik?.touched?.totalHeadCount &&
              Boolean(formik?.errors?.totalHeadCount)
            }
            helperText={
              formik?.touched?.totalHeadCount && formik?.errors?.totalHeadCount
            }
            placeholder="Add total head count"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="contract value/amount">
            Contract value/amount
          </InputLabel>
          <TextField
            InputProps={{
              inputProps: {
                inputMode: 'numeric',
                pattern: '[0-9]*',
              },
            }}
            onChange={(event) => {
              const { value } = event.target;
              formik.setFieldValue(
                'contractValue',
                isNaN(parseFloat(value)) ? '' : value.replace(/[^\d.-]/g, ''),
              );
            }}
            fullWidth
            id="contract value/amount"
            name="contractValue"
            value={formik?.values?.contractValue ?? ''}
            error={
              formik?.touched?.contractValue &&
              Boolean(formik?.errors?.contractValue)
            }
            helperText={
              formik?.touched?.contractValue && formik?.errors?.contractValue
            }
            placeholder="contract value/amount"
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="contract start date">Contract start date</InputLabel>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              showToolbar={false}
              inputFormat="yyyy/MM/DD"
              mask="____/__/__"
              onChange={(date: Moment | null) =>
                formik.setFieldValue(
                  'contractStartDate',
                  date?.toISOString() ?? moment().toISOString(),
                )
              }
              value={formik?.values?.contractStartDate ?? ''}
              OpenPickerButtonProps={{
                'aria-label': 'change date',
              }}
              renderInput={(props: any) => (
                <TextField
                  id="contract-start-date"
                  fullWidth
                  {...props}
                  size="small"
                  error={
                    formik?.touched?.contractStartDate &&
                    Boolean(formik?.errors?.contractStartDate)
                  }
                  helperText={
                    formik?.touched?.contractStartDate &&
                    formik?.errors?.contractStartDate
                  }
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </SlidingSection>
  );
};

export default OtherBillingInfo;
