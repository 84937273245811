import React from 'react';

export const StyledUnheckedCheckbox = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' y='0.5' width='19' height='19' rx='3.5' stroke='#AEAEB2' />
  </svg>
);

export const StyledCheckedCheckbox = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='20' height='20' rx='4' fill='#3E445B' />
    <path
      d='M14.6666 6.79163L8.24992 13.2083L5.33325 10.2916'
      stroke='white'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
