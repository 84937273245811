import { Box, Typography } from '@mui/material';
import { CheckIcon } from '@primer/octicons-react';
import moment from 'moment';
import { useState } from 'react';
import { getSlotTz } from '../../../../../../utilities/Date';
import { GenericDialog } from '../../../../../components/genericDialog';
import { LoadingView } from '../../../../../components/loadingView';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import SwitchButton from '../../../../../widgets/switchButton/SwitchButton';
import { useGetAvailableMeetingSlots } from '../../../hooks/useGetAvailableMeetingSlots';
import { useRescheduleMeeting } from '../../../hooks/useRescheduleMeeting';

const RescheduleMeetingDialog = ({
  open,
  handleClose,
  closeMeetingInfo,
  timezone,
  meeting,
  refreshData = undefined,
}: {
  open: boolean;
  handleClose: () => void;
  closeMeetingInfo?: () => void;
  timezone: string;
  meeting: any;
  refreshData?: () => void;
}) => {
  const [selectedSlot, setSelectedSlot] = useState<number>(0);
  const { data, isLoading } = useGetAvailableMeetingSlots(
    meeting.participants[0].userId,
    meeting.providerRole,
  );
  const { mutate } = useRescheduleMeeting(() => {
    if (closeMeetingInfo) closeMeetingInfo();
    handleClose();
    if (refreshData) refreshData();
  });

  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      title="Select a slot to reschedule to"
      noFullScreen
      extraElements={
        <>
          <Typography variant="subtitle2">Timings are in {timezone}</Typography>
          <StyledButton
            sx={{ width: '10rem' }}
            size="large"
            onClick={() =>
              mutate({
                id: meeting.id,
                body: {
                  userId: String(meeting.participants[0].userId),
                  scheduledStartTime: String(selectedSlot),
                  duration: meeting.duration,
                  role: meeting.providerRole,
                },
              })
            }
            disabled={selectedSlot === 0}
          >
            Confirm
          </StyledButton>
        </>
      }
    >
      {isLoading ? (
        <LoadingView />
      ) : data && meeting.duration in data ? (
        <Box display="flex" gap={1} flexWrap="wrap" p={3}>
          {data[meeting.duration].map((item: any) => {
            const isSelected = item.startTimeUtc === selectedSlot;
            const convertedTime = getSlotTz(
              meeting.scheduledDate,
              meeting.scheduledSlot,
              timezone,
            ).displayTime;
            return (
              <Box
                key={`${item.startTimeUtc}-${item.endTimeUtc}`}
                padding={1}
                onClick={() => setSelectedSlot(item.startTimeUtc)}
                sx={{
                  borderRadius: '0.5rem',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderColor: isSelected ? 'green' : '#8C90A6',
                  cursor: 'pointer',
                  transition: 'all 0.2s linear',
                  backgroundColor: isSelected ? 'green' : 'white',
                  '&:hover': {
                    filter: 'brightness(85%)',
                  },
                }}
              >
                <Box display="flex" gap={1} alignItems="center">
                  <Typography
                    fontWeight="bold"
                    color={isSelected ? 'white' : 'initial'}
                  >
                    {moment(item.startTimeUtc, 'X')
                      .tz(timezone)
                      .format('DD/MM/YYYY')}
                  </Typography>
                  {isSelected && <CheckIcon size={18} fill="white" />}
                </Box>
                <Typography
                  variant="subtitle1"
                  color={isSelected ? 'white' : 'initial'}
                >
                  {moment(item.startTimeUtc, 'X').tz(timezone).format('hh:mmA')}
                  -{moment(item.endTimeUtc, 'X').tz(timezone).format('hh:mmA')}
                </Typography>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Typography variant="subtitle1">No slots available.</Typography>
      )}
    </GenericDialog>
  );
};

export default RescheduleMeetingDialog;
