import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

export const MembersInMultipleTeamsColumns: GridColDef[] = [
  {
    flex: 1,
    minWidth: 60,
    field: 'id',
    headerName: 'ID',
    renderCell: (params: GridRenderCellParams) => {
      return params?.row?.id;
    },
  },
  {
    flex: 1,
    minWidth: 60,
    field: 'userId',
    headerName: 'User ID',
    renderCell: (params: GridRenderCellParams) => {
      return params?.row?.userId;
    },
  },
  {
    flex: 1,
    minWidth: 60,
    field: 'organisationTeamId',
    headerName: 'Team ID',
    renderCell: (params: GridRenderCellParams) => {
      return params?.row?.organisationTeamId;
    },
  },
];
