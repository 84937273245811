import { Box, Grid, InputLabel, TextField } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';

import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';

const DeleteMembersByEmailDialog = ({
  orgId,
  open,
  handleClose,
  refreshData,
}: {
  orgId: number;
  open: boolean;
  handleClose: () => void;
  refreshData: () => void;
}) => {
  const [emails, setEmails] = useState<string>('');

  const handleSubmit = async () => {
    let response = null;

    try {
      response = await OrganisationServiceV2.deleteMultipleMembersByEmail(
        emails.split(','),
        orgId,
      );
      if (response && response.data.success) {
        refreshData();
        toast.success(
          `${response.data.data.count} Members deleted successfully!`,
        );
        handleClose();
        setEmails('');
      }
    } catch (error) {
      toast.error(
        'An error occurred while deleting the members. Please try again.',
      );
    }
  };

  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      noFullScreen
      title="Delete members by email"
      extraElements={
        <StyledButton
          sx={{ width: '7rem' }}
          onClick={handleSubmit}
          color="gray"
        >
          Delete
        </StyledButton>
      }
    >
      <Box padding={5}>
        <InputLabel>Email addresses, separated by commas</InputLabel>
        <TextField
          value={emails}
          fullWidth
          type="email"
          autoFocus
          onChange={(event) => {
            setEmails(event.target.value);
          }}
        />
      </Box>
    </GenericDialog>
  );
};

export default DeleteMembersByEmailDialog;
