import { useQuery } from '@tanstack/react-query';
import { BillingServiceV2 } from '../../../../shared/service/services_v2';

export const useGetCreditRequests = () =>
  useQuery({
    queryKey: ['creditRequests'],
    queryFn: () => BillingServiceV2.getCreditRequests(),
    meta: {
      errorMessage:
        'An error occurred while attempting to get the credit requests.',
    },
  });
