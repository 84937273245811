import { useQuery } from '@tanstack/react-query';
import { ChiefProviderServiceV2 } from '../../shared/service/services_v2';
import { Country } from '../../shared/types/provider/Provider';

export const useGetCountries = () =>
  useQuery({
    queryKey: ['countries'],
    queryFn: () => ChiefProviderServiceV2.getCountries(),
    staleTime: 30 * 60 * 1000, // Data is fresh for 30 minutes
    cacheTime: 40 * 60 * 1000, // Data is kept in cache for 40 minutes
    select: (data) => (data?.data?.data?.countries ?? []) as Country[],
    meta: {
      errorMessage: 'An error occurred while attempting to get countries.',
    },
  });
