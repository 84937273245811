import { useQuery } from '@tanstack/react-query';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { isObjectEmpty } from '../../../../../../utilities/isObjectEmpty';
import { IOrganisationListFilterTypes } from '../OrganisationList';

export const useOrganisationList = (
  page: number,
  pageSize: number,
  searchFilter: IOrganisationListFilterTypes,
  status: string,
  onSuccess: (newTotal: number) => void,
) =>
  useQuery({
    queryKey: ['organisationList', page, pageSize, searchFilter, status],
    queryFn: async () => {
      const { name, expiresIn } = searchFilter;
      const data = await OrganisationServiceV2.getList(
        name,
        page,
        pageSize,
        status,
        expiresIn,
      );

      onSuccess(data.total);

      return data;
    },
    meta: {
      errorMessage:
        'An error occurred while attempting to get the list of organisations.',
    },
    select(data) {
      if (isObjectEmpty(data)) {
        return {
          items: [],
          total: 0,
        };
      } else {
        return data;
      }
    },
  });
