import { useMutation, useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { ChiefProviderServiceV2 } from '../../../../shared/service/services_v2';

export const useCancelMeeting = (onSuccess: () => void) =>
  useMutation({
    mutationKey: ['rescheduleMeeting'],
    mutationFn: (body: Record<'meetingId' | 'userId', string>) =>
      ChiefProviderServiceV2.meetingCancel(body.meetingId, body.userId),
    onSuccess() {
      toast.success('Session cancelled successfully!');
      onSuccess();
    },
    onError() {
      toast.error('An error occurred when attempting to cancel the meeting.');
    },
  });
