import { ApiConfigV2 } from '../../apiConfigV2';
import {
  CollectionContentItem,
  CollectionDetailItem,
  CollectionLabel,
} from '../../types/collection/CollectionDetailItem';
import { TranslationV2 } from '../../types/course_v2/CourseDetail';
import { SessionListItem } from '../../types/course_v2/CourseSession';
import { Subset } from '../../types/UtilTypes';
import { NetworkInstanceV2 } from './NetworkInstance';

class Collection {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getAllCollections = () => this.client.get(ApiConfigV2.api.collection.list);

  addOrUpdateCollection = (body: Subset<CollectionDetailItem>) => {
    const { _id, items, ...rest } = body;
    delete rest?.translationKeys;
    return this.client.post(ApiConfigV2.api.collection.add, {
      collectionId: _id,
      items: items?.map(({ _id, languages, type, categories }) => ({
        id: _id,
        languages,
        type,
        categories: [rest.label],
      })),
      ...rest,
    });
  };

  getCollectionContentSuggestions(
    type: CollectionLabel | undefined,
    pageNo: number,
    title: string,
    id: string,
    languages?: string[],
  ): Promise<{ items: CollectionContentItem[]; total: number }> {
    let experienceTypeContent: string | undefined;

    if ((!!type && type === 'article') || type === 'video') {
      experienceTypeContent = type;
    }
    let languageParam;
    if (languages && languages.length > 0) {
      languageParam = type !== 'course' ? languages : languages?.join(',');
    }
    const params: Record<string, any> = {
      page: pageNo + 1,
      text: title,
      id,
      languages: languageParam,
      ...(type !== 'course' && { category: [type] }),
    };

    if (experienceTypeContent) {
      params.experienceType = [experienceTypeContent];
    }

    return this.client
      .get(
        ApiConfigV2.api.collection[
          type === 'course' ? 'courseList' : 'sessionList'
        ],
        { params },
      )
      .then((response) => ({
        items:
          response.data.data[type === 'course' ? 'courses' : 'sessions'] ?? [],
        total: response.data.data.total ?? 0,
      }));
  }

  getCollectionSessions = (
    pageNo: number,
    queryParams = '',
  ): Promise<{ sessions: SessionListItem[]; total: number }> =>
    this.client
      .get(
        ApiConfigV2.api.catalogue.sessionList.replace(
          '{{pageNo}}',
          pageNo.toString(),
        ) + queryParams,
      )
      .then((response) => response.data.data);

  getCollectionDetails = (id: string): Promise<CollectionDetailItem> =>
    this.client
      .get(ApiConfigV2.api.collection.detail.replace('{{collectionId}}', id))
      .then((response) => response.data.data);

  deleteCollection = (id: string) =>
    this.client.delete(
      ApiConfigV2.api.catalogue.delete.replace('{{catalogueId}}', id),
    );

  getCollectionTranslations = (id: string): Promise<TranslationV2> =>
    this.client
      .get(
        ApiConfigV2.api.collection.translations.replace('{{collectionId}}', id),
      )
      .then((response) => response.data.data.translations);

  //   addCourse = (
  //     courseTitle: string,
  //     description: string,
  //     shortDescription: string,
  //     languages: Array<string>,
  //   ) => {
  //     return this.client.post(ApiConfigV2.api.course.add, {
  //       courseTitle,
  //       description,
  //       shortDescription,
  //       languages,
  //     });
  //   };

  activateCollection = (id: string, isVisible: boolean) => {
    return this.client.put(
      ApiConfigV2.api.collection.publish.replace('{{collectionId}}', id),
      { isActive: !isVisible },
    );
  };

  //   getCourseDetails = (id: string) => {
  //     return this.client.get(
  //       ApiConfigV2.api.course.detail.replace('{{courseId}}', id),
  //     );
  //   };

  //   getCourseSessions = (id: string) => {
  //     return this.client.get(
  //       ApiConfigV2.api.course.session.list.replace('{{courseId}}', id),
  //     );
  //   };

  //   getSessionDetails = (id: String) => {
  //     return this.client.get(ApiConfigV2.api.course.session.details + id);
  //   };

  //   addSession = (body: {
  //     courseId: string;
  //     sessionTitle: string;
  //     sessionDescription: string;
  //     sessionGroupId: string;
  //   }) => {
  //     return this.client.post(ApiConfigV2.api.course.session.add, body);
  //   };
}

export { Collection };
