import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import { useContext, useState } from 'react';

import {
  DefaultCountryStatuses,
  OffboardingStrategies,
  TeamStatuses,
} from '../../../../../shared/constants/Organisation';
import { ConfigMfaItem } from '../../../../../shared/types/organisation/OrganisationDetailItem';
import OrganisationDetailSectionHeader from './OrganisationDetailSectionHeader';
import { OrganisationoDetailContext } from './OrganisationDetailView';
import { useGetOrgMfaList } from './hooks/useGetOrgMfaList';
import { useGetOrgOperationalCountries } from './hooks/useGetOrgOperationalCountries';

const OrganisationConfigurations = () => {
  const { isEditing, updatedData, setUpdatedData } = useContext(
    OrganisationoDetailContext,
  );

  const [countryInputValue, setCountryInputValue] = useState<string>('');

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 300,
  });

  const { data: operationalCountriesList } = useGetOrgOperationalCountries();
  const { data: orgMfaList } = useGetOrgMfaList();

  const handleCountryChange = (_event: any, newValue: any) => {
    if (newValue) {
      if (typeof newValue === 'string') {
        setUpdatedData((prev) => ({
          ...prev,
          operationalCountries: [newValue],
        }));
      } else {
        setUpdatedData((prev) => ({
          ...prev,
          operationalCountries: newValue,
        }));
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} width={12}>
        <OrganisationDetailSectionHeader
          title="Configuration"
          editingModule={'ORG_CONFIGURATION'}
        />
      </Grid>
      <Grid item xs={12} width={12}>
        <InputLabel htmlFor="default-team-status">
          Department Selection
        </InputLabel>
        <Select
          onChange={(event: SelectChangeEvent<string>) =>
            setUpdatedData((prev) => ({
              ...prev,
              defaultTeamStatus: event.target.value,
            }))
          }
          fullWidth
          disabled={!isEditing.ORG_CONFIGURATION}
          id="default-team-status"
          value={updatedData?.defaultTeamStatus ?? ''}
          placeholder="Add default team status"
          size="small"
        >
          {TeamStatuses.map((status) => (
            <MenuItem key={status.value} value={status.value}>
              {status.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} width={12}>
        <InputLabel htmlFor="default-team-status">
          Offboarding action
        </InputLabel>
        <Select
          onChange={(event: SelectChangeEvent<number>) =>
            setUpdatedData((prev) => ({
              ...prev,
              offBoardingStrategy: Number(event.target.value),
            }))
          }
          fullWidth
          disabled={!isEditing.ORG_CONFIGURATION}
          id="offboarding-strategy"
          value={updatedData?.offBoardingStrategy ?? ''}
          placeholder="Add offboarding strategy"
          size="small"
        >
          {OffboardingStrategies.map((strategy) => (
            <MenuItem key={strategy.value} value={strategy.value}>
              {strategy.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} width={12}>
        <InputLabel htmlFor="default-country-status">
          Country Selection
        </InputLabel>
        <Select
          onChange={(event: SelectChangeEvent<number>) =>
            setUpdatedData((prev) => ({
              ...prev,
              defaultCountryStatus: Number(event.target.value),
            }))
          }
          fullWidth
          disabled={!isEditing.ORG_CONFIGURATION}
          id="default-country-status"
          value={updatedData?.defaultCountryStatus ?? ''}
          placeholder="Add default country status"
          size="small"
        >
          {Object.entries(DefaultCountryStatuses).map(([key, value]) => (
            <MenuItem key={`countrystatus-${key}-${value}`} value={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} width={12}>
        <InputLabel htmlFor="operational-countries">
          Operational countries
        </InputLabel>
        <Autocomplete
          filterOptions={filterOptions}
          limitTags={3}
          multiple={true}
          options={operationalCountriesList ?? []}
          value={updatedData?.operationalCountries ?? []}
          inputValue={countryInputValue}
          onInputChange={(event, newInputValue) => {
            setCountryInputValue(newInputValue);
          }}
          disabled={!isEditing.ORG_CONFIGURATION || !operationalCountriesList}
          id="operational-countries"
          disableCloseOnSelect
          onChange={handleCountryChange}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option}
            </li>
          )}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </Grid>
      <Grid item xs={12} width={12}>
        <InputLabel>Multi-Factor Authentication</InputLabel>
        <Typography variant="subtitle2">
          {updatedData?.mfaEnabled ? 'Enabled' : 'Disable'}
        </Typography>
      </Grid>
      {updatedData?.mfaEnabled && (
        <Grid item xs={12} width={12}>
          <InputLabel>Primary mode of authentication</InputLabel>
          <Select
            size="small"
            disabled={true}
            // onChange={(event) => handleAuthChange(Number(event.target.value))}
            value={updatedData?.primaryMfaFactor ?? 'None'}
          >
            {[
              { id: 0, factorName: 'None' },
              ...(orgMfaList || [])?.map((option: ConfigMfaItem) => ({
                id: option.id,
                factorName: option.factorName,
              })),
            ].map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.factorName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
      <Grid item xs={12} width={12}>
        <InputLabel id="">MS Teams access</InputLabel>
        <Select
          disabled={true}
          onChange={(event: SelectChangeEvent<boolean>) =>
            setUpdatedData((prev) => ({
              ...prev,
              msTeamsEnabled: event.target.value === 'true' ? true : false,
            }))
          }
          autoFocus
          fullWidth
          id="enableMsTeams"
          value={updatedData?.msTeamsEnabled ?? ''}
          size="small"
        >
          <MenuItem value={'true'}>Enabled</MenuItem>
          <MenuItem value={'false'}>Disabled</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} width={12}>
        <InputLabel id="">Enable MS Teams</InputLabel>
        <Select
          disabled={true}
          onChange={(event: SelectChangeEvent<boolean>) =>
            setUpdatedData((prev) => ({
              ...prev,
              msTeamsEnabled: event.target.value === 'true' ? true : false,
            }))
          }
          autoFocus
          fullWidth
          id="enableMsTeams"
          value={updatedData?.msTeamsEnabled ?? ''}
          size="small"
        >
          <MenuItem value={'true'}>Enable</MenuItem>
          <MenuItem value={'false'}>Disable</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
};

export default OrganisationConfigurations;
