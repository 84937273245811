import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { TooltipTypography } from '../../../components/TooltipTypography/TooltipTypography';
import { formatDate } from '../../userManagement/utilties';
import { STATUS_TEXT } from '../../../../shared/constants/Provider';
import moment from 'moment';

export const MeetingHistoryColumns: GridColDef[] = [
  {
    flex: 1,
    minWidth: 120,
    field: 'action',
    headerName: 'Action',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.action ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'oldValue',
    headerName: 'Old Value',
    renderCell: (params: GridRenderCellParams) => {
      if (params?.row?.oldValue?.length < 2) {
        return (
          <TooltipTypography
            title={STATUS_TEXT[params?.row?.oldValue] || 'N/A'}
          />
        );
      }
      return <TooltipTypography title={params?.row?.oldValue ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'newValue',
    headerName: 'New Value',
    renderCell: (params: GridRenderCellParams) => {
      if (params?.row?.newValue?.length < 2) {
        return (
          <TooltipTypography
            title={STATUS_TEXT[params?.row?.newValue] || 'N/A'}
          />
        );
      }
      return <TooltipTypography title={params?.row?.newValue ?? 'N/A'} />;
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'updatedAt',
    headerName: 'Last Updated On',
    renderCell: (params: GridRenderCellParams) => {
      if (params?.row?.updatedAt) {
        const dateTime = moment(params?.row?.updatedAt);
        return (
          <TooltipTypography title={dateTime.format('YYYY-MM-DD HH:mm:ss')} />
        );
      } else {
        return <TooltipTypography title={'N/A'} />;
      }
    },
  },
  {
    flex: 1,
    minWidth: 120,
    field: 'updated By',
    headerName: 'updated By',
    renderCell: (params: GridRenderCellParams) => {
      return <TooltipTypography title={params?.row?.updated ?? 'N/A'} />;
    },
  },
];
