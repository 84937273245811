import { useEffect, useState } from 'react';
import FeatureBox from '../../widgets/featureBox';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { Typography } from '@mui/material';
import TableViewComponent from '../../components/TableViewComponent/TableViewComponent';
import { MeetingHistoryColumns } from './utils/MeetingHistoryGridColumns';

interface MeetingHistoryDetail {
  createdAt: string;
  updatedAt: string;
  meetingId: string;
  id: number;
  updatedBy: number;
  action: string;
  oldValue: string;
  newValue: string;
  platform: string | null;
}

const MeetingHistoryView = ({
  historyData,
  providerId,
  userId,
}: {
  historyData: MeetingHistoryDetail[];
  providerId: any;
  userId: any;
}) => {
  const [pageSize, setPageSize] = useState<number>(7);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [meetingData, setMeetingData] = useState<MeetingHistoryDetail[]>([]);

  useEffect(() => {
    if (historyData) {
      const addUserAndProviderId = historyData?.map(
        (history: MeetingHistoryDetail) => {
          return {
            ...history,
            updated:
              +history.updatedBy === +userId
                ? 'USER'
                : history.updatedBy === providerId
                ? 'PROVIDER'
                : 'INTERNAL',
          };
        },
      );
      setMeetingData(addUserAndProviderId);
    }
  }, [historyData, userId, providerId]);

  return (
    <FeatureBox>
      <SectionHeader
        title={
          <Typography variant="h6" fontWeight={'bold'}>
            Meeting History Data
          </Typography>
        }
      />

      {meetingData.length > 0 && (
        <TableViewComponent
          rows={meetingData}
          columns={MeetingHistoryColumns}
          pageSizeCustom={pageSize}
          currentPage={Math.ceil(currentPage / pageSize)}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
          }}
          onPageChange={(page) => {
            const skipTemp = page * pageSize;
            setCurrentPage(skipTemp);
          }}
          getRowId={(row: any) => `${row.id}-${Math.random() + 10}`}
        />
      )}
    </FeatureBox>
  );
};

export default MeetingHistoryView;
