import ConfirmDialog from '../../../../../components/confirmDialog/ConfirmDialog';
import { useCancelMeeting } from '../../../hooks/useCancelMeeting';

const CancelMeetingDialog = ({
  open,
  handleClose,
  meetingId,
  userId,
  refreshData,
}: {
  open: boolean;
  handleClose: () => void;
  meetingId: string;
  userId: string;
  refreshData?: () => void;
}) => {
  const { mutate } = useCancelMeeting(() => {
    handleClose();
    if (refreshData) refreshData();
  });
  return (
    <ConfirmDialog
      title="Cancel meeting?"
      open={open}
      handleClose={handleClose}
      onConfirm={() => mutate({ meetingId, userId })}
    >
      Do you want mark this session as cancelled? User credits will be refunded.
    </ConfirmDialog>
  );
};

export default CancelMeetingDialog;
