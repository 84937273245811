import { ApiConfigV2 } from '../../apiConfigV2';
import { OrganisationAccessCode } from '../../types/organisation/OrganisationDetailItem';
import { NetworkInstanceV2 } from './NetworkInstance';

export class MarketingV2 {
  private client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getSignupList = (page: number) =>
    this.client.get(`${ApiConfigV2.api.marketing.signupList}?page=${page}`);

  addCampaign = (data: any) =>
    this.client.post(ApiConfigV2.api.marketing.campaigns, data);

  getCampaigns = (page: number) =>
    this.client.get(`${ApiConfigV2.api.marketing.campaigns}?page=${page}`);

  addAccessCode = (
    data: Partial<OrganisationAccessCode>,
    campaignId?: number,
  ) =>
    this.client.post(ApiConfigV2.api.marketing.codes, {
      ...data,
      campaignId,
    });

  addB2BAccessCode = (data: Partial<OrganisationAccessCode>, orgId?: number) =>
    this.client.post(ApiConfigV2.api.marketing.codes, {
      ...data,
      organisationId: orgId,
    });

  updateB2BAccessCode = (data: Partial<OrganisationAccessCode>, code: string) =>
    this.client.put(
      ApiConfigV2.api.marketing.updateCode?.replace('{{code}}', code),
      data,
    );
}
