import { Typography } from '@mui/material';

interface IStatusChipProps {
  bgColor: string;
  color: string;
  fontSize?: string;
  margin?: string;
  title: string;
}
const StatusChip = ({
  bgColor,
  color,
  title,
  fontSize = '16px',
}: IStatusChipProps) => {
  return (
    <Typography
      style={{
        backgroundColor: bgColor,
        color: color,
        alignItems: 'center',
        fontSize: fontSize,
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 5,
        height: 'auto',
      }}
      textTransform="initial"
    >
      {title}
    </Typography>
  );
};

export default StatusChip;
