import { decodeFromBase64, encodeToBase64 } from './encryption';

export const addItemToLocalStorage = (
  key: string,
  item: string | { [key: string]: any } | { [key: string]: any }[],
): void => {
  const encodedKey = encodeToBase64(key);
  const preProcessedItem =
    typeof item === 'string' ? item : JSON.stringify(item);
  const encodedItem = encodeToBase64(preProcessedItem);

  localStorage.setItem(encodedKey, encodedItem);
};

export const getItemFromLocalStorage = (
  key: string,
  itemType: 'string' | 'object',
): string | { [key: string]: any } => {
  const encodedKey = encodeToBase64(key);
  const retrievedItem = localStorage.getItem(encodedKey);
  if (retrievedItem) {
    const decodedItem =
      itemType === 'string'
        ? decodeFromBase64(retrievedItem)
        : JSON.parse(decodeFromBase64(retrievedItem));

    return decodedItem;
  }

  return itemType === 'string' ? '' : {};
};

export const removeItemFromLocalStorage = (key: string) => {
  const encodedKey = encodeToBase64(key);
  localStorage.removeItem(encodedKey);
};
