import { Box, FormHelperText, Grid, InputLabel } from '@mui/material';
import { FormikProps } from 'formik';
import { useState } from 'react';
import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import {
  CreditPoolTypes,
  OrganisationStatusForCreation,
} from '../../../../../../shared/constants/Organisation';
import { getDisplayDate } from '../../../../../../utilities/Display';
import LabelWithAsterik from '../../../../../components/LabelWithAsterik/LabelWithAsterik';
import SectionHeader from '../../../../../components/SectionHeader/SectionHeader';
import SlidingSection from '../../../../../components/slidingSection/SlidingSection';
import FeatureBox from '../../../../../widgets/featureBox';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import SwitchButton from '../../../../../widgets/switchButton/SwitchButton';
import CreditRenewDialog from '../../organisationDetail/organisationBilling/creditsV2/CreditRenewDialog';
import { IAddClientInputs } from './AddOrganisationDialogBase';
import moment from 'moment';

const ChooseBillingPlan = ({
  onSubmittedPlansChange,
  formik,
}: {
  onSubmittedPlansChange: (submittedPlans: any[]) => void;
  formik: FormikProps<IAddClientInputs>;
}) => {
  // TODO not being used anywhere
  // const { data: billingPlansList, isLoading } = useGetBillingPlans();
  const [isAddCreditPlanDialogOpen, setIsAddCreditPlanDialogOpen] =
    useState<boolean>(false);

  const setChosenPlans = (selectedPlansArray: any) => {
    onSubmittedPlansChange(selectedPlansArray);
    setIsAddCreditPlanDialogOpen(false);
  };
  // if (!billingPlansList) {
  //   return <LoadingView />;
  // }
  return (
    <>
      <SlidingSection>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>
              <LabelWithAsterik title="Deal Type" />
            </InputLabel>
            <Box display={'flex'} gap={'10px'}>
              {Object.entries(OrganisationStatusForCreation).map(
                ([statusId, statusLabel]) => (
                  <SwitchButton
                    key={'planid-' + statusId}
                    active={formik?.values?.status === statusId}
                    margin={0}
                    onClick={() => {
                      formik.setFieldValue('status', statusId);
                    }}
                  >
                    {statusLabel}
                  </SwitchButton>
                ),
              )}
            </Box>
            {formik?.touched?.status && Boolean(formik?.errors?.status) ? (
              <FormHelperText sx={{ color: 'error.main' }}>
                {formik?.touched?.status && formik?.errors?.status}
              </FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <SectionHeader
              title={<InputLabel>Plans</InputLabel>}
              rightElement={
                <StyledButton
                  color="gray"
                  variant="ghost"
                  startIcon={MaterialIcons.Add}
                  onClick={() => setIsAddCreditPlanDialogOpen(true)}
                  margin={0}
                >
                  Add plan
                </StyledButton>
              }
            />
            <Grid container spacing={2}>
              {formik?.values?.selectedPlans?.map((pool, index) => (
                <>
                  <Grid item xs={4} key={index}>
                    <FeatureBox
                      sx={{
                        padding: '10px',
                        textAlign: 'center',
                        height: 180,
                        margin: 0,
                      }}
                      key={index}
                    >
                      <div style={{ fontSize: 20, fontWeight: 600 }}>
                        {CreditPoolTypes.find((item) => item.type === pool.type)
                          ?.label || ''}
                      </div>
                      <div style={{ fontSize: 12, fontWeight: 300 }}>
                        (
                        {pool.credits === null &&
                          pool.creditPerUser === null &&
                          'Unlimited Plan'}
                        {pool.credits === null &&
                          pool.creditPerUser !== null &&
                          'Credits per user plan'}
                        {pool.credits !== null &&
                          pool.creditPerUser !== null &&
                          'Hybrid plan'}
                        {pool.credits !== null &&
                          pool.creditPerUser === null &&
                          'Pool plan'}
                        )
                      </div>
                      <div style={{ fontSize: 18, fontWeight: 400 }}>
                        Credits: {pool?.credits ?? 'N/A'}
                      </div>
                      <div style={{ fontSize: 14 }}>
                        Restrictions:{' '}
                        {pool.creditPerUser != null
                          ? `${pool.creditPerUser} per user`
                          : 'No limit per user'}
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 300,
                        }}
                      >
                        Valid From: {getDisplayDate(moment().toISOString())} /{' '}
                        {`Valid Until: ${getDisplayDate(pool.expiresAt)}`}
                      </div>
                    </FeatureBox>
                  </Grid>
                </>
              )) ?? null}
            </Grid>
          </Grid>
        </Grid>
      </SlidingSection>
      <CreditRenewDialog
        isAddPlan={true}
        handleSubmit={setChosenPlans}
        handleClose={() => setIsAddCreditPlanDialogOpen(false)}
        open={isAddCreditPlanDialogOpen}
        status={formik?.values?.status ?? ''}
        orgCreate={true}
      />
    </>
  );
};

export default ChooseBillingPlan;
