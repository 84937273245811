import { Switch } from 'react-router-dom';
import { AuthenticatedRoute } from '../../components/authenticatedRoute/AuthenticatedRoute';
import Config from '../../../config';

import MeetingDetailsView from './meetingDetailsView';

const MeetingInfoView = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        exact
        path={Config.paths.meeting.details}
        component={MeetingDetailsView}
      />
    </Switch>
  );
};

export default MeetingInfoView;
