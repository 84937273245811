import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { InfoIcon } from '@primer/octicons-react';
import moment from 'moment';

import { CreditPoolTypes } from '../../../../../../../shared/constants/Organisation';
import { getDisplayDate } from '../../../../../../../utilities/Display';
import FeatureBox from '../../../../../../widgets/featureBox';

const renderPlanDetails = ({ pool, handleOpenPopover }: {
  pool: any,
  handleOpenPopover: (event: any) => void
}) => {
  return <>
    <div style={{ fontSize: 12, fontWeight: 300 }}>
      (
      {pool.credits === null &&
        pool.creditPerUser === null &&
        'Unlimited Plan'}
      {pool.credits === null &&
        pool.creditPerUser !== null &&
        'Credits per user plan'}
      {pool.credits !== null &&
        pool.creditPerUser !== null &&
        'Hybrid plan'}
      {pool.credits !== null &&
        pool.creditPerUser === null &&
        'Pool plan'}
      )
    </div>
    <div
      style={{
        fontSize: 18,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
      }}
    >
      Utilisation: {pool.creditsUsed}
      {pool.credits !== null && `/ ${pool.credits}`}
      <Tooltip
        title={'Click for a monthly break down'}
        onClick={handleOpenPopover}
        data-pool-id={pool.id}
      >
        <IconButton sx={{ padding: 0 }}>
          <InfoIcon size={16} />
        </IconButton>
      </Tooltip>
    </div>
    <Box
      style={{
        fontSize: 12,
        fontWeight: 300,
      }}
    >
      (Including future sessions)
    </Box>
    <div style={{ fontSize: 14 }}>
      Restrictions:{' '}
      {pool.creditPerUser != null
        ? `${pool.creditPerUser} per user`
        : 'No limit per user'}
    </div>
    <div style={{ fontSize: 12, fontStyle: 'italic' }}>
      {pool.notes ? `Note: ${pool.notes?.replace('\n', ' ')}` : ''}
    </div>
  </>;
};
const RenderPlans = ({
                       credits,
                       handleOpenPopover,
                     }: {
  credits: any[];
  handleOpenPopover: (event: any) => void;
}) => {
  return (
    <Grid container spacing={2}>
      {credits
      ?.sort((a, b) => a.expiresAt - b.expiresAt)
      ?.sort((a, b) => +a.type - +b.type)
      .map((pool: any, index: number) => {
        const diffDays = moment(pool.expiresAt).diff(moment(), 'days');
        return (
          <Grid item xs={4} key={pool.id}>
            <FeatureBox
              sx={{
                minWidth: '200px',
                padding: '10px',
                textAlign: 'center',
                height: 180,
                margin: 0,
              }}
            >
              <div style={{ fontSize: 20, fontWeight: 600 }}>
                {CreditPoolTypes.find((item) => item.type === pool.type)
                  ?.label || ''}
              </div>
              {pool.type !== '10' && renderPlanDetails({ pool, handleOpenPopover })}
              {pool.type === '10' && 'Same as clinical'}
              <div
                style={{
                  fontSize: 12,
                  fontWeight: 300,
                }}
              >
                Valid From: {getDisplayDate(pool.createdAt)} /{' '}
                {diffDays > 0
                  ? `Valid Until: ${getDisplayDate(pool.expiresAt)}`
                  : `Expired on ${getDisplayDate(pool.expiresAt)}`}
              </div>
            </FeatureBox>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default RenderPlans;
