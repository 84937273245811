import { Tooltip, Typography, TypographyProps } from '@mui/material';
import React from 'react';

export const TooltipTypography = ({
  title,
  ...typographyProps
}: TypographyProps & { title: string }) => {
  return (
    <Tooltip title={title}>
      <Typography className="txt-overflow" {...typographyProps}>
        {title}
      </Typography>
    </Tooltip>
  );
};
