import { DefaultToastOptions } from 'react-hot-toast';

export const ToasterConfig: DefaultToastOptions = {
  duration: 4000,
  position: 'bottom-left',
  style: {
    color: '#fff',
  },
  // Default options for specific types
  error: {
    iconTheme: {
      primary: 'white',
      secondary: '#ec7979',
    },
    style: {
      backgroundColor: '#ec7979',
    },
  },

  success: {
    iconTheme: {
      primary: 'white',
      secondary: '#5ebb50',
    },
    style: {
      backgroundColor: '#5ebb50',
    },
  },
  iconTheme: {
    primary: 'white',
    secondary: 'green',
  },
};
