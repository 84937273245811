import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Typography } from '@mui/material';
import { DownloadIcon } from '@primer/octicons-react';
import StyledButton from '../../widgets/styledButton/StyledButton';
import FeatureBox from '../../widgets/featureBox';

export const ExcelExportView = ({
  data,
  fileName,
}: {
  data: any[];
  fileName: string;
}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (data: any[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    const result = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(result, fileName + fileExtension);
  };
  return (
    <StyledButton
      color="light"
      variant="ghost"
      startIcon={<DownloadIcon size={18} />}
      onClick={() => exportToCSV(data, fileName)}
    >
      Export Members
    </StyledButton>
  );
};
