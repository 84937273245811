import { AxiosResponse } from 'axios';
import { ApiConfigV2 } from '../../apiConfigV2';
import {
  ConfigItem,
  CreditPlanRequestPayload,
  CreditPoolRequestPayload,
  OrganisationDetailItem,
  OrganisationDomainConfig,
  SsoDomain,
  Subscription,
} from '../../types/organisation/OrganisationDetailItem';
import { OrganisationListItem } from '../../types/organisation/OrganisationListItem';
import {
  BulkAddMembersRequestBody,
  MultipleTeamMembersRequestBody,
  OrganisationTeamItem,
} from '../../types/organisation/OrganisationTeamItem';
import { OrganisationTeamMemberItem } from '../../types/organisation/OrganisationTeamMemberItem';
import { NetworkInstanceV2 } from './NetworkInstance';

export class OrganisationV2 {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getList = (
    name = '',
    page = 1,
    pageSize = 10,
    status: string | null,
    expiresIn = '',
  ): Promise<
    | {
        items: OrganisationListItem[];
        total: number;
        takeRecords: string;
      }
    | Record<string, never>
  > =>
    this.client
      .get(
        `${ApiConfigV2.api.organisation.list}?name=${name}&page=${page}&take=${pageSize}&status=${status}&expiresIn=${expiresIn}`,
      )
      .then((response) => response.data.data);

  getListV2 = (
    queryParams: Record<string, any> = {},
  ): Promise<
    | {
        items: OrganisationListItem[];
        total: number;
        takeRecords: string;
      }
    | Record<string, never>
  > =>
    this.client
      .get(`${ApiConfigV2.api.organisation.list}`, {
        params: queryParams,
      })
      .then((response) => response?.data?.data);

  searchList = (filter: string) =>
    this.client.get(`${ApiConfigV2.api.organisation.list}?name=${filter}`);

  getDetail = (id: number) =>
    this.client.get(
      ApiConfigV2.api.organisation.detail.replace('{{orgId}}', String(id)),
    );

  getOrgMappedProviders = (id: number) =>
    this.client.get(
      ApiConfigV2.api.provider.getOrgMapped.replace('{{orgId}}', String(id)),
    );

  addOrgMappedProviders = (id: number, providerId: number) => {
    return this.client.post(
      ApiConfigV2.api.provider.addOrgProviders
        .replace('{{orgId}}', String(id))
        .replace('{{providerId}}', String(providerId)),
    );
  };

  deleteOrgMappedProviders = (id: number, providerId: number) => {
    console.log('org id', id, providerId);
    return this.client.delete(
      ApiConfigV2.api.provider.addOrgProviders
        .replace('{{orgId}}', String(id))
        .replace('{{providerId}}', String(providerId)),
    );
  };

  addOrganisation = (org: Partial<OrganisationListItem>) =>
    this.client
      .post(ApiConfigV2.api.organisation.create, {
        ...org,
        status: Number(org.status),
      })
      .then((response) => response.data.data);
  // .then((data) =>
  //   this.addSubscription({ ...subscription, organisationId: data.id }),
  // );

  update = (
    id: number,
    info: Partial<OrganisationDetailItem>,
    isStatusUpdated?: boolean,
  ) => {
    const { operationalCountries, status, ...rest } = info;

    return this.client
      .put(
        ApiConfigV2.api.organisation.update.replace('{{orgId}}', String(id)),
        { ...rest, countries: operationalCountries },
      )
      .then((res) =>
        isStatusUpdated && status
          ? this.updateStatus(id, +status)
          : Promise.resolve(res),
      );
  };

  updateStatus = (orgId: number, status: number) => {
    return this.client.put(
      ApiConfigV2.api.organisation.updateStatus.replace(
        '{{orgId}}',
        String(orgId),
      ),
      { status: String(status) },
    );
  };

  addTeam = (id: number, body: { teamName: string }) =>
    this.client.post(
      ApiConfigV2.api.organisation.team.create.replace('{{id}}', String(id)),
      body,
    );

  updateTeam = (
    id: number,
    params?: {
      name?: string;
      isActive?: boolean;
      addOns?: string;
      coachCredits?: number;
    },
  ) =>
    this.client.put(
      ApiConfigV2.api.organisation.team.update.replace('{{id}}', String(id)),
      {
        name: params?.name,
        isActive: params?.isActive,
        addOns: params?.addOns,
        coachCredits: params?.coachCredits,
      },
    );

  updateTeamV2 = (id: number, body: Partial<OrganisationTeamItem>) =>
    this.client.put(
      ApiConfigV2.api.organisation.team.update.replace('{{id}}', String(id)),
      body,
    );

  addChildTeam = (id: number, body: { teamName: string }) =>
    this.client.post(
      ApiConfigV2.api.organisation.team.addChildTeam.replace(
        '{{id}}',
        String(id),
      ),
      body,
    );

  deleteTeam = (id: number) =>
    this.client.delete(
      ApiConfigV2.api.organisation.team.update.replace('{{id}}', String(id)),
    );

  addTeamMembers = (
    id: number,
    params: Partial<OrganisationTeamMemberItem>[],
  ) =>
    this.client.post(
      ApiConfigV2.api.organisation.team.addMembers.replace(
        '{{id}}',
        String(id),
      ),
      params,
    );

  addMultipleTeamMembers = (id: number, data: MultipleTeamMembersRequestBody) =>
    this.client.post(
      ApiConfigV2.api.organisation.team.addMultipleMembers.replace(
        '{{id}}',
        String(id),
      ),
      data,
    );

  addProviderProfile = (
    body: Record<'name' | 'providerId' | 'type' | 'services', string>,
  ) =>
    this.client.post(`${ApiConfigV2.api.provider.profile}`, {
      ...body,
      profileImage: 'image.jpg',
      bio: ' ',
    });

  bulkAddTeamMembers = (id: number, data: BulkAddMembersRequestBody) =>
    this.client.post(
      ApiConfigV2.api.organisation.team.bulkAddMembers.replace(
        '{{id}}',
        String(id),
      ),
      data,
    );

  updateMember = (
    id: number,
    params: {
      emailAddress: string;
      role: string;
      organisationTeamId: number;
      countryCode?: string;
    },
  ) =>
    this.client.put(
      ApiConfigV2.api.organisation.team.updateMember.replace(
        '{{id}}',
        String(id),
      ),
      params,
    );

  deleteMember = (id: number) =>
    this.client.delete(
      ApiConfigV2.api.organisation.team.updateMember.replace(
        '{{id}}',
        String(id),
      ),
    );

  deleteMultipleMembersById = (ids: number[]) =>
    this.client.delete(
      ApiConfigV2.api.organisation.team.deleteMultipleMembers,
      { data: { ids } },
    );

  updateMembersToPendingState = (orgId: number, ids: number[]) =>
    this.client.post(
      ApiConfigV2.api.organisation.membersStatusToPending.replace(
        '{{id}}',
        String(orgId),
      ),
      {
        memberIds: ids,
      },
    );

  updateMultipleMembersTeamById = ({
    orgId,
    currentTeamId,
    newTeamId,
    memberIds,
  }: {
    orgId: number;
    currentTeamId: number;
    newTeamId: number;
    memberIds: number[];
  }) =>
    this.client.post(
      ApiConfigV2.api.organisation.bulkUpdateMembersTeam.replace(
        '{{id}}',
        String(orgId),
      ),
      {
        memberIds,
        currentTeamId,
        newTeamId,
      },
    );

  deleteMultipleMembersByEmail = (emails: string[], orgId: number) =>
    this.client.delete(
      ApiConfigV2.api.organisation.team.deleteMultipleMemberEmails,
      { data: { emails, organisationId: orgId } },
    );

  bulkOffboard = (file: File) => {
    const formData = new FormData();
    formData.append('excel_file', file);

    return this.client.post(
      ApiConfigV2.api.organisation.team.bulkOffboardMembers,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  };

  activateMembers = (id: number) =>
    this.client.post(
      ApiConfigV2.api.organisation.memberActivate.replace('{{id}}', String(id)),
    );

  activateSelectedMembers = (organisationId: number, memberIds: number[]) =>
    this.client.post(
      ApiConfigV2.api.organisation.memberActivateSelected.replace(
        '{{id}}',
        String(organisationId),
      ),
      {
        ids: memberIds,
      },
    );

  syncWithMailChimp = (orgId: number) =>
    this.client.get(
      ApiConfigV2.api.organisation.mailChimpSync.replace(
        '{{orgId}}',
        String(orgId),
      ),
    );

  getAccessCode = (id: number) =>
    this.client.get(
      ApiConfigV2.api.organisation.accessCodes.replace('{{id}}', String(id)),
    );

  uploadExcel = (organisationId: number, file: Blob) => {
    const bodyFormData = new FormData();
    bodyFormData.append('structure', file);
    return this.client.post(
      ApiConfigV2.api.organisation.upload.replace(
        '{{id}}',
        String(organisationId),
      ),
      bodyFormData,
    );
  };

  getOperationalCountriesList = async () =>
    this.client.get(ApiConfigV2.api.organisation.countries.list);

  getTeams = async (organisationId: number) =>
    this.client.get(
      ApiConfigV2.api.organisation.team.list.replace(
        '{{id}}',
        String(organisationId),
      ),
    );

  getMembersByTeamId = async (teamId: number) => {
    const result = await this.client.get(
      ApiConfigV2.api.organisation.team.members.replace(
        '{{id}}',
        String(teamId),
      ),
    );
    return result.data.data;
  };

  getMembersByTeamIdV2 = async (teamId: number) =>
    this.client.get(
      ApiConfigV2.api.organisation.team.members.replace(
        '{{teamId}}',
        String(teamId),
      ),
    );

  getMembersByOrg = async (organisationId: number) =>
    this.client.get(
      ApiConfigV2.api.organisation.members.replace(
        '{{orgId}}',
        String(organisationId),
      ),
    );

  getMembersInMultipleTeamsByOrg = async (organisationId: number) =>
    this.client.get(
      ApiConfigV2.api.organisation.membersInMultipleTeams.replace(
        '{{orgId}}',
        String(organisationId),
      ),
    );

  sendReport = (body: {
    report_name: string;
    start_date: string;
    end_date: string;
    type: string;
    business_name: string;
  }) => this.client.post(ApiConfigV2.api.organisation.sendReport, body);

  getOrgConfigs = (orgId: number) =>
    this.client.get(
      ApiConfigV2.api.organisation.config.org.getList.replace(
        '{{orgId}}',
        String(orgId),
      ),
    );

  getTeamConfigs = (teamId: number) =>
    this.client.get(
      ApiConfigV2.api.organisation.config.team.getList.replace(
        '{{teamId}}',
        String(teamId),
      ),
    );

  getMfaOptions = () =>
    this.client.get(ApiConfigV2.api.organisation.config.org.mfa);

  setMfaEnabled = (orgId: number, mfaStatus: boolean) =>
    this.client.post(
      ApiConfigV2.api.organisation.config.org.mfaEnabled.replace(
        '{{orgId}}',
        String(orgId),
      ),
      { mfaStatus },
    );

  setPrimaryFactor = (orgId: number, id: number) =>
    this.client.put(
      ApiConfigV2.api.organisation.config.org.primaryMfaFactor
        .replace('{{orgId}}', String(orgId))
        .replace('{{Id}}', String(id)),
    );

  addOrgConfigs = (configs: Partial<ConfigItem>[], orgId: number) =>
    this.client.post(
      ApiConfigV2.api.organisation.config.org.add.replace(
        '{{orgId}}',
        String(orgId),
      ),
      configs,
    );

  addTeamConfigs = (configs: Partial<ConfigItem>[], teamId: number) =>
    this.client.post(
      ApiConfigV2.api.organisation.config.team.add.replace(
        '{{teamId}}',
        String(teamId),
      ),
      configs,
    );

  updateConfig = (config: ConfigItem) => {
    config.isPublic = false;
    const { id, ...rest } = config;
    return this.client.put(
      ApiConfigV2.api.organisation.config.update.replace(
        '{{configId}}',
        String(id),
      ),
      rest,
    );
  };

  /**
   * Deletes from config_master table
   * @param configId
   * @returns
   */
  deleteConfig = (configId: number) =>
    this.client.delete(
      ApiConfigV2.api.organisation.config.delete.replace(
        '{{configId}}',
        String(configId),
      ),
    );

  /**
   * Deletes organisation's specific config
   * @param orgId
   * @param configId
   * @returns
   */
  deleteOrganisationConfig = (orgId: number, configId: number) =>
    this.client.delete(
      ApiConfigV2.api.organisation.config.org.delete
        .replace('{{orgId}}', String(orgId))
        .replace('{{configId}}', String(configId)),
    );

  addSubscription = (body: Partial<Subscription>) =>
    this.client.post(ApiConfigV2.api.organisation.subscription.add, body);

  updateSubscription = (body: Partial<Subscription>) =>
    this.client.put(ApiConfigV2.api.organisation.subscription.update, body);

  addCreditPool = (
    body: Partial<CreditPoolRequestPayload>,
    organisationId?: number,
  ) =>
    this.client.post(ApiConfigV2.api.organisation.creditPool.add, {
      ...body,
      organisationId,
    });

  addCreditPlan = (
    body: Partial<CreditPlanRequestPayload>,
    organisationId?: number,
  ) =>
    this.client.post(ApiConfigV2.api.organisation.creditPlan.add, {
      ...body,
      organisationId,
    });

  addBillingPlanAssociatedPool = (planId: string, creditPoolId: string) =>
    this.client.put(
      ApiConfigV2.api.billing.addAssociatedPool.replace('{{planId}}', planId),
      { id: creditPoolId },
    );

  runOutplacement = (organisationId: number, outplacementDetails: any) => {
    return this.client.post(
      ApiConfigV2.api.organisation.outplacement.add.replace(
        '{{orgId}}',
        String(organisationId),
      ),
      outplacementDetails,
    );
  };
  addCredits = (
    body: Partial<CreditPlanRequestPayload[]>,
    organisationId?: number,
  ) => {
    const payloadData = body.map((pool: any) => {
      return {
        ...pool,
        organisationId,
      };
    });
    return this.client.post(ApiConfigV2.api.organisation.creditPlanV2.add, {
      data: payloadData,
    });
  };
  renewCredits = (
    body: Partial<CreditPlanRequestPayload[]>,
    organisationId?: number,
  ) => {
    const payloadData = body.map((pool: any) => {
      return {
        ...pool,
        organisationId,
      };
    });

    return this.client.post(ApiConfigV2.api.organisation.creditPlanV2.add, {
      override: true,
      data: payloadData,
    });
  };

  updateCredits = (
    body: Partial<CreditPlanRequestPayload[]>,
    organisationId?: number,
  ) => {
    const payloadData = body.map((pool: any) => {
      return {
        ...pool,
        credits:
          pool?.credits !== null && pool?.credits !== '' ? +pool.credits : null,
        notes: '',
      };
    });

    return this.client.put(ApiConfigV2.api.organisation.creditPlanV2.add, {
      data: payloadData,
    });
  };

  getPlanHistory = (planId: string) => {
    return this.client.get(
      ApiConfigV2.api.organisation.creditPlanV2.history.replace(
        '{{planId}}',
        String(planId),
      ),
    );
  };

  getDomainEntityConfigs = (
    orgId: number,
  ): Promise<AxiosResponse<{ data: OrganisationDomainConfig[] }>> => {
    return this.client.get(
      ApiConfigV2.api.organisation.domains.getDomainEntityConfigs.replace(
        '{{orgId}}',
        String(orgId),
      ),
    );
  };

  getDomainConfigs = (orgId: number) => {
    return this.client.get(
      ApiConfigV2.api.organisation.domains.getDomains.replace(
        '{{orgId}}',
        String(orgId),
      ),
    );
  };

  createDomain = (configId: number, payload: Partial<SsoDomain> = {}) => {
    return this.client.post(
      ApiConfigV2.api.organisation.domains.createDomain.replace(
        '{{configId}}',
        String(configId),
      ),
      payload,
    );
  };

  updateDomain = (domainId: number, payload: Partial<SsoDomain> = {}) => {
    return this.client.put(
      ApiConfigV2.api.organisation.domains.updateDomain.replace(
        '{{domainId}}',
        String(domainId),
      ),
      payload,
    );
  };

  getDomainUsers = (orgId: number) => {
    const url = `${ApiConfigV2.api.organisation.domainUsers.replace(
      '{{id}}',
      String(orgId),
    )}`;
    return this.client.get(url);
  };

  getAccessCodeUsers = (orgId: number) => {
    const url = `${ApiConfigV2.api.organisation.accessCodeUsers.replace(
      '{{id}}',
      String(orgId),
    )}`;
    return this.client.get(url);
  };
}
