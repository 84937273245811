import { useMemo, useState } from 'react';
import TableViewComponent from '../../../../../../components/TableViewComponent/TableViewComponent';
import moment from 'moment';
import { CreditPoolTypes } from '../../../../../../../shared/constants/Organisation';
import { getDisplayDate } from '../../../../../../../utilities/Display';
import { RenderPlansGridColumns } from '../../../../utils/grid-columns/RenderPlansGridColumnts';

const RenderPlansTabular = ({
  credits,
  handleOpenPopover,
}: {
  credits: any[];
  handleOpenPopover: (event: any) => void;
}) => {
  const [pageSize, setPageSize] = useState<number>(7);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const preparedCredits = useMemo(() => {
    return credits
      ?.sort((a, b) => a.expiresAt - b.expiresAt)
      ?.sort((a, b) => +a.type - +b.type)
      .map((pool: any, index: number) => {
        const diffDays = moment(pool.expiresAt).diff(moment(), 'days');
        const obj = {
          ...pool,
          planName:
            CreditPoolTypes.find((item) => item.type === pool.type)?.label ||
            '',
          planType: '',
          planUtilisation:
            pool.creditsUsed +
            ' ' +
            (pool.credits !== null ? `/ ${pool.credits}` : ''),
          planRestrictions:
            pool.creditPerUser != null
              ? `${pool.creditPerUser} per user`
              : 'No limit per user',
          planValidFrom: getDisplayDate(pool?.createdAt),
          planValidUntil: getDisplayDate(pool?.expiresAt),
          planNotes: pool?.notes?.replace('\n', ' '),
        };

        if (pool.credits === null && pool.creditPerUser === null) {
          obj.planType = 'Unlimited Plan';
        } else if (pool.credits === null && pool.creditPerUser !== null) {
          obj.planType = 'Credits per user plan';
        } else if (pool.credits !== null && pool.creditPerUser !== null) {
          obj.planType = 'Hybrid plan';
        } else if (pool.credits !== null && pool.creditPerUser === null) {
          obj.planType = 'Pool plan';
        }

        return obj;
      });
  }, [credits]);

  return (
    <TableViewComponent
      rows={preparedCredits}
      columns={RenderPlansGridColumns({ handleOpenPopover })}
      pageSizeCustom={pageSize}
      currentPage={Math.ceil(currentPage / pageSize)}
      onPageSizeChange={(pageSize) => {
        setPageSize(pageSize);
      }}
      onPageChange={(page) => {
        const skipTemp = page * pageSize;
        setCurrentPage(skipTemp);
      }}
    />
  );
};

export default RenderPlansTabular;
