import { ServerIcon } from '@primer/octicons-react';
import React, { ChangeEvent, useRef } from 'react';
import StyledButton from '../../../widgets/styledButton/StyledButton';

const FileSelector = ({
  files = [],
  setFiles,
  multiple,
  accept = '',
}: {
  files?: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  multiple?: boolean;
  accept?: string;
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selected = event.target.files;

    if (selected) {
      const filesToAdd = [];

      for (let i = 0; i < selected.length; i++) {
        filesToAdd.push(selected[i]);
      }
      setFiles([...files, ...filesToAdd]);
    }
  };

  return (
    <>
      <StyledButton
        onClick={handleClick}
        startIcon={<ServerIcon size={18} />}
        color="gray"
        sx={{
          padding: '0.5rem 1.5rem',
          alignSelf: 'flex-start',
          fontSize: 18,
        }}
      >
        Select file{multiple ? '(s)' : ''}
      </StyledButton>
      <input
        type="file"
        accept={accept}
        ref={hiddenFileInput}
        multiple={multiple}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default FileSelector;
