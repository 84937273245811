import { Typography, Divider } from '@mui/material';
import { Box } from '@mui/system';
import { AlertFillIcon, UploadIcon } from '@primer/octicons-react';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import FileSelector from '../../../../../components/fileUpload/uploadFile/FileSelector';

const BulkOffboardDialog = ({
  open,
  refreshData,
  handleClose,
}: {
  open: boolean;
  refreshData: () => void;
  handleClose: () => void;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [offboardedData, setOffboardedData] = useState<{
    offboarded: number[];
    notOffBoarded: number[];
  } | null>(null);

  const handleUpload = async () => {
    let response = null;
    try {
      response = await OrganisationServiceV2.bulkOffboard(files[0]);

      if (response && response.data.success) {
        toast.success('Files uploaded successfully!');
        refreshData();

        setOffboardedData(response.data.data);

        if (response.data.data === null) {
          toast.error(
            'Warning: The employees in this sheet were already offboarded.',
            {
              duration: 5000,
              style: { backgroundColor: '#BD8026', color: 'white' },
              icon: <AlertFillIcon />,
              iconTheme: { primary: 'white', secondary: '#BD8026' },
            }
          );

          handleClose();
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(
        err.response?.status === 422
          ? `${err.response?.data.message} : ${err.response?.data?.errors[0]}`
          : 'An error occurred while uploading the file. Please check the file uploaded and try again.'
      );
    }
  };

  useEffect(() => {
    if (!open) {
      setFiles([]);
      setOffboardedData(null);
    }
  }, [open]);

  return (
    <GenericDialog
      open={open}
      handleClose={handleClose}
      noFullScreen
      title='Bulk offboard members'
      extraElements={
        <StyledButton
          disabled={files.length === 0}
          startIcon={<UploadIcon size={18} />}
          onClick={handleUpload}
        >
          Upload file
        </StyledButton>
      }
    >
      <Box paddingX={5} paddingY={3}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
        >
          <Typography variant='subtitle1'>
            Only .xls, .xlsx files allowed
          </Typography>
          <Box display='flex' alignItems='center' gap={4}>
            <Typography variant='h6'>
              {files.length === 0 ? 'No file selected.' : files[0].name}
            </Typography>
            <FileSelector
              setFiles={setFiles}
              accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            />
          </Box>
          <Typography variant='subtitle1' color='error' mt={3}>
            The file you are uploading must have these fields for offboarding to
            work properly:{' '}
            <strong>id, organisationTeamId and emailAddress</strong>.
          </Typography>
        </Box>
        <Divider sx={{ marginY: 2 }} variant='middle' />
        {offboardedData !== null && (
          <>
            <Box display='flex' alignItems='center' px={5}>
              <Typography fontSize='large' fontWeight='bold'>
                Employees offboarded
              </Typography>
              <Box
                display='flex'
                gap={2}
                marginY={2}
                flexWrap='wrap'
                paddingX={3}
              >
                {offboardedData.offboarded.map((id) => (
                  <code key={'offboarded-id-' + id}>{id}</code>
                ))}
              </Box>
            </Box>
            <Box display='flex' alignItems='center' px={5}>
              <Typography fontSize='large' fontWeight='bold'>
                Employees not offboarded
              </Typography>
              <Box
                display='flex'
                gap={2}
                marginY={2}
                flexWrap='wrap'
                paddingX={3}
              >
                {offboardedData.notOffBoarded.length > 0
                  ? offboardedData.notOffBoarded.map((id) => (
                      <code key={'notOffboarded-id-' + id}>{id}</code>
                    ))
                  : 'N/A'}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </GenericDialog>
  );
};

export default BulkOffboardDialog;
