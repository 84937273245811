import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useMemo } from 'react';
import { isArray } from 'lodash';

/**
 * Encodes an object into a query string.
 * @param params - The object containing key-value pairs to encode.
 * @returns The encoded query string.
 */
export const encodeQueryStrings = (params: Record<string, string | number>) => {
  const queryStrings = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

  return queryStrings;
};

/**
 * A custom hook to parse query strings from the URL.
 * @returns The parsed query string as an object.
 */
export const useQueryString = (options?: queryString.ParseOptions) => {
  const { search } = useLocation();

  const memoizedQueryString = useMemo(() => {
    return queryString.parse(search, options);
  }, [search]);

  return memoizedQueryString;
};

/**
 * Function to convert single value into array
 * @param value
 * @returns
 */
export const convertQueryStringToArray = (value: any) => {
  return (value ? (isArray(value) ? value : [value]) : []) as string[];
};
