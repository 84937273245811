import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Breadcrumbs,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React, { SyntheticEvent, useContext, useMemo, useState } from 'react';

import { BellIcon } from '@primer/octicons-react';
import { Link, useLocation } from 'react-router-dom';
import Config from '../../../config';
import { StorageItems } from '../../../shared/constants/App';
import { getItemFromLocalStorage } from '../../../utilities/Storage';
import { RootViewContext } from '../../screens/RootView';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { MaterialIcons } from '../../../assets/MaterialIcons';
import { logOutUser } from '../../../utilities/App';

const BadgeContentSpan = styled('span')({
  width: 8,
  height: 8,
  borderRadius: '50%',
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.09)',
});

const Appbar = ({ children }: { children?: React.ReactNode }) => {
  const { minimizeNavbar, appBreadCrumbs } = useContext(RootViewContext);
  const theme = useTheme();
  const user = useMemo(() => {
    return getItemFromLocalStorage(StorageItems.USER_INFO, 'object') as Record<
      string,
      any
    >;
  }, []);

  const [anchorElUser, setAnchorElUser] = useState<null | Element>(null);

  const location = useLocation();

  const handleOpenUserMenu = (event: SyntheticEvent) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position='fixed'
      sx={{
        zIndex: 1000,
        backgroundColor: '#ffffff',
        color: '#3E445B',
        boxShadow: 'none',
        borderBottom: '0.1rem solid #F5F4F3',
        marginLeft: minimizeNavbar
          ? Config.drawerWidth
          : `calc(${theme.spacing(8)} + 8px)`,
        width: minimizeNavbar
          ? `calc(100% - ${Config.drawerWidth}px)`
          : `calc(100% - calc(${theme.spacing(8)} + 8px))`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: minimizeNavbar
            ? theme.transitions.duration.enteringScreen
            : theme.transitions.duration.leavingScreen,
        }),
        'li a': {
          color: '#8C90A6',
          textDecoration: 'none',
        },
        'li a:hover': {
          textDecoration: 'underline',
        },
        'li:last-child span': {
          fontWeight: 'bold',
          color: '#3E445B',
        },
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography color='inherit' noWrap>
          {appBreadCrumbs?.length ? (
            <Breadcrumbs aria-label='breadcrumb'>
              {appBreadCrumbs?.map((item, idx) =>
                item?.type === 'link' ? (
                  <Link
                    key={idx}
                    to={item?.path ?? '#'}
                    color={
                      item?.path?.includes(location.pathname)
                        ? 'textPrimary'
                        : ''
                    }
                  >
                    {item?.label}
                  </Link>
                ) : item?.type === 'callback' ? (
                  <StyledButton
                    variant='ghost'
                    color='gray'
                    onClick={item?.callback}
                    margin={0}
                    startIcon={item?.startIcon}
                    endIcon={item?.endIcon}
                  >
                    {item?.label}
                  </StyledButton>
                ) : (
                  <Typography key={idx} color='textPrimary'>
                    {item?.label}
                  </Typography>
                ),
              )}
            </Breadcrumbs>
          ) : (
            <></>
          )}
        </Typography>
        <Box>
          <IconButton color='inherit' size='large'>
            <Badge badgeContent={0} color='secondary'>
              <BellIcon size={24} />
            </Badge>
          </IconButton>
          <Tooltip title='User Profile'>
            <Badge
              overlap='circular'
              badgeContent={<BadgeContentSpan />}
              sx={{ cursor: 'pointer' }}
            >
              <Avatar
                alt={`${
                  user?.name ? user?.name?.slice(0, 1)?.toUpperCase() : 'U'
                }`}
                src={`${
                  user?.name ? user?.name?.slice(0, 1)?.toUpperCase() : 'U'
                }`}
                sx={{
                  backgroundColor: '#028DF9',
                  fontWeight: 700,
                }}
                onClick={handleOpenUserMenu}
              />
            </Badge>
          </Tooltip>

          <Menu
            sx={{
              mt: '10px',
              padding: 0,
              '& .MuiMenu-paper': { width: 230, mt: 4 },
            }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Box sx={{ px: 2 }}>
              <Box display={'flex'} alignItems={'center'}>
                <Badge overlap='circular' badgeContent={<BadgeContentSpan />}>
                  <Avatar
                    alt={`${
                      user?.name ? user?.name?.slice(0, 1)?.toUpperCase() : 'U'
                    }`}
                    src={`${
                      user?.name ? user?.name?.slice(0, 1)?.toUpperCase() : 'U'
                    }`}
                    sx={{
                      backgroundColor: '#028DF9',
                      fontWeight: 700,
                    }}
                  />
                </Badge>
                <Box
                  sx={{
                    ml: 1,
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>
                    {user?.name || 'User'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ m: 1 }} />
            <MenuItem onClick={logOutUser}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  fontSize: '14px',
                  color: '#222529',
                }}
              >
                {MaterialIcons.LogoutIcon}
                Logout
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export { Appbar };
