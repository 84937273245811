import { ApiConfigV2 } from '../../apiConfigV2';
import { CourseListItemV2 } from '../../types/course_v2/CourseListItem';
import { SessionListItem } from '../../types/course_v2/CourseSession';
import { NetworkInstanceV2 } from './NetworkInstance';

class Catalogue {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getAllCatalogues = () => this.client.get(ApiConfigV2.api.catalogue.list);

  addOrUpdateCatalogue = (body: {
    catalogueId?: string;
    title: string;
    description?: string;
    catalogueType: string;
    label?: string;
    items: Array<string>;
    isVisible: boolean;
  }) => this.client.post(ApiConfigV2.api.catalogue.add, body);

  getCatalogueCourses = (
    pageNo: number,
  ): Promise<{ courses: CourseListItemV2[]; total: number }> =>
    this.client
      .get(
        ApiConfigV2.api.catalogue.courseList.replace(
          '{{pageNo}}',
          pageNo.toString(),
        ),
      )
      .then((response) => response.data.data);

  getCatalogueSessionsRevised = (
    pageNo: number,
    queryParams: Record<string, any> = {},
  ): Promise<{ sessions: SessionListItem[]; total: number }> =>
    this.client
      .get(ApiConfigV2.api.catalogue.sessionListRevised, {
        params: {
          page: pageNo,
          ...queryParams,
        },
      })
      .then((response) => response.data.data);

  getCatalogueDetails = (id: string) =>
    this.client.get(
      ApiConfigV2.api.catalogue.detail.replace('{{catalogueId}}', id),
    );

  deleteCatalogue = (id: string) =>
    this.client.delete(
      ApiConfigV2.api.catalogue.delete.replace('{{catalogueId}}', id),
    );
  //   addCourse = (
  //     courseTitle: string,
  //     description: string,
  //     shortDescription: string,
  //     languages: Array<string>,
  //   ) => {
  //     return this.client.post(ApiConfigV2.api.course.add, {
  //       courseTitle,
  //       description,
  //       shortDescription,
  //       languages,
  //     });
  //   };

  //   updateCourse = (courseId: string, body: Partial<CourseDetailV2>) => {
  //     return this.client.post(ApiConfigV2.api.course.update, body);
  //   };

  //   getCourseDetails = (id: string) => {
  //     return this.client.get(
  //       ApiConfigV2.api.course.detail.replace('{{courseId}}', id),
  //     );
  //   };

  //   getCourseSessions = (id: string) => {
  //     return this.client.get(
  //       ApiConfigV2.api.course.session.list.replace('{{courseId}}', id),
  //     );
  //   };

  //   getSessionDetails = (id: String) => {
  //     return this.client.get(ApiConfigV2.api.course.session.details + id);
  //   };

  //   addSession = (body: {
  //     courseId: string;
  //     sessionTitle: string;
  //     sessionDescription: string;
  //     sessionGroupId: string;
  //   }) => {
  //     return this.client.post(ApiConfigV2.api.course.session.add, body);
  //   };
}

export { Catalogue };
