import React from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import {
  STATUS_TEXT,
  STATUS_TEXT_CHIP_COLORS,
} from '../../../../../../shared/constants/Provider';
import { ChiefProviderServiceV2 } from '../../../../../../shared/service/services_v2';
import { Provider } from '../../../../../../shared/types/provider/Provider';
import { MeetingInfo } from './MeetingInfo';
import { TooltipTypography } from '../../../../../components/TooltipTypography/TooltipTypography';
import SectionHeader from '../../../../../components/SectionHeader/SectionHeader';
import StatusChip from '../../../../../components/StatusChip/StatusChip';
import { LoadingView } from '../../../../../components/loadingView';
import { MeetingInfoPopup } from './MeetingInfoPopup';

export const encodeUserId = (number: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const base = characters.length;
  let code = '';

  while (number > 0) {
    const remainder = number % base;
    code = characters[remainder] + code;
    number = Math.floor(number / base);
  }
  while (code.length < 6) {
    code = characters[0] + code;
  }

  return code;
};

const ProviderSessionView = ({ provider }: { provider: Provider }) => {
  const [selectedDate, setSelectedDate] = React.useState(
    moment().tz(provider.timezone).toDate(),
  );
  const [sessions, setSessions]: [any, Function] = React.useState({});
  const [loading, setLoading]: [boolean, Function] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState<{
    provider: any;
    meeting: any;
  } | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getData = async (date: Date) => {
    const startDate =
      moment().tz(provider.timezone).utcOffset() > 0
        ? moment(date).subtract(1, 'day')
        : moment(date);

    const response = await ChiefProviderServiceV2.getSessions(
      provider.userId,
      startDate.format('YYYY/MM/DD'),
      moment(startDate).add(1, 'day').format('YYYY/MM/DD'),
    );
    if (response && response.data) {
      const filteredSessions = response.data.data.sessions.filter(
        (session: any) => {
          if (
            moment(startDate).format('YYYY/MM/DD') === session.scheduledDate &&
            session.scheduledSlot >=
              moment().tz(provider.timezone).utcOffset() / 30
          ) {
            return true;
          }
          if (
            moment(startDate).add(1, 'd').format('YYYY/MM/DD') ===
              session.scheduledDate &&
            session.scheduledSlot <
              moment().tz(provider.timezone).utcOffset() / 30
          ) {
            return true;
          }
          return false;
        },
      );
      setSessions({
        ...sessions,
        [moment(date).format('YYYY/MM/DD')]: filteredSessions,
      });
    }
  };

  const handleDateChange = async (value: Date | Date[] | null) => {
    setLoading(true);
    if (value && !Array.isArray(value)) {
      setSelectedDate(moment(value).startOf('day').toDate());
      await getData(value);
    }
    setLoading(false);
  };

  const displayDate = moment(selectedDate).format('YYYY/MM/DD');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4} width={4}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticDatePicker
              openTo="day"
              onChange={handleDateChange}
              value={selectedDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={8} width={8} display={'flex'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            gap={'5px'}
            flexWrap={'wrap'}
          >
            {loading ? (
              <LoadingView />
            ) : (
              sessions[displayDate] &&
              sessions[displayDate].map((session: any) => (
                <Link
                  target="blank"
                  href={`/meeting/details/${session.id}`}
                  color={'secondary'}
                  underline="hover"
                >
                  <Box
                    paddingX={2}
                    paddingY={1}
                    sx={{
                      width: '150px',
                      height: '120px',
                      borderRadius: '0.5rem',
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: '#8C90A6',
                      cursor: 'pointer',
                      transition: 'all 0.2s linear',
                      backgroundColor: 'white',
                      '&:hover': {
                        filter: 'brightness(85%)',
                      },
                    }}
                  >
                    <SectionHeader
                      title={
                        <TooltipTypography
                          color="secondary"
                          variant="h5"
                          fontWeight="bold"
                          maxWidth={'180px'}
                          title={
                            session?.participants?.map((p: any) =>
                              encodeUserId(p?.userId),
                            ) || 'N/A'
                          }
                        />
                      }
                      subTitle={session?.displayTime}
                    />
                    <StatusChip
                      bgColor={STATUS_TEXT_CHIP_COLORS(session?.status).bgColor}
                      color={STATUS_TEXT_CHIP_COLORS(session?.status).color}
                      title={STATUS_TEXT[session?.status]}
                    />
                  </Box>
                </Link>
              ))
            )}
          </Box>
        </Grid>
      </Grid>
      <MeetingInfoPopup
        anchorEl={anchorEl}
        meetingId={dataToEdit?.meeting.id}
        handleClose={() => {
          setAnchorEl(null);
        }}
        refreshData={() => getData(selectedDate)}
      />
    </>
  );
};

export default ProviderSessionView;
