import { Divider, Grid, Typography } from '@mui/material';

import { useContext, useEffect, useState } from 'react';

import toast from 'react-hot-toast';
import ToggleSwitchButton from '../../../../widgets/ToggleSwitchButton/ToggleSwitchButton';
import OrganisationContractDetails from '../OrganisationContractDetails';
import OrganisationConfigurations from './OragnisationConfigurations';
import { OrganisationoDetailContext } from './OrganisationDetailView';
import OrganisationInformation from './OrganisationInformation';
import { useUpdateOrganisation } from './hooks/useUpdateOrganisation';
import AccessCodesView from './organisationBilling/accessCodes/AccessCodesView';
import CreditView from './organisationBilling/creditsV2/CreditView';
import AuditLogs from '../../../../components/AuditLogs/AuditLogs';
import { ApiConfigV2 } from '../../../../../shared/apiConfigV2';

const OrganisationOverview = () => {
  const {
    updatedData,
    orgData,
    getOrgData,
    refetchAuditLogs,
    setRefetchAuditLogs,
  } = useContext(OrganisationoDetailContext);

  const { mutate: updateOrgData, isLoading } = useUpdateOrganisation(() => {
    getOrgData();
    setRefetchAuditLogs(!refetchAuditLogs);
    toast.success('Organisation data updated successfully!');
  });

  const [dependentAccess, setDependentAccess] = useState(
    orgData?.dependentEnabled,
  );
  const [holisticContent, setHolisticContent] = useState(
    orgData?.holisticsEnabled,
  );

  useEffect(() => {
    setDependentAccess(orgData?.dependentEnabled);
    setHolisticContent(orgData?.holisticsEnabled);
  }, [orgData?.dependentEnabled, orgData?.holisticsEnabled]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <OrganisationConfigurations />
          <Divider sx={{ my: 3 }} />
          <OrganisationInformation />
        </Grid>
        {/* <Grid item xs={0.5}>
          <Divider orientation="vertical" sx={{ mx: 2 }} />
        </Grid> */}
        <Grid item xs={9} width={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} width={12}>
              <OrganisationContractDetails />
            </Grid>
            {/* <Grid item xs={12} width={12}>
              <Divider sx={{ my: 1 }} />
            </Grid> */}
            <Grid
              item
              xs={6}
              width={6}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant="h6" margin={0} className="txt-overflow">
                Dependent Access
              </Typography>

              <ToggleSwitchButton
                width="160px"
                checked={!!dependentAccess}
                onChange={(v) => {
                  if (orgData) {
                    updateOrgData({
                      orgId: orgData?.id,
                      updatedData: { ...updatedData, dependentEnabled: v },
                      isStatusUpdated: updatedData.status !== orgData.status,
                    });
                  }
                }}
                uniqueKey={'1-dependent'}
                disabled={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={6}
              width={6}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant="h6" margin={0} className="txt-overflow">
                Holistic Content
              </Typography>

              <ToggleSwitchButton
                width="160px"
                checked={!!holisticContent}
                uniqueKey={'2-holistic'}
                disabled={isLoading}
                onChange={(v) => {
                  if (orgData) {
                    updateOrgData({
                      orgId: orgData?.id,
                      updatedData: { ...updatedData, holisticsEnabled: v },
                      isStatusUpdated: updatedData.status !== orgData.status,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} width={12}>
              <AccessCodesView />
            </Grid>
            {/* <Grid item xs={12} width={12}>
              <Divider sx={{ my: 1 }} />
            </Grid> */}
            <Grid item xs={12} width={12}>
              <CreditView />
            </Grid>

            {orgData?.id && (
              <Grid item xs={12} width={12}>
                <AuditLogs
                  refId={orgData?.id?.toString()}
                  customUrl={ApiConfigV2.api.auditlog.getOrganisationAuditLogs}
                  refetch={refetchAuditLogs}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OrganisationOverview;
