import {
  Autocomplete,
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import {
  OrganisationTeamMemberRole,
  TeamMemberStatusEnum,
  TeamMemberStatusRevised,
} from '../../../../../../shared/constants/Organisation';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { OrganisationTeamItem } from '../../../../../../shared/types/organisation/OrganisationTeamItem';
import { OrganisationTeamMemberItem } from '../../../../../../shared/types/organisation/OrganisationTeamMemberItem';
import { Country } from '../../../../../../shared/types/provider/Provider';
import { useGetCountries } from '../../../../../../utilities/hooks/useGetCountries';
import ConfirmDialog from '../../../../../components/confirmDialog/ConfirmDialog';
import { GenericDialog } from '../../../../../components/genericDialog';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { useGetOrgConfigs } from '../hooks/useGetOrgConfigs';
import MigrateMembersToOutplacementDialog from './OutplacementDialog';

const EditMemberDialog = ({
  open,
  member,
  handleClose,
  refreshData,
  teams,
  orgId,
  csRep,
}: {
  open: boolean;
  member: OrganisationTeamMemberItem | null;
  handleClose: () => void;
  refreshData: () => void;
  teams?: OrganisationTeamItem[];
  orgId: number;
  csRep: boolean;
}) => {
  const [newEmail, setNewEmail] = React.useState<string>('');
  const [newRole, setNewRole] = React.useState<string>('');
  const [newTeam, setNewTeam] = React.useState<number>(0);
  // const [newCountry, setNewCountry] = React.useState<string>('');
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [showMigrateButton, setShowMigrateButton] = React.useState(false);
  const [isOutplacementDialogOpen, setIsOutplacementDialogOpen] =
    React.useState(false);

  const [countryMember, setCountryMember] = React.useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { data: countries } = useGetCountries();
  const { data: orgConfigList } = useGetOrgConfigs(orgId);

  useEffect(() => {
    const placementCheck = orgConfigList?.find(
      (element: any) => element.key === 'OUTPLACEMENT_ORG',
    );
    setShowMigrateButton(!!placementCheck && !!placementCheck.value);
  }, [orgConfigList]);

  useEffect(() => {
    setNewEmail(member?.emailAddress ?? '');
    setNewRole(member?.role ?? '0');
    setNewTeam(member?.organisationTeamId ?? 0);
    // setNewCountry(member?.country ?? '');
    setCountryMember(member?.country ?? '');
  }, [member, orgId]);

  const selectedCountry = useMemo(() => {
    return countries?.find((item) => item?.code === countryMember);
  }, [countryMember, countries]);

  const handleDeleteMember = async () => {
    try {
      if (!member?.id) return;

      setLoading(true);
      const result = await OrganisationServiceV2.deleteMember(member?.id);
      if (result) {
        refreshData();
        toast.success('Member deleted successfully!');
        setIsDialogOpen(false); // Close the dialog
        handleClose();
      }
    } catch (error) {
      toast.error('Error occurred while deleting member.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateMember = async () => {
    try {
      if (!member?.id) return;

      setLoading(true);
      const result = await OrganisationServiceV2.updateMember(member?.id, {
        emailAddress: newEmail,
        role: newRole,
        organisationTeamId: newTeam,
        countryCode: countryMember || '',
      });
      if (result) {
        refreshData();
        toast.success('Member updated successfully!');
        handleClose();
      }
    } catch (error) {
      toast.error('Error occurred while updating member.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <GenericDialog
      noFullScreen
      handleClose={handleClose}
      open={open}
      title="Edit member"
      extraElements={
        member && (
          <>
            {showMigrateButton &&
            member.status === TeamMemberStatusEnum.ACTIVE ? (
              <StyledButton
                sx={{ width: '8rem' }}
                size="large"
                onClick={() => setIsOutplacementDialogOpen(true)}
              >
                Migrate
              </StyledButton>
            ) : (
              <StyledButton
                variant="ghost"
                color="red"
                startIcon={MaterialIcons.Delete}
                onClick={() => setIsDialogOpen(true)}
              >
                Delete
              </StyledButton>
            )}
            <MigrateMembersToOutplacementDialog
              open={isOutplacementDialogOpen}
              handleClose={() => {
                setIsOutplacementDialogOpen(false);
                handleClose();
              }}
              orgId={orgId}
              refreshData={refreshData}
              memberUserId={member.userId}
            />

            <ConfirmDialog
              open={isDialogOpen}
              disabled={loading}
              handleClose={() => setIsDialogOpen(false)}
              onConfirm={handleDeleteMember}
              title="Confirm Deletion"
              cancelButtonTitle="Cancel"
              confirmButtonTitle="Delete"
            >
              Are you sure you want to delete this member?
            </ConfirmDialog>
            <StyledButton
              sx={{ width: '8rem' }}
              size="large"
              onClick={handleUpdateMember}
              disabled={loading}
            >
              Save
            </StyledButton>
          </>
        )
      }
    >
      {member && (
        <Box padding={3}>
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Grid container spacing={4}>
              <Grid item xs={10}>
                <InputLabel>Email address</InputLabel>
                <TextField
                  disabled={TeamMemberStatusRevised[member.status].editable}
                  onChange={(event) => setNewEmail(event.target.value)}
                  value={newEmail}
                  sx={{ flexGrow: 1, marginRight: 3 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <InputLabel>Status</InputLabel>
                <Typography
                  style={{
                    backgroundColor:
                      TeamMemberStatusRevised[member.status].bgColor,
                    color: TeamMemberStatusRevised[member.status].color,
                    alignItems: 'center',
                    paddingLeft: 5,
                    paddingRight: 5,
                    borderRadius: 5,
                    height: 25,
                  }}
                >
                  {TeamMemberStatusRevised[member.status].label}
                </Typography>
              </Grid>
            </Grid>

            {/* <StyledButton
                onClick={() => startUpdate(member.role, newEmail)}
              >
                Update
              </StyledButton> */}
          </Box>

          <Box marginBottom={2}>
            <InputLabel>Role</InputLabel>
            <Select
              style={{ width: '100%' }}
              // className={classes.textField}
              value={newRole}
              onChange={(event) => {
                setNewRole(event.target.value);
              }}
              // helperText="Please select your currency"
            >
              {Object.keys(OrganisationTeamMemberRole).map((key: string) => (
                <MenuItem key={key} value={key}>
                  {OrganisationTeamMemberRole[key].label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {!!teams && (
            <Box marginBottom={2}>
              <InputLabel>Team</InputLabel>
              <Select
                style={{ width: '100%' }}
                // className={classes.textField}
                value={newTeam}
                onChange={(event) => setNewTeam(Number(event.target.value))}
                // helperText="Please select your currency"
              >
                {teams.map((team: OrganisationTeamItem) => (
                  <MenuItem key={team.id} value={team.id}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}

          <Box>
            <InputLabel>Country</InputLabel>
            <Autocomplete
              disabled={false}
              options={countries ?? []}
              getOptionLabel={(option: Country) => option?.countryName ?? ''}
              value={selectedCountry}
              onChange={(event: any, newValue: Country | null) => {
                if (newValue) {
                  setCountryMember(newValue?.code);
                }
              }}
              renderInput={(params: any) => <TextField {...params} />}
              key={`countryAutocomplete${countryMember}`}
            />
          </Box>
        </Box>
      )}
    </GenericDialog>
  );
};

export default EditMemberDialog;
