import * as Yup from 'yup';

export const addUserFormValidationSchema = Yup.object().shape({
  name: Yup.string()
    .typeError('Name is required')
    .required('Name is required')
    .trim(),
  email: Yup.string()
    .typeError('Email Address is required')
    .required('Email Address is required')
    .test(
      'validate-email',
      'Please enter correct username part, @ is not allowed',
      (value) => !new RegExp(/[@]/).test(value),
    )
    .trim(),
  associatedRoles: Yup.array()
    .of(Yup.number())
    .min(1, 'Role is required')
    .typeError('Role is required')
    .required('Role is required'),
});
