import { ApiConfigV2 } from '../../apiConfigV2';
import { NetworkInstanceV2 } from './NetworkInstance';

export class UserManagement {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getAllUsers = (filterInputs: Record<string, any> = {}) =>
    this.client.get(ApiConfigV2.api.userManagement.getUsers, {
      params: filterInputs,
    });

  getRoles = () => this.client.get(ApiConfigV2.api.userManagement.getRoles);

  addUser = (payload: Record<string, any>) =>
    this.client.post(ApiConfigV2.api.userManagement.addUser, payload);

  editUser = (userId: string, payload: Record<string, any>) =>
    this.client.put(
      ApiConfigV2.api.userManagement.editUser.replace('{{userId}}', userId),
      payload,
    );

  searchUser = (email: string) =>
    this.client.get(ApiConfigV2.api.userManagement.searchUser, {
      params: { email },
    });
}
