import { ApiConfigV2 } from '../../apiConfigV2';
import { Reward as IReward } from '../../types/reward';
import { NetworkInstanceV2 } from './NetworkInstance';

export class RewardV2 {
  client: NetworkInstanceV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
  }

  getAllRewards = () => this.client.get(ApiConfigV2.api.reward.list);

  getReward = (rewardId: string) =>
    this.client.get(ApiConfigV2.api.reward.detail.replace('{{id}}', rewardId));

  addReward = (body: Partial<IReward>) =>
    this.client.post(ApiConfigV2.api.reward.add, body);

  updateReward = (rewardId: string, body: Partial<IReward>) =>
    this.client.put(
      ApiConfigV2.api.reward.update.replace('{{id}}', rewardId),
      body,
    );

  deleteReward = (rewardId: string) =>
    this.client.delete(
      ApiConfigV2.api.reward.delete.replace('{{id}}', rewardId),
    );
}
