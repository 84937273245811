import { useContext, useState } from 'react';
import { GenericDialog } from '../../../../../components/genericDialog';
import { OrganisationoDetailContext } from '../OrganisationDetailView';
import TableViewComponent from '../../../../../components/TableViewComponent/TableViewComponent';
import { MembersInMultipleTeamsColumns } from '../../../utils/grid-columns/MembersInMultipleTeamsColumns';

const MembersInMultipleTeamsDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { membersInMultipleTeams } = useContext(OrganisationoDetailContext);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  return (
    <>
      <GenericDialog
        noFullScreen
        handleClose={onClose}
        open={open}
        title="Duplicate Users"
      >
        <TableViewComponent
          columns={MembersInMultipleTeamsColumns}
          rows={membersInMultipleTeams}
          pageSizeCustom={pageSize}
          currentPage={Math.ceil(currentPage / pageSize)}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
          }}
          onPageChange={(page) => {
            const skipTemp = page * pageSize;
            setCurrentPage(skipTemp);
          }}
        />
      </GenericDialog>
    </>
  );
};

export default MembersInMultipleTeamsDialog;
