import * as Yup from 'yup';

export const addAccessCodeValidationSchema = Yup.object().shape({
  code: Yup.string()
    .typeError('Acces Code is invalid')
    .required('Acces Code is required')
    .min(5, 'Access Code should be greater than or equal to 5 chars.')
    .trim(),
  maxUsage: Yup.number()
    .typeError('Max Usage is invalid')
    .required('Max Usage is required'),
  domainRestriction: Yup.string()
    .typeError('Domain Restriction is invalid')
    .trim(),
});
