import React from 'react';
import XLSX from 'xlsx';
import { Button } from '@mui/material';
import { saveAs } from 'file-saver';
import StyledButton from '../../widgets/styledButton/StyledButton';
import { DesktopDownloadIcon, DownloadIcon } from '@primer/octicons-react';

const DownloadTemplateView = () => {
  const handleDownload = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ['email', 'team', 'Sub-team', 'country'],
    ]);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    saveAs(blob, 'upload-members-template.xlsx');
  };
  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <StyledButton
      color="light"
      variant="ghost"
      startIcon={<DownloadIcon size={18} />}
      onClick={handleDownload}
    >
      Download Template
    </StyledButton>
  );
};

export default DownloadTemplateView;
