import { Backdrop, Box, CircularProgress, SxProps, Theme } from '@mui/material';

export const FullScreenLoadingView = () => (
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open
  >
    <CircularProgress color="inherit" disableShrink />
  </Backdrop>
);

export const LoadingView = ({ sx = [] }: { sx?: SxProps<Theme> }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="50%"
    width="100%"
    sx={[
      {
        padding: '0.5rem',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <CircularProgress sx={{ color: 'black' }} />
  </Box>
);
