import { Switch } from 'react-router';
import Config from '../../../config';
import { AuthenticatedRoute } from '../../components/authenticatedRoute/AuthenticatedRoute';
import CaseNotesList from './CaseNotesList';

const CaseNotesView = () => {
  return (
    <Switch>
      <AuthenticatedRoute
        path={Config.paths.caseNotes.home}
        exact={true}
        component={CaseNotesList}
      />
    </Switch>
  );
};

export default CaseNotesView;
