// ** MUI Imports
import { Box } from '@mui/material';
import {
  DataGrid,
  GridCallbackDetails,
  GridFeatureMode,
  GridFilterModel,
  GridInputRowSelectionModel,
  GridPaginationModel,
  GridRowClassNameParams,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { LoadingView } from '../loadingView';

type PropTypes = {
  rows: Array<Record<string, any>>;
  columns: Array<any>;
  disableColumnMenu?: boolean;
  disableColumnFilter?: boolean;
  disableRowsPerPageSelection?: boolean;
  hideColumns?: Array<string>;
  onPageSizeChange?: (pageSize: number) => void;
  onPageChange?: (pageSize: number) => void;
  currentPage?: number;
  total?: number;
  sortModel?: { field: string; sort: 'asc' | 'desc' | null }[];
  handleSorting?: (data: any[]) => void;
  handleFilter?: (data: GridFilterModel) => void;
  getRowId?: any;
  loading?: boolean;
  pageSizeCustom?: number;
  paginationMode?: GridFeatureMode;
  filterMode?: GridFeatureMode;
  noDataMsg?: string | React.ReactNode;
  sortingMode?: 'client' | 'server';
  handleSortingProgrammatically?: boolean;
  checkboxSelection?: boolean;
  keepNonExistentRowsSelected?: boolean;
  apiRef?: any;
  isRowSelectable?: (params: any) => boolean;
  onStateChange?: (state: any) => void;
  onRowClick?: (params: GridRowParams) => void;
  onRowSelection?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails<any>,
  ) => void;
  getRowClassName?: (
    params: GridRowClassNameParams<Record<string, any>>,
  ) => string;
  rowSelectionModel?: GridInputRowSelectionModel;
};

const MaximumPageSize = 100;

const TableViewComponent = ({
  rows = [],
  columns = [],
  hideColumns = [],
  onPageSizeChange: onPaginationModelChange,
  onPageChange,
  currentPage,
  total,
  sortModel,
  handleSorting,
  handleFilter,
  getRowId,
  loading = false,
  pageSizeCustom = 7,
  paginationMode = 'client',
  filterMode = 'client',
  noDataMsg,
  onRowClick,
  onRowSelection,
  sortingMode = 'client',
  disableColumnMenu = true,
  disableColumnFilter = true,
  checkboxSelection = false,
  disableRowsPerPageSelection = false,
  getRowClassName = undefined,
  keepNonExistentRowsSelected,
  rowSelectionModel,
}: PropTypes) => {
  const pageSize =
    pageSizeCustom >= MaximumPageSize ? MaximumPageSize : pageSizeCustom;

  const handleSortModelChange = (sortModel: GridSortModel) => {
    if (handleSorting) handleSorting(sortModel);
  };

  const handleFilterModelChange = (sortModel: GridFilterModel) => {
    if (handleFilter) handleFilter(sortModel);
  };

  const NoDataComponent = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ p: '24px' }}
    >
      {noDataMsg || (
        <>
          <Box display={'flex'} flexDirection={'column'} gap={4}>
            <Box
              sx={{ fontSize: '16px', color: '#252B2C', textAlign: 'center' }}
            >
              Sorry !! No results found
            </Box>
          </Box>
        </>
      )}
    </Box>
  );

  const columnsToHide: any = Object.assign(
    {},
    ...hideColumns.map((key: string) => ({ [key]: false })),
  );

  return (
    <DataGrid
      rows={rows}
      getRowId={getRowId}
      columns={columns}
      loading={loading}
      disableColumnSelector={true}
      disableColumnMenu={disableColumnMenu}
      disableColumnFilter={disableColumnFilter}
      disableRowSelectionOnClick
      checkboxSelection={checkboxSelection}
      keepNonExistentRowsSelected={keepNonExistentRowsSelected}
      onRowSelectionModelChange={onRowSelection}
      rowSelectionModel={rowSelectionModel}
      rowCount={total}
      pageSizeOptions={!disableRowsPerPageSelection ? [7, 10, 25, 50] : []}
      paginationModel={{
        pageSize: pageSize,
        page: (currentPage as number) || 0,
      }}
      paginationMode={paginationMode}
      initialState={{
        pagination: {
          paginationModel: {
            page: (currentPage as number) || 0,
          },
        },
        sorting: {
          sortModel: sortModel,
        },
      }}
      onPaginationModelChange={(
        gridPaginationModal: GridPaginationModel,
        details: GridCallbackDetails<any>,
      ) => {
        /** Only Update Pagination when reason is there
         *  This was causing the issue when we persisted our pagination details on url,
         * so on page refresh pagination was getting reset,
         * that's why this condition added
         */
        if (details.reason) {
          onPaginationModelChange &&
            onPaginationModelChange(gridPaginationModal.pageSize);
          onPageChange && onPageChange(gridPaginationModal.page);
        }
      }}
      onSortModelChange={handleSortModelChange}
      sortingMode={sortingMode}
      slots={{
        noRowsOverlay: NoDataComponent,
        noResultsOverlay: NoDataComponent,
        loadingOverlay: () => (
          <>
            <LoadingView />
          </>
        ),
      }}
      columnVisibilityModel={columnsToHide}
      onRowClick={onRowClick}
      filterMode={filterMode}
      onFilterModelChange={handleFilterModelChange}
      filterDebounceMs={500}
      getRowClassName={getRowClassName}
      sx={{
        border: 'none',
        minHeight: loading ? 450 : 'auto',
        height: loading ? 450 : 'auto',
        // '& .MuiDataGrid-columnHeaders': {
        //   background: 'var(--chip-grey-bg-color)',
        // },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: '700',
        },
        '.MuiDataGrid-virtualScroller,.MuiDataGrid-overlayWrapperInner': {
          minHeight: rows.length == 0 ? '250px' : 'none',
        },
        // '&.MuiDataGrid-root': {
        //   border: '1px solid var(--chip-grey-bg-color) !important',
        //   borderRadius: '5px !important',
        // },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-row': {
          transition: 'all 0.1s linear',
          textDecoration: 'none',
          '&:hover': {
            ...(onRowClick ? { cursor: 'pointer' } : {}),
            backgroundColor: '#ededed',
            '& .MuiTableCell-root': {
              color: 'secondary.main',
            },
          },
        },
      }}
      autoHeight={true}
    />
  );
};

export default TableViewComponent;
