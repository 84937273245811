import { Box } from '@mui/material';
import { useMemo } from 'react';

const toggleSwitchStyles: { [key: string]: React.CSSProperties } = {
  container: {
    width: '16rem',
    position: 'relative',
    display: 'flex',
    padding: 0,
    border: '1px solid #d9d9d9',
    background: 'var(--toggle-switch-bg-color)',
    lineHeight: '2.5rem',
    borderRadius: 'var(--default-border-radius)',
  },
  input: {
    visibility: 'hidden',
    position: 'absolute',
    top: 0,
  },
  label: {
    width: '50%',
    padding: 0,
    margin: 0,
    textAlign: 'center',
    cursor: 'pointer',
    color: 'var(--toggle-switch-label-color)',
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '50%',
    padding: '0.15rem',
    transition: 'transform 0.5s cubic-bezier(0.77, 0, 0.175, 1)',
  },
  switch: {
    borderRadius: 'var(--default-border-radius)',
    background: 'var(--toggle-switch-thumb-bg-color)',
    height: '100%',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.09)',
  },
  switchLabel: {
    width: '100%',
    textAlign: 'center',
    opacity: 0,
    display: 'block',
    color: 'var(--toggle-switch-thumb-color)',
    transition: 'opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s',
    willChange: 'opacity',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  wrapperChecked: {
    transform: 'translateX(100%)',
  },
  wrapperUnchecked: {
    transform: 'translateX(0%)',
  },
  switchLabelChecked: {
    opacity: 1,
  },
};

export enum ToggleSwitchButtonLabels {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

const ToggleSwitchButton = ({
  checkedValue = ToggleSwitchButtonLabels.ENABLED,
  uncheckedValue = ToggleSwitchButtonLabels.DISABLED,
  checked,
  onChange,
  width = '16rem',
  fullWidth = false,
  disabled = false,
  uniqueKey,
}: {
  checkedValue?: string;
  uncheckedValue?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  width?: string;
  fullWidth?: boolean;
  uniqueKey: string;
  disabled?: boolean;
}) => {
  const labelForUniqueKey = useMemo(() => {
    return `${uniqueKey}-${Math.random()}`;
  }, [uniqueKey]);

  const handleChange = (e: any) => {
    const newValue = e.target.value;
    onChange(newValue === checkedValue ? true : false);
  };

  return (
    <Box
      style={{
        ...toggleSwitchStyles.container,
        ...(disabled && { pointerEvents: 'none' }),
        width: fullWidth ? '100%' : width,
      }}
    >
      <input
        type="radio"
        id={`switchYearly-${labelForUniqueKey}`}
        name="switchPlan"
        value={uncheckedValue}
        checked={!checked}
        onChange={handleChange}
        style={toggleSwitchStyles.input}
        disabled={disabled}
      />
      <input
        type="radio"
        id={`switchMonthly-${labelForUniqueKey}`}
        name="switchPlan"
        value={checkedValue}
        checked={checked}
        onChange={handleChange}
        style={toggleSwitchStyles.input}
        disabled={disabled}
      />
      <label
        htmlFor={`switchYearly-${labelForUniqueKey}`}
        style={toggleSwitchStyles.label}
      >
        {uncheckedValue}
      </label>
      <label
        htmlFor={`switchMonthly-${labelForUniqueKey}`}
        style={toggleSwitchStyles.label}
      >
        {checkedValue}
      </label>
      <Box
        style={{
          ...toggleSwitchStyles.wrapper,
          ...(checked
            ? toggleSwitchStyles.wrapperChecked
            : toggleSwitchStyles.wrapperUnchecked),
        }}
      >
        <Box style={toggleSwitchStyles.switch}>
          <Box
            style={{
              ...toggleSwitchStyles.switchLabel,
              ...(!checked ? toggleSwitchStyles.switchLabelChecked : {}),
            }}
          >
            {uncheckedValue}
          </Box>
          <Box
            style={{
              ...toggleSwitchStyles.switchLabel,
              ...(checked ? toggleSwitchStyles.switchLabelChecked : {}),
            }}
          >
            {checkedValue}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ToggleSwitchButton;
