import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import {
  OrganisationDomainConfig,
  SsoDomain,
} from '../../../../../../shared/types/organisation/OrganisationDetailItem';
import { validateDomain } from '../../../../../../utilities/Common';
import LabelWithAsterik from '../../../../../components/LabelWithAsterik/LabelWithAsterik';
import { GenericDialog } from '../../../../../components/genericDialog';
import ToggleSwitchButton from '../../../../../widgets/ToggleSwitchButton/ToggleSwitchButton';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';

const AddDomainDialog = ({
  open,
  edit,
  handleClose,
  handleSuccess,
  existingDomain,
  orgSsoEntityConfigs,
}: {
  open: boolean;
  edit: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  existingDomain?: SsoDomain;
  orgSsoEntityConfigs?: OrganisationDomainConfig[];
}) => {
  const [loading, setLoading] = useState(false);
  const configData = useMemo(() => {
    const configs = orgSsoEntityConfigs?.reduce(
      (result: { configId: number; entityId: string }[], item) => {
        const existingConfig = result.find((rs) => rs.configId === item?.id);

        if (!existingConfig) {
          result.push({
            configId: item?.id,
            entityId: item?.entityId,
          });
        }

        return result;
      },
      [],
    );

    return configs;
  }, [orgSsoEntityConfigs]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik<{ configId?: number; domainName: string; isActive: boolean }>({
    enableReinitialize: true,
    initialValues: {
      configId:
        existingDomain?.ssoConfig?.id ?? configData?.[0]?.configId ?? undefined,
      domainName: existingDomain?.domainName || '',
      isActive: existingDomain?.isActive ?? false,
    },
    validationSchema: Yup.object().shape({
      domainName: Yup.string()
        .typeError('Domain Name is required')
        .required('Domain Name is required')
        .test('validate-domain', 'Please enter correct domain name', (value) =>
          validateDomain(value),
        )
        .trim(),
      isActive: Yup.bool().required('Is Active is required'),
      configId: Yup.number().positive().required('Please select any domain'),
    }),
    onSubmit: async () => {
      if (!values?.configId) return;

      if (edit) handleEditDomain();
      else handleAddDomain();
    },
  });

  const handleAddDomain = async () => {
    try {
      if (!values?.configId) return;

      setLoading(true);
      const response = await OrganisationServiceV2.createDomain(
        values?.configId,
        values,
      );
      if (response && response.data.success) {
        resetForm();
        handleSuccess();
      }
    } catch (err) {
      toast.error('An error occurred while attempting to add domain.');
    } finally {
      setLoading(false);
    }
  };
  const handleEditDomain = async () => {
    try {
      if (!existingDomain?.id) return;
      setLoading(true);
      const response = await OrganisationServiceV2.updateDomain(
        existingDomain?.id,
        values,
      );
      if (response && response.data.success) {
        handleSuccess();
      }
    } catch (err) {
      toast.error('An error occurred while attempting to edit domain.');
    } finally {
      setLoading(false);
    }
  };

  const handleDialogClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <GenericDialog
      open={open}
      handleClose={handleDialogClose}
      noFullScreen
      title={`${edit ? 'Edit' : 'Add'} domain`}
      extraElements={
        <>
          <StyledButton
            size="small"
            sx={{ width: '8rem' }}
            onClick={handleSubmit}
            disabled={loading}
          >
            Submit
          </StyledButton>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={6} width={6}>
          <InputLabel>
            <LabelWithAsterik title={'Domain Config'} />
          </InputLabel>
          <Select
            fullWidth
            size="small"
            value={values?.configId}
            name="configId"
            onChange={handleChange}
            disabled={Boolean(existingDomain?.id)}
            error={touched.configId && Boolean(errors.configId)}
            MenuProps={{ sx: { width: '50%' } }}
          >
            {configData?.map((item, idx) => (
              <MenuItem
                key={idx}
                value={item?.configId}
                sx={{ whiteSpace: 'normal' }}
              >
                {`${item?.configId} - ${item?.entityId ?? ''}`}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6} width={6}>
          <InputLabel>
            <LabelWithAsterik title="Domain Name" />
          </InputLabel>
          <TextField
            fullWidth
            name="domainName"
            value={values?.domainName}
            onChange={handleChange}
            error={touched.domainName && Boolean(errors.domainName)}
            helperText={touched.domainName && errors.domainName}
            size="small"
          />
        </Grid>
        <Grid item xs={4} width={4}>
          <InputLabel>Status</InputLabel>
          <ToggleSwitchButton
            width="160px"
            checked={!!values?.isActive}
            onChange={(v) => {
              setFieldValue('isActive', v);
            }}
            uniqueKey={'domain-status'}
            checkedValue="Active"
            uncheckedValue="Inactive"
          />
        </Grid>
      </Grid>
    </GenericDialog>
  );
};

export default AddDomainDialog;
