import { useQuery } from '@tanstack/react-query';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import { ConfigMfaItem } from '../../../../../../shared/types/organisation/OrganisationDetailItem';

export const useGetOrgMfaList = () =>
  useQuery({
    queryKey: ['org-mfa-list'],
    queryFn: () => OrganisationServiceV2.getMfaOptions(),
    staleTime: 30 * 60 * 1000, // Data is fresh for 30 minutes
    cacheTime: 40 * 60 * 1000, // Data is kept in cache for 40 minutes
    select: (data) => (data?.data?.data ?? []) as ConfigMfaItem[],
    meta: {
      errorMessage: 'An error occurred while attempting to get org mfa list',
    },
  });
