import moment from 'moment/moment';
import { Provider, ProviderProfile } from '../../types/provider/Provider';
import { OrganisationV2 } from './Organisation';
import { slotToTime } from '../../../utilities/Date';
import { NetworkInstanceV2 } from './NetworkInstance';
import { ApiConfig } from '../../api_config';
import { OrganisationServiceV2 } from '../services_v2';

const ProviderConfig = {
  baseURL: ApiConfig.base.talky,
};

export class ProviderV2 {
  private client: NetworkInstanceV2;

  private organisationService: OrganisationV2;

  constructor(_client: NetworkInstanceV2) {
    this.client = _client;
    this.organisationService = new OrganisationV2(_client);
  }

  getProviders = (): Promise<Provider[]> =>
    this.client
      .get(`${ApiConfig.api.provider.list}?page=1`, ProviderConfig)
      .then((response) => response.data.data);

  getProvider = (providerId: number) =>
    this.client.get(
      `${ApiConfig.api.provider.list}/${providerId}`,
      ProviderConfig,
    );

  addProvider = async (body: any): Promise<{ userId: string }> => {
    await OrganisationServiceV2.addMultipleTeamMembers(
      ApiConfig.values.providerTeamId,
      {
        newMembers: [{ emailAddress: body.email, role: '0' }],
        existingMembers: [],
      },
    );
    return this.client
      .post(`${ApiConfig.api.provider.list}`, { ...body }, ProviderConfig)
      .then((response) => response.data.data);
  };

  addProviderProfile = (
    body: Record<'name' | 'providerId' | 'type' | 'services', string>,
  ) =>
    this.client.post(
      `${ApiConfig.api.provider.profile}`,
      { ...body, profileImage: 'image.jpg', bio: ' ' },
      ProviderConfig,
    );

  saveProviderProfile = (profile: Partial<ProviderProfile>) =>
    this.client.post(
      `${ApiConfig.api.provider.profile}`,
      {
        ...profile,
        bioTranslation: profile.bioTranslation || {},
        providerNameTranslation: profile.providerNameTranslation || {},
      },
      ProviderConfig,
    );

  getSessions = (providerId: number, fromDate: string, toDate: string) =>
    this.client.get(
      `${ApiConfig.api.provider.sessions.replace(
        '{{providerId}}',
        `${providerId}`,
      )}/${encodeURIComponent(fromDate)}/${encodeURIComponent(toDate)}`,
      ProviderConfig,
    );

  getMeetingSlots = (userId: number, role: string) =>
    this.client
      .get(ApiConfig.api.provider.meeting.meetingSlots, {
        params: { userId, role },
      })
      .then((response) => response.data.data);

  rescheduleMeeting = (
    id: string,
    body: Record<'userId' | 'scheduledStartTime' | 'duration' | 'role', string>,
  ) =>
    this.client.put(
      ApiConfig.api.provider.meeting.reschedule.replace('{{meetingId}}', id),
      body,
    );

  cancelMeeting = (meetingId: string, userId: string) =>
    this.client.put(
      ApiConfig.api.provider.meeting.cancel.replace('{{meetingId}}', meetingId),
      {
        userId,
      },
    );

  getSlots = (providerId: number) =>
    this.client.get(
      ApiConfig.api.provider.getSlots.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );

  saveSlots = (providerId: number, timezone: string, slots: any[]) => {
    const momentSlots: any[] = [];
    const offset = moment.tz(timezone).utcOffset();
    slots.map((slot) => {
      momentSlots.push(
        moment(
          `${slot.dayOfWeek} ${slotToTime(+slot.slot)}`,
          'd HH:mm',
        ).subtract(offset, 'minutes'),
      );
    });
    const dayMap: any = {
      0: new Set(),
      1: new Set(),
      2: new Set(),
      3: new Set(),
      4: new Set(),
      5: new Set(),
      6: new Set(),
      7: new Set(),
    };

    const body = Object.keys(dayMap).map((key: any) => {
      const slots: number[] = [];
      dayMap[key].forEach((slot: number) => slots.push(slot));
      return {
        dayOfWeek: key,
        slots: slots.sort((a: number, b: number) => a - b).join(','),
      };
    });

    return this.client.post(
      ApiConfig.api.provider.updateSlots.replace(
        '{{providerId}}',
        String(providerId),
      ),
      body,
      ProviderConfig,
    );
  };

  getLanguages = (providerId: number) =>
    this.client.get(
      ApiConfig.api.provider.languages.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );

  getAttributes = (providerId: number) =>
    this.client.get(
      ApiConfig.api.provider.attributes.replace(
        '{{providerId}}',
        `${providerId}`,
      ),
    );

  meetingReschedule = (
    providerId: number,
    meetingId: string,
    body: { scheduledDate: string; scheduledSlot: number },
  ) =>
    this.client.post(
      ApiConfig.api.provider.meeting.reschedule
        .replace('{{providerId}}', String(providerId))
        .replace('{{meetingId}}', meetingId),
      body,
      ProviderConfig,
    );

  meetingCancel = (providerId: number, meetingId: string) =>
    this.client.delete(
      ApiConfig.api.provider.meeting.reschedule
        .replace('{{providerId}}', String(providerId))
        .replace('{{meetingId}}', meetingId),
      ProviderConfig,
    );

  updateProvider = (
    providerId: number,
    body: { canMatch?: boolean; timezone?: string },
  ) =>
    this.client.put(
      ApiConfig.api.provider.update.replace(
        '{{providerId}}',
        String(providerId),
      ),
      body,
      ProviderConfig,
    );

  getRanking = (providerId: number) =>
    this.client.get(
      ApiConfig.api.provider.getRanking.replace(
        '{{providerId}}',
        String(providerId),
      ),
    );

  saveRanking = (providerId: number, rankings: any[]) =>
    this.client.post(
      ApiConfig.api.provider.saveRanking.replace(
        '{{providerId}}',
        String(providerId),
      ),
      { rankings },
      ProviderConfig,
    );

  getHolidays = (providerId: number) =>
    this.client.get(
      ApiConfig.api.provider.holiday.list.replace(
        '{{providerId}}',
        String(providerId),
      ),
      ProviderConfig,
    );

  deleteHoliday = (providerId: number, holidayId: string) =>
    this.client.delete(
      ApiConfig.api.provider.holiday.delete
        .replace('{{providerId}}', String(providerId))
        .replace('{{id}}', holidayId),
      ProviderConfig,
    );

  getClients = (providerId: number) =>
    this.client.get(
      ApiConfig.api.provider.client.list.replace(
        '{{providerId}}',
        String(providerId),
      ),
      ProviderConfig,
    );

  deleteClient = (providerId: number, clientId: string) =>
    this.client.delete(
      ApiConfig.api.provider.client.delete
        .replace('{{providerId}}', String(providerId))
        .replace('{{userId}}', clientId),
      ProviderConfig,
    );
  // return this.client.get(
  //   ApiConfig.api.provider.client.list.replace(
  //     "{{providerId}}",
  //     String(providerId),
  //   ),
  //   ProviderConfig,
  // );

  manualAssign = (
    userId: string,
    providerId: string,
    body: {
      friendlyName: string;
      goals: string[];
      role: string;
      userTimeZone: string;
    },
  ) =>
    this.client.post(
      ApiConfig.api.provider.coach.manualAssign
        .replace('{{providerId}}', providerId)
        .replace('{{userId}}', userId),
      body,
    );
}
